import React, { useState } from 'react';
import { Modal, Box, Typography, Button, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import ImportSmeModal from './ImportSMEModal';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../Elements/CustomButton';

interface OnboardSmeModalProps {
  open: boolean;
  onClose: () => void;
}

const OnboardSMEModal: React.FC<OnboardSmeModalProps> = ({
  open,
  onClose,
  
}) => {

    const navigate = useNavigate();
    const [openImportSMEModal, setImportSMEModal] = useState(false);
    const handleToggleImportSMEModal = () => setImportSMEModal(!openImportSMEModal);
    const handleAddSME = () => navigate("/onboarding");
    const theme = useTheme();

  return (
    <>
        <Modal open={open} onClose={() => onClose()}>
            <Box
                sx={{
                width: '600px',
                height: 'auto',
                backgroundColor: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
                padding: '24px',
                borderRadius: '12px',
                margin: 'auto',
                position: 'relative',
                top: '10%',
                }}
            >
                <Typography
                mb={3}
                sx={{
                    fontSize: '24px',
                    fontWeight: '500',
                    lineHeight: '32px',
                    fontFamily: 'Roboto',
                    color: theme.palette.text.primary,
                }}
                >
                SME Onboarding
                </Typography>

                <Box textAlign="center" p={2}>
                <Typography
                    sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '19px',
                    letterSpacing: '-0.0015em',
                    color: 'rgba(93, 94, 98, 1)',
                    marginBottom: '16px',
                    }}
                >
                    Click on 'Add SME' to onboard a new SME or 'Import SME' to import SMEs for onboarding.
                </Typography>

                <Box mt={5} sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <CustomButton
                        variant="outlined"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleAddSME}
                        text="Add SME"
                        
                        />

                    <CustomButton
                        variant="contained"
                        color="success" 
                        startIcon={<AddchartOutlinedIcon />}
                        onClick={handleToggleImportSMEModal}
                        text="Import SME"

                    />
                </Box>
                </Box>

                <Box p={2} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button
                    variant="outlined"
                    onClick={() => onClose()}
                    sx={{
                    width: '93px',
                    height: '39px',
                    padding: '10px 24px',
                    borderRadius: '4px',
                    borderColor: 'rgba(61, 127, 142, 1)',
                    color: 'rgba(61, 127, 142, 1)',
                    }}
                >
                    Close
                </Button>
                </Box>
            </Box>
        </Modal>

        <ImportSmeModal open={openImportSMEModal} onClose={handleToggleImportSMEModal} />
    </>
    
  );
};

export default OnboardSMEModal;
