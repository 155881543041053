import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import DottedLabel from '../Elements/DottedLabel';
import PieChartWrapper from '../Charts/PieChartWrapper';
import PolarAreaChartWrapper from '../Charts/PolarAreaChartWrapper';
import NoDataFoundCard from './NoDataFoundCard';

type ChartType = 'pie' | 'polarArea';

interface SummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    rightPrimaryHeading?: string;
    rightSecondaryText?: string;
    additionalRightLabels: string[];
    dottedColors: string[];
    dottedSize: string;
    type: ChartType;
    data: number[];
}

const ChartSummaryCard: React.FC<SummaryCardProps> = ({
    additionalRightLabels,
    title,
    titleIcon,
    rightPrimaryHeading,
    rightSecondaryText,
    dottedColors,
    dottedSize,
    type,
    data
}) => {
    const hasData = data && data.length > 0;

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2}>
                {hasData ? (
                    <Box marginTop="-20px" width="100%" display="flex" justifyContent="flex-end" alignItems="center">
                        {type === 'polarArea' && (
                            <div style={{ height: '140px' }}>
                                <PolarAreaChartWrapper
                                    chartData={{
                                        labels: [],
                                        datasets: [
                                            {
                                                label: 'Performance',
                                                data: data,
                                                backgroundColor: ['#6EAD57', '#3D7F8E', '#619C9C'],
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: { display: false },
                                            tooltip: { enabled: false },
                                        },
                                        scales: {
                                            r: {
                                                grid: { display: false },
                                                ticks: { display: false },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                        {type === 'pie' && (
                            <div style={{ height: '130px' }}>
                                <PieChartWrapper
                                    chartData={{
                                        labels: [],
                                        datasets: [
                                            {
                                                label: 'Performance',
                                                data: data,
                                                backgroundColor: ['#6EAD57', '#3D7F8E'],
                                            },
                                        ],
                                    }}
                                    options={{
                                        plugins: {
                                            legend: { display: false },
                                            tooltip: { enabled: false },
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </Box>
                ) : (
                    <NoDataFoundCard title={title} />
                )}
                <Box width="100%" display="flex" flexDirection="column">
                    <Box ml={2} pl={2} borderLeft="2px solid #D9D9D9">
                        <Typography textAlign="left" variant="body2">
                            {rightSecondaryText}
                        </Typography>
                        <Typography textAlign="left" variant="h6">
                            {rightPrimaryHeading}
                        </Typography>
                        {additionalRightLabels.map((label, index) => (
                            <DottedLabel
                                key={index}
                                dottedColor={dottedColors[index] || dottedColors[0]}
                                dottedSize={dottedSize}
                                dottedText={label}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default ChartSummaryCard;
