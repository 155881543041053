import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface FinanceDetailsProps {
    total: number;
    income: number;
    expense: number;
}

const FinanceDetails: React.FC<FinanceDetailsProps> = ({ total, income, expense }) => {

    const theme = useTheme();

    return (
        
        <Box display="flex" justifyContent="space-between" p={2} mt={-4}>
            <Box display="flex" alignItems="center" flexGrow={1}>
                <Box flexShrink={0}>
                    <Typography
                        textAlign="left"
                        fontFamily="Roboto"
                        color={theme.palette.text.primary}
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="24px"
                        letterSpacing="0.15px"
                    >
                        Average client account balance
                    </Typography>
                    <Typography
                        textAlign="left"
                        fontFamily="Roboto"
                        fontSize="36px"
                        lineHeight="44px"
                        color={theme.palette.text.primary}
                        fontWeight="400"
                    >
                        R {total.toLocaleString()}
                    </Typography>
                </Box>

                <Box ml={2} pl={2} borderLeft={`2px solid ${theme.palette.divider}`} display="flex" flexDirection="column" flexGrow={1} gap={2}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <ArrowForward sx={{ color: '#14AE5C' }} />
                        <Box>
                            <Typography
                                textAlign="left"
                                fontFamily="Roboto"
                                color={theme.palette.text.primary}
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                letterSpacing="0.25px"
                            >
                                Income
                            </Typography>
                            <Typography
                                textAlign="left"
                                fontFamily="Roboto"
                                color={theme.palette.text.primary}
                                fontWeight="400"
                                fontSize="24px"
                                lineHeight="32px"
                            >
                                R {income.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box display="flex" alignItems="center" gap={2}>
                        <ArrowBack sx={{ color: '#C00F0C' }} />
                        <Box>
                            <Typography
                                textAlign="left"
                                fontFamily="Roboto"
                                color={theme.palette.text.primary}
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="20px"
                                letterSpacing="0.25px"
                            >
                                Expenses
                            </Typography>
                            <Typography
                                textAlign="left"
                                fontFamily="Roboto"
                                color={theme.palette.text.primary}
                                fontWeight="400"
                                fontSize="24px"
                                lineHeight="32px"
                            >
                                R {expense.toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FinanceDetails;
