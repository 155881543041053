import { manageLabellingReducer } from '../common/features/labelling/manageLabellingSlice';
import { authReducer } from '../common/features/auth/authSlice';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    auth: authReducer,
    manageLabelling: manageLabellingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
