// src/data/clientsDummyData.ts

export interface Client {
    id: string;
    company: string;
    contact: string;
    activeSince: string; 
    borrowed: string;
    remainingTerm: string;
    tasks: number | string;
    riskStatus: 'Low' | 'Medium' | 'High';
    flags: string[];
}

export const clientsDummyData: Client[] = [
    {
        id: '6e0e9a5b-43b8-4b5c-8bb1-d2f7f5a5a36c',
        company: 'Company A',
        contact: 'John Doe',
        activeSince: '2021-01-01',
        borrowed: '$10,000',
        remainingTerm: '12 months',
        tasks: 2,
        riskStatus: 'Low',
        flags: ['Low'],
    },
    {
        id: 'f78f527e-8b90-4426-8e8b-3b8a2cfc7c6b',
        company: 'Company B',
        contact: 'Jane Smith',
        activeSince: '2020-06-15',
        borrowed: '$15,000',
        remainingTerm: '6 months',
        tasks: 1,
        riskStatus: 'Medium',
        flags: ['Low'],
    },
    {
        id: 'b14efc9f-11b3-45f8-bf5a-9c1a4e8b8b53',
        company: 'Company C',
        contact: 'Alice Johnson',
        activeSince: '2019-03-10',
        borrowed: '$20,000',
        remainingTerm: '3 months',
        tasks: "(none)",
        riskStatus: 'High',
        flags: ['Medium', 'High'],
    },
];
