import ResetPasswordPage from '../pages/Authentication/ResetPassword';
import ForgotPasswordPage from '../pages/Authentication/ForgotPassword';
import { LoginPage } from '../pages/Authentication/Login';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

const AuthenticationRoutes = () => (
  <Routes>
    <Route path="/authentication/login" element={<LoginPage />} />
    <Route path="/authentication/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/authentication/reset-password" element={<ResetPasswordPage />} />
   
  </Routes>
);

export default AuthenticationRoutes;
