import React from 'react';
import { Box } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import NoDataFound from '../Elements/NoDataFound';


interface NoDataFoundCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    
}

const NoDataFoundCard: React.FC<NoDataFoundCardProps> = ({
    title,
    titleIcon,
    
}) => {
  
    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2} mt={-4}>
                <NoDataFound />
                
            </Box>
        </BaseCard>
    );
};

export default NoDataFoundCard;
