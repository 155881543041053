import React, { useState } from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import ResponsiveGrid from '../../common/components/Grids/ResponsiveGrid';
import { useComponentRegistryContext } from '../../contexts/componentRegistryContext'; 
import { AddCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles'; 
import OnboardSMEModal from '../../common/components/Modals/OnboardingSMEModal';
import CardWithIcon from '../../common/components/Elements/CardWithIcon';

const DashboardPage: React.FC = () => {
    const theme = useTheme();
    
    const { pinnedComponents } = useComponentRegistryContext(); 
    
    const nonFullWidthComponents = pinnedComponents
        .filter((component: any) => component && !component.isFullWidth)
        .slice(0, 4);

    const fullWidthComponents = pinnedComponents
        .filter((component:any) => component && component.isFullWidth)
        .slice(0, 2);

    const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
    const handleOpenOnboardingModal = () => setOpenOnboardingModal(prev => !prev);

    return (
        <Box
            sx={{
                py: 4,
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                gap: 2,
            }}
        >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                <GreetingMessage title='Hi Ben!' subtitle='' />

                <CardWithIcon
                    onClick={handleOpenOnboardingModal}
                    icon={
                        <AddCircle 
                            style={{ 
                                color: theme.palette.mode === 'light' ? 'rgba(73, 69, 79, 1)' : 'white', 
                                marginRight: '5px', 
                                fontSize: '34px' 
                            }} 
                        />
                    }
                    text='Onboard'
                />
            </Box>
            
            <ResponsiveGrid columnCount={2}>
                {nonFullWidthComponents.map((component:any, index:number) => (
                    <Box width="100%" key={index}>
                        {component.component}
                    </Box>
                ))}
            </ResponsiveGrid>

            <ResponsiveGrid columnCount={1}>
                {fullWidthComponents.map((component:any, index:number) => (
                    <Box width="100%" key={index}>
                        {component.component}
                    </Box>
                ))}
            </ResponsiveGrid>

            <OnboardSMEModal open={openOnboardingModal} onClose={handleOpenOnboardingModal} />
        </Box>
    );
};

export default DashboardPage;
