import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    getPrimaryCategories,
    getSecondaryCategoriesByPrimary,
    getKeywordsBySecondary,
    createPrimaryCategory,
    updatePrimaryCategory,
    deletePrimaryCategory,
    createSecondaryCategory,
    updateSecondaryCategory,
    deleteSecondaryCategory,
    createKeyword,
    updateKeyword,
    deleteKeyword,
    getPrimaryCategoriesWithDetails,
} from '../../services/retool-service'; 


export const usePrimaryCategoriesWithDetails = () => {
    return useQuery<any>('primaryCategories', async () => {
        const response = await getPrimaryCategoriesWithDetails();
        return response.data.data;
    }, { staleTime: Infinity });
};

export const usePrimaryCategories = () => {
    return useQuery<any>('primaryCategories', async () => {
        const response = await getPrimaryCategories();
        return response.data.data;
    },{ staleTime: Infinity });
};

export const useSecondaryCategories = (primaryCategoryId: string | undefined) => {
    return useQuery<any>(
        ['secondaryCategories', primaryCategoryId],
        async () => {
            const response = await getSecondaryCategoriesByPrimary(primaryCategoryId!);
            return response.data.data;
        },
        {
            enabled: !!primaryCategoryId,
            staleTime: Infinity
        }
    );
};

export const useKeywordsBySecondary = (secondaryCategoryId: string | undefined) => {
    return useQuery<any>(
        ['keywords', secondaryCategoryId],
        async () => {
            const response = await getKeywordsBySecondary(secondaryCategoryId);
            return response.data.data;
        },
        {
            enabled: !!secondaryCategoryId,
            staleTime: Infinity
        }
    );
};

export const useCreatePrimaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(createPrimaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('primaryCategories');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useUpdatePrimaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(updatePrimaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('primaryCategories');
        },
    });
};

export const useDeletePrimaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(deletePrimaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('primaryCategories');
        },
    });
};

export const useCreateSecondaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(createSecondaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('secondaryCategories');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useUpdateSecondaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(updateSecondaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('secondaryCategories');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useDeleteSecondaryCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteSecondaryCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries('secondaryCategories');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useCreateKeyword = () => {
    const queryClient = useQueryClient();
    return useMutation(createKeyword, {
        onSuccess: () => {
            queryClient.invalidateQueries('keywords');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useUpdateKeyword = () => {
    const queryClient = useQueryClient();
    return useMutation(updateKeyword, {
        onSuccess: () => {
            queryClient.invalidateQueries('keywords');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};

export const useDeleteKeyword = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteKeyword, {
        onSuccess: () => {
            queryClient.invalidateQueries('keywords');
            queryClient.invalidateQueries('primaryCategoriesWithDetails');
        },
    });
};
