import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { colors } from '../../../configs/theme';

interface PillLabelProps {
    innerText?: string;
    besideText?: string;
    width?: number;
    height?: number;
    bgcolor: string; 
    fontSize?: string;
}

const PillLabel: React.FC<PillLabelProps> = ({
    innerText,
    besideText,
    bgcolor,
    width = 48,
    height = 20,
    fontSize = "14px",
}) => {
    
    const theme = useTheme();

    return (
        <Box display="flex" alignItems="center">
            <Box
                sx={{
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: '40px',
                    backgroundColor: bgcolor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #303030',
                }}
            >
                <Typography
                    sx={{
                        color: colors.light.text.primary,
                        fontSize: '12px',
                        fontWeight: 600,
                        lineHeight: '1.5',
                        letterSpacing: '0.18px',
                    }}
                >
                    {innerText}
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontSize: fontSize,
                    fontWeight: 400,
                    lineHeight: '1.5',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    marginLeft: '10px',
                    color: theme.palette.text.secondary, 
                }}
            >
                {besideText}
            </Typography>
        </Box>
    );
};

export default PillLabel;
