import { useNavigate } from 'react-router-dom';
import authService from '../../../common/services/auth-service';
import { User } from '../../../common/types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setCookie, removeCookie, getCookie } from 'typescript-cookie';

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
}

const storedUser = localStorage.getItem('user');
const storedToken = getCookie('accessToken');
const isAuthenticated = !!storedToken && !!storedUser;

const initialUser: User | null = storedUser ? JSON.parse(storedUser) : null;

const initialState: AuthState = {
  user: initialUser,
  token: storedToken || null,
  isAuthenticated,
  loading: false,
  error: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await authService.login(email, password);

      if (response.status) {
        throw new Error(response?.response?.data?.error || 'Login failed.');
       
      
      } else {
        setCookie('accessToken', response.token);
        setCookie('refreshToken', response.refreshToken);
        setCookie('isAdmin', JSON.stringify(response.user.isAdmin));

        localStorage.setItem('loginCount', response.loginCount);
        localStorage.setItem('userId', response.user.id);
        localStorage.setItem('username', response.user.name);
        localStorage.setItem('user', JSON.stringify(response.user));

        return response;
        
      }
    } catch (error: any) {
      
      return rejectWithValue(error.message || 'An unexpected error occurred.');
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  authService.logout();
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },

    setAuth(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Login failed';
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.isAuthenticated = false;
      });
  },
});


export const { resetError, setAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;
