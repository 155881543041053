import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material/styles';
import { Cancel, CheckCircle, CheckOutlined } from '@mui/icons-material';

interface StyledSwitchProps extends SwitchProps {
    checked: boolean;
}

const StyledSwitch = styled((props: StyledSwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        icon={<CloseIcon />}
        checkedIcon={<CheckIcon />}
        {...props}
    />
))(({ theme }: { theme: Theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    borderRadius: 15,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: 'green',
            '& + .MuiSwitch-track': {
                backgroundColor: 'green',
                opacity: 1,
                border: 0,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'green',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 26,
        height: 26,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& .MuiSwitch-track': {
        borderRadius: 15,
        backgroundColor: '#E9E9EA',
        border: '2px solid red',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        padding: '5px',
    },
}));

const ToggleSwitch = ({ checked, onChange }: { checked: boolean; onChange?: (column: any) => void }) => {
    const [internalChecked, setInternalChecked] = useState(checked);

    const handleToggle = () => {
        const newValue = !internalChecked;

        if (onChange) {
           
            onChange(newValue); 
        } else {
            
            setInternalChecked(newValue);  
        }
    };

    return (
        <StyledSwitch
            checked={onChange ? checked : internalChecked}
            onChange={handleToggle}
            icon={<Cancel fontSize="small" style={{ color: 'red' }} />}
            checkedIcon={<CheckCircle fontSize="small" style={{ color: 'white' }} />}
        />
    );
};
export default ToggleSwitch;
