import React from "react";
import { Box, Typography, Grid, Modal } from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";

const DealSummaryDialog = ({
	open,
	onClose,
	dealSummaryData,
	setDealSummaryData,
	handleExport,
	handleSave,
}) => {
	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					maxHeight: "100%",
					backgroundColor: "white",
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					overflowY: "auto",

					position: "relative",
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
						lineHeight: "32px",
						fontFamily: "Roboto",
						color: "rgba(29, 27, 32, 1)",
					}}
				>
					Deal Summary
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CustomTextInput
							label="Company Profile Overview"
							multiline
							minRows={2}
							value={dealSummaryData?.companyProfileOverView}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									companyProfileOverview: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Deal Description Overview"
							multiline
							minRows={2}
							value={dealSummaryData?.dealDescriptionOverview}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									dealDescriptionOverview: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Company Profile Operations"
							multiline
							minRows={2}
							value={dealSummaryData?.companyProfileOperations}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									companyProfileOperations: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Company Profile Loan Purpose"
							multiline
							minRows={2}
							value={dealSummaryData?.companyProfileLoanPurpose}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									companyProfileLoanPurpose: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Company Profile Expertise"
							multiline
							minRows={2}
							value={dealSummaryData?.companyProfileExpertise}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									companyProfileExpertise: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Entrepreneurial Profile Score"
							value={dealSummaryData?.entrepreneurialProfileScore}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									entrepreneurialProfileScore: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Entrepreneurial Profile Rationale"
							multiline
							minRows={2}
							value={dealSummaryData?.entrepreneurialProfileRationale}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									entrepreneurialProfileRationale: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Business Profile Score"
							value={dealSummaryData?.businessProfileScore}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									businessProfileScore: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Business Profile Rationale"
							multiline
							minRows={2}
							value={dealSummaryData?.businessProfileRationale}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									businessProfileRationale: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Repayability Score"
							value={dealSummaryData?.repayabilityScore}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									repayabilityScore: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Repayability Rationale"
							multiline
							minRows={2}
							value={dealSummaryData?.repayabilityRationale}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									repayabilityRationale: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Risk Score"
							value={dealSummaryData?.riskScore}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									riskScore: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Risk Rationale"
							multiline
							minRows={2}
							value={dealSummaryData?.riskRationale}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									riskRationale: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Development Impact Score"
							value={dealSummaryData?.developmentImpactScore}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									developmentImpactScore: e.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<CustomTextInput
							label="Development Impact Rationale"
							multiline
							minRows={2}
							value={dealSummaryData?.developmentImpactRationale}
							onChange={(e) =>
								setDealSummaryData((prev) => ({
									...prev,
									developmentImpactRationale: e.target.value,
								}))
							}
						/>
					</Grid>
				</Grid>

				<Box
					sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}
				>
					<CustomButton
						text="Close"
						onClick={onClose}
						variant="outlined"
						color="secondary"
					/>

					<CustomButton
						text="Save"
						onClick={handleSave}
						variant="contained"
						color="success"
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default DealSummaryDialog;
