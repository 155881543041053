import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  LinearProgress,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomButton from '../Elements/CustomButton'; 
import Lottie from 'react-lottie';
import { animationJson } from '../../../data/animationData';

interface OnboardingModalProps {
  open: boolean;
  onClose: () => void;
  completed: boolean;
  progress: number;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ open, onClose, completed, progress }) => {
  const [isClient, setIsClient] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    setIsClient(true);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSubmit = () => {
    onClose(); 
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        sx={{
            width: '400px',
            maxHeight: '80vh',
            backgroundColor: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
            padding: '24px',
            borderRadius: '8px 8px',
            border: 0,
            margin: 'auto',
            overflowY: 'auto',
            top: '30%',
            position: 'relative',
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          {completed ? (
            <>
              <CheckCircleIcon style={{ fontSize: 80, color: "rgba(61, 127, 142, 1)" }} />
              <Typography variant="h6">Onboarding Complete!</Typography>
            </>
          ) : (
            <>
              {isClient && (
                <Lottie options={defaultOptions} height={150} width={150} />
              )}
              <Typography variant="h6">Onboarding SMEs...</Typography>
              <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CustomButton
            text={completed ? 'Close' : 'Cancel'}
            onClick={handleSubmit}
            variant="outlined"
            color="success"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default OnboardingModal;
