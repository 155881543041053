export interface Transaction {
    id: number;
    company: string;
    date: string;
    description: string;
    amount: number;
    flag: 'Low' | 'Medium' | 'High';
    flagDetails: string;
}

const transactionsDummyData: Transaction[] = [
    {
        id: 1,
        company: "BENched",
        date: "15/09/2024",
        description: "Drug deal",
        amount: 109999.00,
        flag: "High",
        flagDetails: "Suspicious transaction detected"
    },
    {
        id: 2,
        company: "Simmy’s Seafood",
        date: "10/09/2024",
        description: "eBucks2Cash",
        amount: -9.95,
        flag: "Low",
        flagDetails: "Flag cleared: Suspicious transaction detected"
    },
    {
        id: 3,
        company: "Rey’s Coats",
        date: "05/09/2024",
        description: "Audi sales",
        amount: 1599999.00,
        flag: "High",
        flagDetails: "Description contains the word 'Audi'"
    },
    {
        id: 4,
        company: "Rey’s Coats",
        date: "05/09/2024",
        description: "Audi sales",
        amount: 1599999.00,
        flag: "Medium",
        flagDetails: "Flag cleared: Suspicious transaction detected"
    },
    {
        id: 5,
        company: "Star signs",
        date: "02/09/2024",
        description: "iCloud storage",
        amount: -39.00,
        flag: "Low",
        flagDetails: "Type is Applepay"
    },
    {
        id: 6,
        company: "The Natcracker",
        date: "30/08/2024",
        description: "Powerball",
        amount: -24.00,
        flag: "Medium",
        flagDetails: "Suspicious transaction detected"
    },
    {
        id: 7,
        company: "Jay’s Inn",
        date: "28/08/2024",
        description: "Rent",
        amount: 1035000.00,
        flag: "High",
        flagDetails: "Amount exceeds a million"
    },
    {
        id: 8,
        company: "Faz Food",
        date: "25/08/2024",
        description: "Needca$h",
        amount: 35000.00,
        flag: "Medium",
        flagDetails: "Suspicious transaction detected"
    }
];

export default transactionsDummyData;
