import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	Chip,
	Grid,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import {
	Label,
	MoreVert,
	Delete,
	Edit,
	Close,
	HighlightOff,
} from "@mui/icons-material";
import IconWithText from "../Elements/IconWithText";
import CustomSearchBar from "../Elements/CustomSearchBar";
import BaseCard from "../Cards/BaseCard";
import CreateAndUpdateCategoryModal from "./CreateOrUpdateCategoryModal";
import ConfirmationModal from "./ConfirmationModal";
import { useTheme } from "@mui/material/styles";
import {
	usePrimaryCategoriesWithDetails,
	useDeleteKeyword,
	useDeleteSecondaryCategory,
} from "../../helpers/hooks/useLabellingCategory";
import CustomTabs from "../Elements/CustomTabs";
import toast from "react-hot-toast";
import {
	deleteKeyword,
	deleteSecondaryCategory,
} from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";
import CategoryTable from "../Tables/CategoryTable";

interface ManageLabellingRulesModalProps {
	open: boolean;
	onClose: () => void;
}

const ManageLabellingRulesModal: React.FC<ManageLabellingRulesModalProps> = ({
	open,
	onClose,
}) => {
	const theme = useTheme();
	const [isEdit, setIsEdit] = useState(false);
	const [openCreateOrUpdateCategoryModal, setOpenCreateOrUpdateCategoryModal] =
		useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [keywordToDelete, setKeywordToDelete] = useState<string | null>(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const queryClient = useQueryClient();

	const tabs = [
		{ label: "Categories", status: "Categories" },
		{ label: "Rules", status: "Rules" },
	];

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const {
		data: primaryCategories = [],
		isLoading: categoriesLoading,
		refetch,
	} = usePrimaryCategoriesWithDetails();

	const handleSearch = (query: string) => setSearchQuery(query);

	const handleMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement>,
		category: any
	) => {
		setAnchorEl(event.currentTarget);
		setSelectedCategory(category);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleCreateOrUpdateCategory = (action: string) => {
		setIsEdit(action === "edit");
		setOpenCreateOrUpdateCategoryModal(true);
	};

	const handleDeleteCategory = () => {
		if (!selectedCategory) return;
		setConfirmationMessage(
			`Are you sure you want to delete the secondary category "${selectedCategory.secondaryCategory}". This will delete the attached keywords as well.`
		);
		handleMenuClose();
		setConfirmationOpen(true);
	};

	const handleConfirmCategoryDelete = async () => {
		const loadingToastId = toast.loading("Deleting...");
		try {
			if (selectedCategory) {
				await deleteSecondaryCategory(selectedCategory.secondaryCategoryId);
				handleConfirmationClose();
				toast.success("Deleted successfully", {
					id: loadingToastId,
					duration: 2000,
				});
				queryClient.invalidateQueries("primaryCategoriesWithDetails");
			}
		} catch (error) {
			toast.error("Failed to delete category", {
				id: loadingToastId,
				duration: 2000,
			});
			console.error("Error deleting category:", error);
		}
	};

	const handleDeleteKeywordConfirm = async () => {
		const loadingToastId = toast.loading("Deleting keyword...");
		try {
			if (keywordToDelete) {
				await deleteKeyword(keywordToDelete);
				handleConfirmationClose();
				toast.success("Keyword deleted successfully", {
					id: loadingToastId,
					duration: 2000,
				});
				queryClient.invalidateQueries("primaryCategoriesWithDetails");
			}
		} catch (error) {
			toast.error("Failed to delete keyword", {
				id: loadingToastId,
				duration: 2000,
			});
			console.error("Error deleting keyword:", error);
		}
	};

	const handleDeleteKeyword = (keywordId: string) => {
		setKeywordToDelete(keywordId);
		setConfirmationMessage(`Are you sure you want to delete this keyword?`);
		setConfirmationOpen(true);
	};

	const handleConfirmationClose = () => {
		setConfirmationOpen(false);
		setKeywordToDelete(null);
		setSelectedCategory(null);
	};

	const filteredCategories = (primaryCategories as any[]).filter(
		(category) =>
			category.primaryCategory
				?.toLowerCase()
				.includes(searchQuery.toLowerCase()) ||
			category.secondaryCategory
				?.toLowerCase()
				.includes(searchQuery.toLowerCase())
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					position: "relative",
					overflow: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "24px", fontWeight: "500" }}
					>
						Manage Labelling Rules
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<CustomTabs
					tabs={tabs}
					selectedTab={selectedTab}
					onTabChange={handleTabChange}
				/>

				{selectedTab === 1 && (
					<>
						<Grid mt={1} container spacing={3} sx={{ mb: 3 }}>
							<Grid
								item
								xs={12}
								sm={2.5}
								onClick={() => handleCreateOrUpdateCategory("create")}
							>
								<BaseCard
									sx={{ cursor: "pointer", height: "auto", padding: "10px" }}
								>
									<IconWithText
										icon={
											<Label
												style={{
													color: "#C7C000",
													marginRight: "5px",
													fontSize: "34px",
												}}
											/>
										}
										besideText="Add new category"
									/>
								</BaseCard>
							</Grid>

							<Grid item xs={12} sm={9.5}>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<CustomSearchBar
										searchTerm={searchQuery}
										onSearchChange={(e) => handleSearch(e.target.value)}
									/>
								</Box>
							</Grid>
						</Grid>

						{categoriesLoading && <CircularProgress color="success" />}

						<Grid container spacing={2} my={4}>
							{filteredCategories.map((category: any, index: number) => (
								<Grid item xs={12} sm={4} md={3} key={index}>
									<BaseCard
										sx={{
											height: "150px",
											padding: "10px",
											display: "flex",
											flexDirection: "column",
											position: "relative",
											justifyContent: "space-between",
										}}
									>
										<Box
											sx={{
												mb: 2,
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography
												fontSize="14px"
												sx={{ display: "inline-block" }}
											>
												{category.secondaryCategory}
											</Typography>
											<IconButton
												sx={{ position: "absolute", top: "8px", right: "8px" }}
												onClick={(e) => handleMenuOpen(e, category)}
											>
												<MoreVert />
											</IconButton>
										</Box>

										<Box
											sx={{
												overflowY: "auto",
												display: "flex",
												flexDirection: "column",
												justifyContent: "flex-end",
												maxHeight: "150px",
											}}
										>
											<Box sx={{ flexGrow: 1, overflowY: "auto" }}>
												{category.keywords.map((keyword: any) => (
													<Chip
														key={keyword.id}
														label={keyword.keyword}
														onDelete={() => handleDeleteKeyword(keyword.id)}
														sx={{
															height: "20px",
															fontSize: "10px",
															margin: "4px",
															backgroundColor: "rgba(61, 127, 142, 1)",
															color: "#fff",
															".MuiChip-label": {
																padding: "0px 4px",
															},
															".MuiSvgIcon-root": {
																margin: 0,
															},
														}}
														deleteIcon={
															<HighlightOff
																sx={{ fontSize: "1px", fill: "white" }}
															/>
														}
													/>
												))}
											</Box>
											<Box textAlign="right">
												<Chip
													label={category.primaryCategory || "No Primary"}
													color="primary"
													// sx={{
													//     backgroundColor: 'green',
													//     color: 'white',
													//     fontSize: '10px',
													// }}
												/>
											</Box>
										</Box>
									</BaseCard>
									<Menu
										anchorEl={anchorEl}
										open={Boolean(anchorEl)}
										onClose={handleMenuClose}
										key={category}
									>
										<MenuItem
											onClick={() => {
												handleCreateOrUpdateCategory("edit");
												handleMenuClose();
											}}
										>
											<Edit /> Edit
										</MenuItem>
										<MenuItem onClick={() => handleDeleteCategory()}>
											<Delete /> Delete
										</MenuItem>
									</Menu>
								</Grid>
							))}
						</Grid>
					</>
				)}

				{selectedTab == 0 && <CategoryTable />}

				<CreateAndUpdateCategoryModal
					open={openCreateOrUpdateCategoryModal}
					onClose={() => setOpenCreateOrUpdateCategoryModal(false)}
					initialData={selectedCategory}
					isEdit={isEdit}
					categories={primaryCategories}
					refetch={refetch}
				/>

				<ConfirmationModal
					open={confirmationOpen}
					onClose={handleConfirmationClose}
					message={confirmationMessage}
					handleConfirmAction={
						keywordToDelete
							? handleDeleteKeywordConfirm
							: handleConfirmCategoryDelete
					}
					isLoading={isLoading}
				/>
			</Box>
		</Modal>
	);
};

export default ManageLabellingRulesModal;
