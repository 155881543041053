import React from 'react';
import { Box } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import DottedLabel from '../Elements/DottedLabel';
import DoughnutChartWrapper from '../Charts/DoughnutChartWrapper';

interface RightLabel {
    heading: string;
    subtitle: string;
}

interface DoughnutSummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    additionalRightLabels: RightLabel[];
    dottedColors: string[];
    dottedSize: string;
    data: number[];
}

const DoughnutSummaryCard: React.FC<DoughnutSummaryCardProps> = ({
    additionalRightLabels,
    title,
    titleIcon,
    dottedColors,
    dottedSize,
    data,
}) => {
    const totalData = data.reduce((acc, value) => acc + value, 0);

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2} mt={-3}>
                <Box width="25%" display="flex" alignItems="center" justifyContent="center">
                    <div style={{ height: '200px' }}>
                        <DoughnutChartWrapper 
                            data={data} 
                            colors={['#5C6138', '#3D7F8E', '#6EAD57']} 
                        />
                    </div>
                </Box>
                <Box width="75%" display="flex" flexDirection="column" alignItems="flex-start" pl={2}>
                    <Box borderLeft="2px solid #D9D9D9" pl={2}>
                        {additionalRightLabels.map((label: RightLabel, index: number) => (
                            <DottedLabel
                                key={index}
                                dottedColor={dottedColors[index] || dottedColors[0]}
                                dottedSize={dottedSize}
                                dottedText={`${label.heading}`} 
                                dottedSubText={label.subtitle}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default DoughnutSummaryCard;
