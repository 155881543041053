import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    openCreateOrUpdateCategoryModal: false,
    confirmationOpen: false,
    selectedCategory: null,
    searchQuery: '',
};

const manageLabellingSlice = createSlice({
    name: 'manageLabelling',
    initialState,
    reducers: {
        openCreateOrUpdateModal: (state) => {
            state.openCreateOrUpdateCategoryModal = true;
        },
        closeCreateOrUpdateModal: (state) => {
            state.openCreateOrUpdateCategoryModal = false;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        openConfirmationModal: (state) => {
            state.confirmationOpen = true;
        },
        closeConfirmationModal: (state) => {
            state.confirmationOpen = false;
        },
    },
});

export const {
    openCreateOrUpdateModal,
    closeCreateOrUpdateModal,
    setSelectedCategory,
    setSearchQuery,
    openConfirmationModal,
    closeConfirmationModal,
} = manageLabellingSlice.actions;

export const manageLabellingReducer =  manageLabellingSlice.reducer;
