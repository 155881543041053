import React from 'react';
import { Box } from '@mui/material';
import BaseCard from '../Cards/BaseCard';
import CustomCardHeaderWithIcon from '../Cards/CustomCardHeaderWithIcon';
import CustomTable from '../Tables/CustomTable';

interface TableColumn<T> {
    title: string;
    key: keyof T & string;
    sortable?: boolean;
    icon?: React.ReactNode;
}

interface TableRow {
    [key: string]: any;
}

interface ActionColumn<T extends TableRow> {
    title: string;
    icon?: React.ReactNode;
    renderAction?: (row: T) => React.ReactNode;
    onActionClick?: (row: T) => void;
}

interface TableCardProps<T extends TableRow> {
    data: T[];
    title: string;
    titleIcon?: React.ReactNode;
    columns: TableColumn<T>[];
    actionColumn?: ActionColumn<T>;
}

const TableCard = <T extends TableRow>({ data, title, titleIcon, columns, actionColumn }: TableCardProps<T>) => {
    return (
        <BaseCard sx={{height: 'auto'}}>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box p={2} mt={-2}>
                <CustomTable actionColumn={actionColumn} columns={columns} data={data} />
            </Box>
        </BaseCard>
    );
};

export default TableCard;
