import React, { FC } from "react";
import { Container } from "@mui/material";
import ResetPasswordForm from "../../common/components/Forms/ResetPasswordForm";

const ResetPasswordPage: FC = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <ResetPasswordForm />
    </Container>
  );
};

export default ResetPasswordPage;
