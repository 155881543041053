import axios from 'axios';
import { axiosInstance } from "../services/auth-token";

const RETOOL_URL = `${process.env.REACT_APP_PUBLIC_API_URL}/PostInvestmentMonitoring`;
const POST_INVESTMENT_URL = `${process.env.REACT_APP_PUBLIC_API_URL}/PostInvestmentMonitoring`;

const apiClient = axios.create({
    baseURL: RETOOL_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getModelCategories = async () => {
    return await axiosInstance.get('/model-categories');
};

export const getModels = async () => {
    return await axiosInstance.get(POST_INVESTMENT_URL + '/models');
};

export const getModelById = async (id) => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/model/${id}`);
};

export const getAlertsById = async (id) => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/get-alerts/${id}`);
};

export const getSmeStatsById = async (id) => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/get-sme-stats/${id}`);
};

export const getSmeDataConsentStatus = async (id) => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/get-sme-consent-status/${id}`);
};

export const updateSmeConsentStatus = async (modelData) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/update-sme-consent-status', modelData);
};

export const addModel = async (modelData) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/add-model', modelData);
};

export const pimRequestBankAccountLink = async (bankAccountLinkData) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/request-bank-account-link', bankAccountLinkData);
};

export const updateBannerViewStatus = async () => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/update-home-banner-status');
};

export const getBannerViewStatus = async () => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/home-banner-status`);
};

export const getHomeDefaultStats = async () => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/home-default-stats`);
};

export const getSmeTransactions = async (smeId) => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/get-sme-transactions/${smeId}`);
};

export const getLenderActivityLogs = async () => {
    return await axiosInstance.get(POST_INVESTMENT_URL + `/get-pim-lender-activities`);
};

export const openSupportTicket = async (modelData) => {
    return await axiosInstance.post(POST_INVESTMENT_URL + '/open-support-ticket', modelData);
};

export const uploadBulkSMEs = async (data, onProgress) => {
    try {
        const config = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgress(percentCompleted);
            }
        };
        await axiosInstance.post(POST_INVESTMENT_URL + '/bulk-sme-upload', data, config);
    } catch (error) {
        throw error;
    }
};

export const getAlerts = async () => {
    return await axiosInstance.get(POST_INVESTMENT_URL + '/get-alerts');
};