import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	Stepper,
	Step,
	StepLabel,
	CircularProgress,
	IconButton,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import {
	useAddUser,
	useRunKybUsingEmail,
	useRunKycUsingEmail,
} from "../../../common/helpers/hooks/useClientService";
import { Add, Close } from "@mui/icons-material";
import CustomStepper from "../Elements/CustomStepper";
import { useQueryClient } from "react-query";

interface AddClientModalProps {
	open: boolean;
	onClose: () => void;
}

const AddClientModal: React.FC<AddClientModalProps> = ({ open, onClose }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const [email, setEmail] = useState("");
	const [idNumber, setIdNumber] = useState("");
	const [cipcNo, setCipcNo] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const steps = ["Add New Client", "User Profile", "Business Profile"];

	const addUserMutation = useAddUser();
	const runKycMutation = useRunKycUsingEmail();
	const runKybMutation = useRunKybUsingEmail();
	const queryClient = useQueryClient();

	const handleNext = () => {
		setIsLoading(true);

		if (activeStep === 0) {
			const toastId = toast.loading("Adding user...");
			addUserMutation.mutate(
				{ email },
				{
					onSuccess: () => {
						toast.success("User added successfully!", { id: toastId });
						setActiveStep((prev) => prev + 1);
						queryClient.invalidateQueries("clients");
					},
					onError: (error: any) => {
						toast.error(error?.response?.data?.error, { id: toastId });
					},
					onSettled: () => {
						setIsLoading(false);
					},
				}
			);
		} else if (activeStep === 1) {
			const toastId = toast.loading("Running KYC...");
			runKycMutation.mutate(
				{ email, idNumber },
				{
					onSuccess: ({ data, success, message }) => {
						if (success) toast.success(message, { id: toastId });
						else toast.error(message, { id: toastId });
						setActiveStep((prev) => prev + 1);
						queryClient.invalidateQueries("clients");
					},
					onError: (error: any) => {
						toast.error("Error running KYC.", { id: toastId });
					},
					onSettled: () => {
						setIsLoading(false);
					},
				}
			);
		} else if (activeStep === 2) {
			const toastId = toast.loading("Running KYB...");
			runKybMutation.mutate(
				{ email, companyRegNumber: cipcNo },
				{
					onSuccess: ({ data, success, message }) => {
						if (success) toast.success(message, { id: toastId });
						else toast.error(message, { id: toastId });
						queryClient.invalidateQueries("clients");
						setActiveStep((prev) => prev + 1);
					},
					onError: (error: any) => {
						toast.error("Error running KYB.", { id: toastId });
					},
					onSettled: () => {
						setIsLoading(false);
					},
				}
			);
		}
	};

	const handleBack = () => {
		setActiveStep((prev) => prev - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setEmail("");
		setIdNumber("");
		setCipcNo("");
	};

	const renderStepContent = () => {
		switch (activeStep) {
			case 0:
				return (
					<CustomTextInput
						name="email"
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						sx={{ mb: 2 }}
					/>
				);
			case 1:
				return (
					<CustomTextInput
						name="idNumber"
						label="ID Number"
						value={idNumber}
						onChange={(e) => setIdNumber(e.target.value)}
						sx={{ mb: 2 }}
					/>
				);
			case 2:
				return (
					<CustomTextInput
						name="cipcNo"
						label="CIPC No"
						value={cipcNo}
						placeholder="xxxx/xxxxxx/xx"
						onChange={(e) => setCipcNo(e.target.value)}
						fullWidth
						sx={{ mb: 2 }}
					/>
				);
			default:
				return (
					<Typography textAlign="center" sx={{ mb: 2 }}>
						All steps completed successfully!
					</Typography>
				);
		}
	};

	const renderButtons = () => (
		<Box
			display="flex"
			justifyContent="flex-end"
			alignItems="center"
			mt={2}
			gap={2}
		>
			{activeStep > 0 && (
				<CustomButton text="Back" onClick={handleBack} variant="outlined" />
			)}
			{isLoading ? (
				<CircularProgress size={24} color="success" />
			) : activeStep < steps.length ? (
				<CustomButton text="Next" onClick={handleNext} variant="contained" />
			) : (
				<CustomButton text="Reset" onClick={handleReset} variant="contained" />
			)}
		</Box>
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Client Creation
				</Typography>

				<CustomStepper
					steps={steps}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>

				<Box sx={{ mt: 4 }}>
					{renderStepContent()}
					{renderButtons()}
				</Box>
			</Box>
		</Modal>
	);
};

export default AddClientModal;
