export const getTopNPercentages = (arr: any[], n: number) => {
    const totalSum = arr.reduce((sum, num) => sum + num, 0);
  
    const indexedArr = arr.map((value, index) => ({ value, index }));
  
    const sortedArr = [...indexedArr].sort((a, b) => b.value - a.value);
  
    const topN = sortedArr.slice(0, n).map(item => ({
      percentage: (item.value / totalSum) * 100,
      index: item.index
    }));
  
    const remainingPercentage = 100 - topN.reduce((sum, item) => sum + item.percentage, 0);
  
    return { topN, remaining: remainingPercentage };
  };