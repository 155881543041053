import React from 'react';
import {
  Box,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

interface TabItem {
  label: string;
  status?: string;
}

interface TabSearchComponentProps {
  tabs: TabItem[];
  selectedTab: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TabsWithSearch: React.FC<TabSearchComponentProps> = ({
  tabs,
  selectedTab,
  onTabChange,
  searchTerm,
  onSearchChange,
}) => {

  const theme = useTheme(); 
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        aria-label="client tabs"
        sx={{
          padding: '10px 0px',
          minHeight: '48px',
          flexGrow: 1,
          borderBottom: `2px solid ${theme.palette.divider}`, 
          '& .MuiTab-root': {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textAlign: 'center',
            color: theme.palette.text.primary, 
          },
          '& .Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
          '& .MuiTab-root.Mui-selected': {
            color: theme.palette.text.primary, 
          },
          '& .MuiTab-root:hover': {
            color: theme.palette.text.primary, 
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main, 
            height: '3px',
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={onSearchChange}
          placeholder='Search'
          sx={{
            width: '360px',
            height: '56px',
            borderRadius: '28px',
            background: 'rgba(245, 245, 245, 1)',
            '& input': {
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.5px',
              textAlign: 'left',
              color: "#000",
              padding: '15px 12px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon sx={{ color: 'rgba(90, 90, 90, 1)', fontSize: '30px', marginTop: '10px' }} />
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default TabsWithSearch;
