import React, { useState } from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import BaseCard from '../../common/components/Cards/BaseCard';
import { clientsDummyData } from '../../data/clients';
import { AddCircle, ArrowBack, Flag, PushPin, Visibility } from '@mui/icons-material';
import CustomTable from '../../common/components/Tables/CustomTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Tooltip, useTheme } from '@mui/material';
import IconWithText from '../../common/components/Elements/IconWithText';
import { FlagData, flagsDummyData } from '../../data/flags';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlagModal from '../../common/components/Modals/FlagModal';
import TabsWithSearch from '../../common/components/Widgets/TabsWithSearch';

const FlagDetails: React.FC = () => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [openAddFlagModal, setOpenAddFlagModal] = useState(false);
  const [openViewFlagModal, setOpenViewFlagModal] = useState(false);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const navigate = useNavigate();

  const [selectedFlag, setSelectedFlag] = useState<FlagData | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  let filteredData = flagsDummyData.filter((x: any) =>
    x.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    x.flagDetails?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBack = () => { navigate("/flagging") };

  const handleToggleFlag = (row:any) => {

    const newFlag = !row?.flag;

    filteredData.forEach((data:any) => {
      if(data.id == row.id) {
        data.flag = newFlag;

      }
    });

  }

  const tabs = [
    { label: "Active (2)" },
    { label: "Archived (2)" },

  ];

  const columns = selectedTab === 1
    ? [
        { title: 'Category', key: 'category', sortable: true },
        { title: 'Flag Details', key: 'flagDetails', sortable: true },
        { title: 'In/Out', key: 'transactionFlow', sortable: true },
        { title: 'Conditions', key: 'conditions', sortable: true },
        { title: 'Times triggered', key: 'timesTriggered', sortable: true },
      ]
    : [
        { title: 'On/Off', key: 'flag', sortable: true, handler: (row:any) => handleToggleFlag(row) },
        { title: 'Category', key: 'category', sortable: true },
        { title: 'Flag Details', key: 'flagDetails', sortable: true },
        { title: 'In/Out', key: 'transactionFlow', sortable: true },
        { title: 'Conditions', key: 'conditions', sortable: true },
        { title: 'Times triggered', key: 'timesTriggered', sortable: true },
      ];

    const handleView = (flagDetails:FlagData) => {
      setSelectedFlag(flagDetails);
      setOpenViewFlagModal(true);
     }

  return (
    <Box
      sx={{
        py: 4,
        px: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        gap: 2,
      }}
    >
      <Box sx={{ cursor: 'pointer' }} onClick={handleBack} p={2} display="flex" gap={1} alignItems="center" justifyContent="center" width="100%">
        <ArrowBack sx={{ fill: 'rgba(73, 69, 79, 1)' }} />
        <GreetingMessage title={type === "flags" ? 'My Flags' : 'Suspicious transactions'} />
      </Box>

      <Grid container spacing={1} justifyContent="flex-start" width="100%">
        <Grid onClick={() => setOpenAddFlagModal(true)} item xs={12} lg={3} sx={{ cursor: 'pointer' }}>
          <BaseCard sx={{ height: 'auto', padding: '10px' }}>
            <IconWithText
              icon={<AddCircle style={{ color: 'rgba(73, 69, 79, 1)', marginRight: '5px', fontSize: '34px' }} />}
              besideText={type === 'flags' ? 'Add new flag' : 'Add new flag rule'}
            />
          </BaseCard>
        </Grid>
      </Grid>
      <BaseCard sx={{ height: 'auto' }}>
        <Box px={2} py={4}>
          <GreetingMessage title={type === "flags" ? 'Flags' : 'Suspicious activity'} />
          <TabsWithSearch
             tabs={tabs}
             selectedTab={selectedTab}
             onTabChange={handleTabChange}
             searchTerm={searchTerm}
             onSearchChange={handleSearchChange}
         />
          <CustomTable
            icons={(flagDetails) => [

              {
                icon: (
                  <Tooltip title="View Details" arrow>
                    <Visibility sx={{ fill: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white', fontSize: '16px' }} />
                  </Tooltip>
                ),
                onClick: () => handleView(flagDetails),
              },
            ]}
            columns={columns}
            data={filteredData}
            actionColumn={{
              title: 'Actions',
            }}
          />
        </Box>
      </BaseCard>

      <FlagModal isOpen={openAddFlagModal} title="Flag Details" onClose={() => setOpenAddFlagModal(false)} />
      <FlagModal flagDetails={selectedFlag} type={selectedTab == 0 ? 'Active' : 'Archived'} isOpen={openViewFlagModal} title="Flag Details" onClose={() => setOpenViewFlagModal(false)} />

    </Box>
  );
};

export default FlagDetails;
