import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	Stepper,
	Step,
	StepLabel,
	CircularProgress,
	IconButton,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import { Add, Close } from "@mui/icons-material";
import { exportCsvExcelExtract } from "../../../common/services/retool-service";
import { useQuery, useQueryClient } from "react-query";

interface CreditReportModalProps {
	open: boolean;
	onClose: () => void;
	client?: any;
}

const CreditReportModal: React.FC<CreditReportModalProps> = ({
	open,
	onClose,
	client,
}) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [idNumber, setIdNumber] = useState(client?.idNumber || "");
	const [consumerClientName, setConsumerClientName] = useState(
		client?.firstname || ""
	);
	const [companyRegistrationNo, setCompanyRegistrationNo] = useState(
		client?.companyRegistrationNo || ""
	);
	const [commercialClientName, setCommercialClientName] = useState(
		client?.companyName || ""
	);

	const steps = ["Consumer Credit Check", "Commercial Credit Report"];
	const queryClient = useQueryClient();

	const handleNext = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Processing...");

		try {
			if (activeStep === 0) {
				const payload = {
					userId: client?.userId,
					consumerClientName,
					idNumber,
				};

				const response = await exportCsvExcelExtract(payload);
				const { message } = response.data;
				toast.success(message, { id: toastId });
				queryClient.invalidateQueries("clients");
			} else if (activeStep === 1) {
				const payload = {
					userId: client?.userId,
					commercialClientName,
					cipcRegNo: companyRegistrationNo,
				};

				const response = await exportCsvExcelExtract(payload);
				const { message } = response.data;

				toast.success(message, { id: toastId });
				queryClient.invalidateQueries("clients");
			}

			setActiveStep((prev) => prev + 1);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleBack = () => {
		setActiveStep((prev) => prev - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setIdNumber(client?.idNumber || "");
		setConsumerClientName(client?.firstname || "");
		setCompanyRegistrationNo(client?.companyRegistrationNo || "");
		setCommercialClientName(client?.companyName || "");
	};

	const renderStepContent = () => {
		if (activeStep === 0) {
			return (
				<>
					<CustomTextInput
						name="consumerClientName"
						label="Consumer Client Name"
						value={consumerClientName}
						onChange={(e) => setConsumerClientName(e.target.value)}
						sx={{ mb: 2 }}
					/>
					<CustomTextInput
						name="idNumber"
						label="ID Number"
						value={idNumber}
						onChange={(e) => setIdNumber(e.target.value)}
						sx={{ mb: 2 }}
					/>
				</>
			);
		} else if (activeStep === 1) {
			return (
				<>
					<CustomTextInput
						name="commercialClientName"
						label="Commercial Client Name"
						value={commercialClientName}
						onChange={(e) => setCommercialClientName(e.target.value)}
						sx={{ mb: 2 }}
					/>
					<CustomTextInput
						name="companyRegistrationNo"
						label="Company Registration No"
						value={companyRegistrationNo}
						onChange={(e) => setCompanyRegistrationNo(e.target.value)}
						sx={{ mb: 2 }}
					/>
				</>
			);
		} else {
			return (
				<Typography textAlign="center" sx={{ mb: 2 }}>
					All steps completed successfully!
				</Typography>
			);
		}
	};

	const renderButtons = () => (
		<Box
			display="flex"
			justifyContent="flex-end"
			alignItems="center"
			mt={2}
			gap={2}
		>
			{activeStep > 0 && (
				<CustomButton text="Back" onClick={handleBack} variant="outlined" />
			)}
			{isLoading ? (
				<CircularProgress size={24} color="success" />
			) : activeStep < steps.length ? (
				<CustomButton text="Next" onClick={handleNext} variant="contained" />
			) : (
				<CustomButton text="Reset" onClick={handleReset} variant="contained" />
			)}
		</Box>
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Run Credit Check
				</Typography>

				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label, index) => (
						<Step
							sx={{ cursor: "pointer" }}
							key={label}
							onClick={() => setActiveStep(index)}
						>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>

				<Box sx={{ mt: 4 }}>
					{renderStepContent()}
					{renderButtons()}
				</Box>
			</Box>
		</Modal>
	);
};

export default CreditReportModal;
