import React from 'react';
import { Typography, useTheme } from '@mui/material';

const PrimaryHeading: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();

    return (
        <Typography
            style={{
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'left',
                color: theme.palette.text.primary, // Dynamic color based on the theme
            }}
        >
            {children}
        </Typography>
    );
};

export default PrimaryHeading;
