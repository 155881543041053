import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import { CheckCircle, Error } from '@mui/icons-material';
import CustomCircularProgressBar from '../Widgets/CircularProgressBar';
import { useTheme } from '@mui/material/styles';

interface FinanceCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    risk: 'Low' | 'High' | 'Medium';
    previousRisk: 'Low' | 'High' | 'Medium';
    previousRiskDate: string;
}

const ProfileCard: React.FC<FinanceCardProps> = ({
    title,
    titleIcon,
    risk,
    previousRiskDate,
    previousRisk,
}) => {
    const theme = useTheme();

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" p={2} gap={2} mt={-4}>
                {/* First Column */}
                <Box
                    maxWidth="366px"
                    p="16px 24px 16px 16px"
                    bgcolor={theme.palette.mode == 'dark' ? "" : theme.palette.background.paper}
                    gridColumn="span 5"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    <Typography
                        textAlign="left"
                        color={theme.palette.text.primary}
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="19px"
                        letterSpacing="-0.15px"
                    >
                        Average portfolio risk rating
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {risk === 'Low' && (
                            <Box display="flex" alignItems="center">
                                <CheckCircle fontSize='large' sx={{ color: theme.palette.success.main, marginRight: '5px' }} />
                                <Typography
                                    textAlign="left"
                                    color={theme.palette.success.main}
                                    fontWeight="400"
                                    fontSize="20px"
                                    lineHeight="19px"
                                    letterSpacing="-0.15px"
                                >
                                    {risk}
                                </Typography>
                            </Box>
                        )}
                        {risk === 'Medium' && (
                            <Box display="flex" alignItems="center">
                                <Error sx={{ color: '#C7C000', marginRight: '5px' }} />
                                <Typography
                                    textAlign="left"
                                    color='#C7C000'
                                    fontWeight="400"
                                    fontSize="20px"
                                    lineHeight="19px"
                                    letterSpacing="-0.15px"
                                >
                                    {risk}
                                </Typography>
                            </Box>
                        )}
                        {risk === 'High' && (
                            <Box display="flex" alignItems="center">
                                <Error sx={{ color: theme.palette.error.main, marginRight: '5px' }} />
                                <Typography
                                    textAlign="left"
                                    color={theme.palette.error.main}
                                    fontWeight="400"
                                    fontSize="20px"
                                    lineHeight="19px"
                                    letterSpacing="-0.15px"
                                >
                                    {risk}
                                </Typography>
                            </Box>
                        )}
                    </div>
                    <Typography
                        textAlign="left"
                        fontSize="14px"
                        lineHeight="21px"
                        color={theme.palette.text.primary}
                        fontWeight="400"
                        letterSpacing="-0.31px"
                    >
                        Changed from {previousRisk} risk
                    </Typography>
                    <Typography
                        textAlign="left"
                        color={theme.palette.text.secondary}
                        fontWeight="400"
                        fontSize="10px"
                        lineHeight="19px"
                        letterSpacing="-0.15px"
                    >
                        {previousRiskDate}
                    </Typography>
                </Box>

                {/* Second Column */}
                <Box gridColumn="span 7" display="flex" flexDirection="column" justifyContent="flex-end" gap={0}>
                    <Typography
                        mb={3}
                        textAlign="center"
                        color={theme.palette.text.primary}
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="19px"
                        letterSpacing="-0.15px"
                    >
                        Collective portfolio averages based on last available data
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={0.3} justifyContent="space-between">
                        <CustomCircularProgressBar
                            value={60}
                            text="Consumer credit score"
                            progressColor={theme.palette.success.main}
                        />
                        <CustomCircularProgressBar
                            value={60}
                            text="Commercial credit score"
                            progressColor={theme.palette.warning.main}
                        />
                        <CustomCircularProgressBar
                            value={15}
                            text="KYC score"
                            progressColor={theme.palette.error.main}
                        />
                        <CustomCircularProgressBar
                            value={60}
                            text="Default Risk"
                            progressColor={theme.palette.success.main}
                        />
                        <CustomCircularProgressBar
                            value={60}
                            text="Non-performance risk"
                            progressColor={theme.palette.success.main}
                        />
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default ProfileCard;
