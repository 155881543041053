import { useQuery } from "react-query";
import moment from "moment";
import { getTransactionHistories, getUploadedBankStatements } from "../../../common/services/retool-service";

const useUserBankData = (userId: string) => {
	
	const {
		data: uploadedBankStatementsData = [],
		isLoading: isLoadingStatements,
		refetch: refetchStatements
	} = useQuery(
		["uploadedBankStatements", userId],
		() => getUploadedBankStatements(userId),
		{
			enabled: !!userId,
			select: (data) =>
				data?.data?.data.map((each: any) => ({
					...each,
					name: each.documentName,
					document: each.ocrStatusValue === "Succeeded" ? "1/1" : "0/1",
					type: "OCR",
					description: `OCR Extraction status for ${each.documentName}`,
					status:
						each.ocrStatusValue === "Succeeded"
							? "Completed"
							: each.ocrStatusValue !== "Failed"
							? "Pending"
							: "Failed",
					dateCreated: moment(each.uploadedTimestamp).format("YYYY-MM-DD"),
				})) || [],
			
		}
	);

	const {
		data: transactionHistoriesData = [],
		isLoading: isLoadingHistories,
		refetch: refetchHistories
	} = useQuery(
		["transactionHistories", userId],
		() => getTransactionHistories(userId),
		{
			enabled: !!userId,
			select: (data) => data?.data?.data || [],
		}
	);

	return {
		uploadedBankStatementsData,
		transactionHistoriesData,
		isLoadingStatements: isLoadingStatements,
		isLoadingHistories: isLoadingHistories,
		refetchHistories, 
		refetchStatements
	};
};

export default useUserBankData;
