export const getProductType = (type: string): string => {
    switch (type) {
      case "BNPL":
      case "STIF":
      case "Short Term Input Finance":
        return "short-term-input-finance";
      case "InvoiceFinance":
      case "Invoice Finance":
        return "invoice-finance";
      case "PurchaseOrderLoan":
      case "Purchase Order Loan":
        return "purchase-order-loan";
      case "AssetFinance":
      case "Asset Finance":
        return "asset-finance";
      case "Unspecified":
      case "unspecified":
        return "unspecified";
      default:
        return "unspecified";
    }
  };
  