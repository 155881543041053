import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import { CheckCircle, Error } from '@mui/icons-material';
import CustomCircularProgressBar from '../Widgets/CircularProgressBar';
import { useTheme } from '@mui/material/styles';

interface FinanceCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    risk: 'Low' | 'High' | 'Medium';
    label: string;
}

const LinearProfileCard: React.FC<FinanceCardProps> = ({
    title,
    titleIcon,
    risk,
    label,
}) => {
    const theme = useTheme();

    const riskStyles = {
        Low: {
            color: theme.palette.success.main,
            icon: <CheckCircle fontSize='large' style={{ color: theme.palette.success.main, marginRight: '5px' }} />,
        },
        Medium: {
            color: '#C7C000',
            icon: <Error style={{ color: '#C7C000', marginRight: '5px' }} />,
        },
        High: {
            color: theme.palette.error.main,
            icon: <Error style={{ color: theme.palette.error.main, marginRight: '5px' }} />,
        },
    };

    return (
        <BaseCard sx={{ height: 'auto' }}>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box p={2} mt={-2} display="flex" flexDirection="column" gap={1.8}>
                <Box display="flex" gap={1} alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Box display="flex" alignItems="center">
                            {riskStyles[risk].icon}
                            <Typography
                                textAlign="left"
                                color={riskStyles[risk].color}
                                fontWeight="400"
                                fontSize="20px"
                                lineHeight="19px"
                                letterSpacing="-0.15px"
                            >
                                {risk}
                            </Typography>
                        </Box>
                    </div>
                    <Typography
                        textAlign="left"
                        color={theme.palette.text.primary}
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="19px"
                        letterSpacing="-0.15px"
                    >
                        {label}
                    </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={0.3} justifyContent="space-between" px={2}>
                    <CustomCircularProgressBar
                        value={60}
                        text="Consumer credit score"
                        progressColor="#14AE5C"
                    />
                    <CustomCircularProgressBar
                        value={60}
                        text="Commercial credit score"
                        progressColor="#E5A000"
                    />
                    <CustomCircularProgressBar
                        value={15}
                        text="KYC score"
                        progressColor="#EC221F"
                    />
                    <CustomCircularProgressBar
                        value={60}
                        text="Default Risk"
                        progressColor="#14AE5C"
                    />
                    <CustomCircularProgressBar
                        value={60}
                        text="Non-performance risk"
                        progressColor="#14AE5C"
                    />
                </Box>
            </Box>
        </BaseCard>
    );
};

export default LinearProfileCard;
