import { useQuery, useMutation } from 'react-query';
import {
    getAcceptedLoanOffers,
    createOrUpdateLoanOffer,
    createOrUpdateLoanContract,
    getLoanOffersSummary,
    getLendingApplicationsApprovedByOfficer,
    getLoanOffers,
    getLoanContracts,
} from '../../../common/services/retool-service';

export const useFetchAcceptedLoanOffers = () => {
    return useQuery('acceptedLoanOffers', getAcceptedLoanOffers, { staleTime: Infinity });
};


export const useFetchApprovedLoanOffers = () => {
    return useQuery('approvedLoanOffers', getLendingApplicationsApprovedByOfficer, { staleTime: Infinity });
};

export const useFetchLoanOffers = () => {
    return useQuery('loanOffers', getLoanOffers, { staleTime: Infinity });
};


export const useFetchLoanContracts = () => {
    return useQuery('loanContracts', getLoanContracts, { staleTime: Infinity });
};

export const useCreateOrUpdateLoanOffer = () => {
    return useMutation((loanOfferRequest) => createOrUpdateLoanOffer(loanOfferRequest));
};

export const useCreateOrUpdateLoanContract = () => {
    return useMutation((loanOfferContractRequest) => createOrUpdateLoanContract(loanOfferContractRequest));
};

export const useFetchLoanOffersSummary = () => {
    return useQuery('loanOffersSummary', getLoanOffersSummary, { staleTime: Infinity });
};
