import {axiosInstance } from "../services/auth-token"

import axios from 'axios';
import { User } from "../types";
import { removeCookie } from "typescript-cookie";

interface LoginResponse {
  user: User;
  token: string;
  expiration: string;
  loginCount: number;
}

interface SocialLoginRequest {
  name: string;
  email: string;
}

interface RegisterRequest {
  name: string;
  email: string;
  agentCode: string;
}

const register = (payload: RegisterRequest) => {
 
  return axios
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/Auth/register", payload)
    .then((response) => {
      return response.data;
    });
};



const login = (email: string, password: string) => {
  return axios
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/Auth/login", {
      email,
      password,
    })
    .then((response) => {

      return response.data;
    }).catch((error) => {
      return error;
    });
};

const forgotPassword = (email: string, ref : string = 'LOGIN') => {
  return axios
    .post(process.env.REACT_APP_PUBLIC_API_URL + `/Auth/forgot-password?refPage=${ref}`, {
      email
    })
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (userId: string, resetPasswordToken: string, password: string) => {
  return axios
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/Auth/reset-password", {
      userId,
      resetPasswordToken,
      password
    })
    .then((response) => {
      return response.data;
    });
};

const updatePassword = (currentPassword: string, newPassword: string) => {
  return axiosInstance
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/Auth/update-password", {
      currentPassword,
      newPassword,
    })
    .then((response) => {
      return response.data;
    });
};

const resetPasswordAdmin = (userId: string, password: string) => {
  return axiosInstance
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/Auth/reset-password-admin", {
      userId,
      password
    })
    .then((response) => {
      return response.data;
    });
};

const getLoginCount = () => {
  return axiosInstance
    .get<string>(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/login-count`)
    .then((resp) => resp.data);
};

const socialLogin = (payload: SocialLoginRequest) => {
  return axiosInstance
    .post(process.env.REACT_APP_PUBLIC_API_URL + "/auth/social-login", payload)
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  removeCookie('accessToken');
  removeCookie('refreshToken');
  removeCookie('isAdmin');
  
  localStorage.removeItem('loginCount');
  localStorage.removeItem('userId');
  localStorage.removeItem('username');
  localStorage.removeItem('user');
};

const verifyRegistrationEligibility = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/verify-eligibility`, payload).then((response) => {
    if (response.data != null) {
      return response.data;
    }
  });
};

const getUserOtp = async (email: string | null) => {
  return await axios.get(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/get-user-otp/${email}`).then((response) => {
    if (response.data != null) {
      return response.data;
    }
  });
};

const sendApplicationOtp = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/send-otp`, payload).then((response) => {
    if (response.data != null) {
      return response.data;
    }
  });
};

const verifyApplicationOtp = async (payload:any) => {
  return await axios.post(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/verify-otp`, payload).then((response) => {
    if (response.data != null) {
      return response.data;
    }
  });
};

export default {
  login,
  logout,
  register,
  resetPasswordAdmin,
  updatePassword,
  socialLogin,
  getLoginCount,
  forgotPassword,
  resetPassword,
  sendApplicationOtp,
  verifyApplicationOtp,
  getUserOtp,
  verifyRegistrationEligibility,
};