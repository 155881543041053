import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import NoDataFoundCard from './NoDataFoundCard'; // Ensure you import the NoDataFoundCard

interface Segment {
    name: string;
    value: number;
}

interface ColoredBoxSummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    segments?: Segment[];
}

const ColoredBoxSummaryCard2: React.FC<ColoredBoxSummaryCardProps> = ({
    title,
    titleIcon,
    segments,
}) => {
   
    if (!segments || segments.length === 0) {
        return <NoDataFoundCard title={title} />;
    }

    const total = segments.reduce((acc, segment) => acc + segment.value, 0);
    const baseColor = '#3D7F8E';

    const darkenColor = (color: string, amount: number) => {
        let col = parseInt(color.replace(/^#/, ''), 16);
        let r = (col >> 16) - amount;
        let g = ((col >> 8) & 0x00FF) - amount;
        let b = (col & 0x0000FF) - amount;

        r = Math.max(0, r);
        g = Math.max(0, g);
        b = Math.max(0, b);

        return `#${(r.toString(16).padStart(2, '0'))}${(g.toString(16).padStart(2, '0'))}${(b.toString(16).padStart(2, '0'))}`;
    };

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" flexDirection="column" p={2} height="100%" mt={-4}>
                <Box display="flex" width="100%" height="120px" borderRadius="4px" overflow="hidden">
                    {segments.map((segment, index) => {
                        const darkenedColor = darkenColor(baseColor, index * 15);

                        return (
                            <Tooltip key={index} title={`${segment.name}: ${segment.value}`} arrow>
                                <Box
                                    width={`${(segment.value / total) * 100}%`}
                                    bgcolor={darkenedColor}
                                    height="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Typography textTransform="uppercase" fontSize="12px" fontWeight="400" lineHeight="13.62px" color="white">
                                        {segment.name.slice(0, 2)}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        );
                    })}
                </Box>
                <Typography mt={2} textAlign="left" fontSize="9px" color='#1E1E1E' fontWeight="400" lineHeight="13.62px">
                    {segments.map(segment => `${segment.value} ${segment.name}`).join(', ')}
                </Typography>
            </Box>
        </BaseCard>
    );
};

export default ColoredBoxSummaryCard2;
