import React, { useState, ChangeEvent } from 'react';
import {
  Modal,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  Grid,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleSwitch from '../Elements/CustomSwitch';
import { AddCircleOutline, HighlightOff } from '@mui/icons-material';
import ResponseModal from './ResponseModal';
import { FlagData } from '../../../data/flags';

interface Condition {
  category: string;
  parameter: string;
  amount: string;
}

interface Checkboxes {
  incoming: boolean;
  outgoing: boolean;
}

interface FlagModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  flagDetails?: FlagData | null;
  type?: "Active" | "Archived";
}

const FlagModal: React.FC<FlagModalProps> = ({ title, isOpen, flagDetails, type, onClose }) => {
  const theme = useTheme();
  const [openConfirmSuccessModal, setOpenConfirmSuccessModal] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [conditions, setConditions] = useState<Condition[]>([{ category: '', parameter: '', amount: '' }]);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<Checkboxes>({
    incoming: false,
    outgoing: false,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    checkAllFields();
  };

  const handleClearInput = () => {
    setInputValue('');
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckboxes((prev) => ({ ...prev, [name]: checked }));
    checkAllFields();
  };

  const handleConditionChange = (index: number, field: keyof Condition, value: string) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][field] = value;
    setConditions(updatedConditions);
    checkAllFields();
  };

  const addCondition = () => {
    setConditions([...conditions, { category: '', parameter: '', amount: '' }]);
  };

  const removeCondition = (index: number) => {
    // Only allow deletion if more than one condition is present
    if (conditions.length > 1) {
      const updatedConditions = conditions.filter((_, i) => i !== index);
      setConditions(updatedConditions);
      checkAllFields();
    }
  };

  const checkAllFields = () => {
    const allConditionsFilled = conditions.every(
      (condition) => condition.category && condition.parameter && condition.amount
    );
    if (inputValue && (checkboxes.incoming || checkboxes.outgoing) && allConditionsFilled) {
      setIsAllFieldsFilled(true);
    } else {
      setIsAllFieldsFilled(false);
    }
  };

  const handleSave = () => {
    setOpenConfirmSuccessModal(true);
  };

  const canAddCondition = conditions.length === 0 || (
    conditions[conditions.length - 1].category &&
    conditions[conditions.length - 1].parameter &&
    conditions[conditions.length - 1].amount
  );

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: '800px',
          height: 'auto',
          backgroundColor: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
          padding: '24px',
          borderRadius: '12px',
          margin: 'auto',
          position: 'relative',
          top: '10%',
        }}
      >
        
        <Typography mb={3} color={theme.palette.text.primary} fontSize="24px" lineHeight="32px" fontFamily="Roboto" fontWeight="400" gutterBottom>
          {title}
        </Typography>

        
        <TextField
          fullWidth
          sx={{ width: '602px' }}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Flag Description"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClearInput}>
                  <HighlightOff />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        
        <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: '4px', width: '602px' }}>
          {inputValue.length}/50
        </Typography>

        
        <Typography
          variant="body1"
          fontWeight="600"
          sx={{ marginTop: '24px', marginBottom: '8px' }}
        >
          What type of transactions would you like to flag?
        </Typography>

        
        <Box width="100%" sx={{ ml: 3, display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <FormControlLabel
            control={
              <Checkbox
                name="incoming"
                checked={checkboxes.incoming}
                onChange={handleCheckboxChange}
                color="success"
              />
            }
            label="Incoming"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="outgoing"
                checked={checkboxes.outgoing}
                onChange={handleCheckboxChange}
                color="success"
              />
            }
            label="Outgoing"
          />
        </Box>

       
        
        {conditions.map((condition, index) => (
          <Grid container spacing={2} sx={{ marginBottom: '16px' }} key={index}>
            <Box  
              mt={2}
              mb={-2}
              width="100%" 
              display="flex" 
              justifyContent="space-around" alignItems="center">

            <Typography
              variant="body1"
              fontWeight="600"
              width="100%"
              p={2}
             
            >
              Flag transaction if
            </Typography>

            {conditions.length > 1 && (
              <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                
              }}
              onClick={() => removeCondition(index)}
              
            >
              <DeleteIcon sx={{ fontSize: '18px' }} color="success" />
              <Typography sx={{ marginLeft: '8px', color: 'rgba(61, 127, 142, 1)', fontSize: '14px', lineHeight: '19px', letterSpacing: '-0.15%', fontWeight: 600 }}>Remove</Typography>
            </Box>
              
            )}

            </Box>
           

            <Grid item xs={4}>
              <Select
                fullWidth
                value={condition.category}
                onChange={(e) => handleConditionChange(index, 'category', e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select a category</em>
                </MenuItem>
                <MenuItem value="category1">Category 1</MenuItem>
                <MenuItem value="category2">Category 2</MenuItem>
              </Select>
            </Grid>

            
            <Grid item xs={4}>
              {condition.category && (
                <Select
                  fullWidth
                  value={condition.parameter}
                  onChange={(e) => handleConditionChange(index, 'parameter', e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select a parameter</em>
                  </MenuItem>
                  <MenuItem value="parameter1">Less than</MenuItem>
                  <MenuItem value="parameter2">Greater than</MenuItem>
                  <MenuItem value="parameter2">Equals to</MenuItem>
                </Select>
              )}
            </Grid>

           
            <Grid item xs={4}>
              {condition.parameter && (
                <TextField
                  fullWidth
                  label="Amount"
                  value={condition.amount}
                  onChange={(e) => handleConditionChange(index, 'amount', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R</InputAdornment>,
                  }}
                />
              )}
            </Grid>

          
            
          </Grid>
        ))}

        
        {canAddCondition && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '24px',
            }}
            onClick={addCondition}
          >
            <AddCircleOutline color="success" />
            <Typography sx={{ marginLeft: '8px', color: 'rgba(61, 127, 142, 1)', fontSize: '14px', lineHeight: '19px', letterSpacing: '-0.15%', fontWeight: 600 }}>Add a condition</Typography>
          </Box>
        )}

       
        <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormControlLabel
          
          control={
            <ToggleSwitch
              checked={isAllFieldsFilled}
              onChange={() => setIsAllFieldsFilled(!isAllFieldsFilled)}
            />
          }
          label={
            <span style={{ marginLeft: '10px' }}> 
              {isAllFieldsFilled ? 'This flag is active' : 'Fill out all required fields to activate this flag'}
            </span>
          }
        />

          
          <Box>
            <Button
              sx={{
                width: '93px',
                height: '39px',
                padding: '10px 24px',
                gap: '8px',
                borderRadius: '4px',
                backgroundColor: 'white',
                border: '1px solid rgba(61, 127, 142, 1)',
                color: 'rgba(61, 127, 142, 1)',
                marginRight: '8px'
              }}
              onClick={onClose} variant="outlined">
              Cancel
            </Button>
           
            {
  type === "Active" && (
    <Button
      onClick={handleSave}
      variant="contained"
      color="success"
      disabled={!isAllFieldsFilled}
      sx={{
        width: '93px',
        height: '39px',
        padding: '10px 24px',
        gap: '8px',
        borderRadius: '4px',
        backgroundColor: 'rgba(61, 127, 142, 1)',
        color: 'white',
      }}
    >
      Archive
    </Button>
  )
}

{
  type === "Archived" && (
    <Button
      onClick={handleSave}
      variant="contained"
      color="success"
      disabled={!isAllFieldsFilled}
      sx={{
        width: '93px',
        height: '39px',
        padding: '10px 24px',
        gap: '8px',
        borderRadius: '4px',
        backgroundColor: 'rgba(61, 127, 142, 1)',
        color: 'white',
      }}
    >
      Restore
    </Button>
  )
}

{
  (type == null) && (
    <Button
      onClick={handleSave}
      variant="contained"
      color="success"
      disabled={!isAllFieldsFilled}
      sx={{
        width: '93px',
        height: '39px',
        padding: '10px 24px',
        gap: '8px',
        borderRadius: '4px',
        backgroundColor: 'rgba(61, 127, 142, 1)',
        color: 'white',
      }}
    >
      Save
    </Button>
  )
}


          </Box>
        </Box>
      </Box>
    </Modal>
      
      {flagDetails && type === "Active" ? (
    <ResponseModal
      title="Archive flag?"
      body="By archiving this flag, all previously flagged transactions will no longer be affected."
      isOpen={openConfirmSuccessModal}
      onClose={() => setOpenConfirmSuccessModal(false)}
    />

  ) : (
    <ResponseModal
      title="Save Changes?"
      body="You made changes to this flag, would you like to save it?"
      isOpen={openConfirmSuccessModal}
      onClose={() => setOpenConfirmSuccessModal(false)}
    />
  )}

    </>
    
  );
};

export default FlagModal;
