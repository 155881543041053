import React, { FC, useState } from "react";
import { Box, Container, Grid, Typography, Button, TextField } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import useResetPasswordForm from "../../../common/helpers/hooks/useResetPasswordForm";
import BaseCard from "../Cards/BaseCard";

export const ResetPasswordForm: FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { userId, token } = useParams<{ userId: string; token: string }>();

  const safeUserId = userId || "";
  const safeToken = token || "";

  const formik = useResetPasswordForm(safeUserId, safeToken);

  return (
    <Container
      maxWidth="sm"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <BaseCard sx={{ height: "auto", width: "100%", padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <img
                src="/static/pumpkn-logo2.png"
                alt="Pumpkn logo"
                width={80}
                height={80}
              />
              <Typography
                lineHeight="24.75px"
                fontSize="18px"
                fontWeight="700"
                color="#111827"
                sx={{ mt: 2 }}
                variant="body1"
              >
                Reset Password
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body1">
                Enter your new password
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="New Password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  sx={{ mt: 2 }}
                />
                {formik.errors.submit && (
                  <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                    {formik.errors.submit}
                  </Typography>
                )}
                <Button
                  variant="contained"
                   sx={{ background: "#619C9C", mt: 3 }}
                    size="small"
                  fullWidth
                  type="submit"
                  disabled={formik.isSubmitting || isLoading}
                >
                  {formik.isSubmitting || isLoading ? "Resetting..." : "Reset Password"}
                </Button>
              </Box>
            </form>
          </Grid>

          {isSuccess && (
            <Grid item xs={12}>
              <Typography color="green" variant="body2" align="center">
                Password reset successfully! You can now log in.
              </Typography>
            </Grid>
          )}

          <Grid className="grid__item" item xs={12}>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <RouterLink to="/authentication/login" style={{ textDecoration: 'none' }}>
                  <Typography color="textSecondary" variant="body2">
                    Remember your password? Login
                  </Typography>
                </RouterLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BaseCard>
    </Container>
  );
};

export default ResetPasswordForm;
