import React, { useState } from "react";
import { Modal, Box, Typography, Tooltip, useTheme } from "@mui/material";
import {
	Delete,
	Eye,
	Upload as UploadIcon,
	Visibility as VisibilityIcon,
} from "@mui/icons-material";
import CustomButton from "../Elements/CustomButton";
import CustomSelect from "../Elements/CustomSelect";
import moment from "moment";
import CustomTable from "../Tables/CustomTable";
import PdfViewerModal from "./PdfViewModal";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { removeDocument } from "../../../common/services/retool-service";
import ConfirmationModal from "./ConfirmationModal";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

const ViewDocumentsModal = ({
	open,
	handleClose,
	selectedRow,
	isFetching = false,
}) => {
	const plugin = defaultLayoutPlugin();
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [selectedDoc, setSelectedDoc] = useState(null);

	const handleConfirmDelete = (doc) => {
		setSelectedDoc(doc);
		setOpenConfirmationModal(true);
	};

	const queryClient = useQueryClient();

	const handleDelete = async () => {
		setIsLoading(true);

		try {
			const response = await removeDocument({
				userId: selectedRow?.userId,
				documentId: selectedDoc.id,
				id: selectedDoc.id,
				documentRef: selectedDoc.documentRef,
			});

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("dealChecklists");
				queryClient.invalidateQueries("clientDealChecklists");
			} else toast.error(response.data.message);
		} catch (error) {
			toast.error("Failed to delete document.");
		} finally {
			setIsLoading(false);
			handleClose();
		}
	};

	const columns = [
		{ title: "Document Ref", key: "documentRef", sortable: true },
		{ title: "Document Name", key: "documentName", sortable: true },
		{
			title: "Uploaded Timestamp",
			key: "uploadedTimestamp",
			sortable: true,
			render: (rowData) =>
				moment(rowData.uploadedTimestamp).format("YYYY-MM-DD"),
		},
		{
			title: "Is Expired",
			key: "isExpired",
			isPill: true,
			sortable: true,
			render: (rowData) => (rowData.isExpired ? "Yes" : "No"),
		},
	];

	const actionIcons = (doc) => [
		{
			icon: (
				<Tooltip title="Delete" arrow>
					<Delete
						style={{
							color: "red",
							fontSize: "16px",
						}}
					/>
				</Tooltip>
			),
			onClick: () => handleConfirmDelete(doc),
		},
		{
			icon: (
				<Tooltip title="View" arrow>
					<VisibilityIcon sx={{ fill: "#000", fontSize: "16px" }} />
				</Tooltip>
			),
			onClick: () => {
				setPdfUrl(doc.downloadUrl);
				setOpenPdfDialog(true);
			},
		},
	];

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					width: "100vw",
					maxHeight: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					overflowY: "auto",
					top: "0%",
				}}
			>
				<Typography variant="h6" mb={4}>
					Uploaded Documents
				</Typography>

				<CustomTable
					isLoading={isFetching}
					columns={columns}
					data={selectedRow?.uploadedDocuments || []}
					icons={actionIcons}
					actionColumn={{
						title: "Actions",
					}}
				/>

				<Box display="flex" justifyContent="flex-end" mt={2}>
					<CustomButton
						text="Close"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
					/>
				</Box>

				<PdfViewerModal
					open={openPdfDialog}
					handleClose={() => setOpenPdfDialog(false)}
					pdfUrl={pdfUrl}
					plugin={plugin}
				/>

				<ConfirmationModal
					open={openConfirmationModal}
					onClose={() => setOpenConfirmationModal(false)}
					message="Are you sure you want to delete this document?"
					handleConfirmAction={handleDelete}
					isLoading={isLoading}
				/>
			</Box>
		</Modal>
	);
};

export default ViewDocumentsModal;
