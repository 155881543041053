import React from 'react';
import { Box, TextField, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  width?: string;
  height?: string;
}

const CustomSearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  onSearchChange,
  placeholder = 'Search',
  width = '360px',
  height = '56px',
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <TextField
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={onSearchChange}
        placeholder={placeholder}
        sx={{
          width,
          height,
          borderRadius: '28px',
          background: theme.palette.mode === 'dark' ? 'rgba(60, 60, 60, 1)' : 'rgba(245, 245, 245, 1)',
          '& input': {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.5px',
            textAlign: 'left',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000', 
            padding: '15px 12px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent', 
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent', 
          },
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon
              sx={{
                color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(90, 90, 90, 1)',
                fontSize: '30px',
                marginTop: '10px',
              }}
            />
          ),
        }}
      />
    </Box>
  );
};

export default CustomSearchBar;
