import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	Typography,
	TextField,
	IconButton,
	Chip,
	MenuItem,
	Select,
	CircularProgress,
} from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import { Add, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
	useCreateKeyword,
	useCreatePrimaryCategory,
	useDeleteKeyword,
} from "../../../common/helpers/hooks/useLabellingCategory";
import toast from "react-hot-toast";
import {
	createSecondaryCategory,
	deleteKeyword,
	updateSecondaryCategory,
} from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";

interface CreateAndUpdateCategoryModalProps {
	open: boolean;
	onClose: () => void;
	isEdit: boolean;
	initialData?: any;
	categories: any[];
	refetch: () => void;
}

const CreateAndUpdateCategoryModal: React.FC<
	CreateAndUpdateCategoryModalProps
> = ({ open, onClose, isEdit, initialData, categories, refetch }) => {
	const theme = useTheme();
	const [parentCategories, setParentCategories] = useState<any>([]);
	const [categoryData, setCategoryData] = useState({
		secondaryCategory: "",
		primaryCategory: "",
		keywords: [] as any[],
	});

	const [newKeyword, setNewKeyword] = useState("");
	const [parentModalOpen, setParentModalOpen] = useState(false);
	const [newParentCategory, setNewParentCategory] = useState("");
	const [loading, setLoading] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (initialData) {
			setCategoryData({
				secondaryCategory: initialData.secondaryCategory || "",
				primaryCategory: initialData.primaryCategory || "",
				keywords: initialData.keywords || [],
			});
		} else {
			setCategoryData({
				secondaryCategory: "",
				primaryCategory: "",
				keywords: [],
			});
		}
	}, [initialData]);

	useEffect(() => {
		const uniqueCategories = Array.from(
			new Set(categories.map((x) => x.primaryCategory))
		);
		setParentCategories(uniqueCategories);
	}, [categories]);

	const createKeywordMutation = useCreateKeyword();
	const deleteKeywordMutation = useDeleteKeyword();

	const handleAddKeyword = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" && newKeyword.trim()) {
			const trimmedKeyword = newKeyword.trim();

			setCategoryData((prev) => ({
				...prev,
				keywords: [
					...prev.keywords,
					{ id: Date.now(), keyword: trimmedKeyword },
				], // Temporary ID for keyword
			}));

			setNewKeyword("");

			createKeywordMutation.mutate(
				{
					keyword: trimmedKeyword,
					secondaryCategory: categoryData.secondaryCategory,
				},
				{
					onSuccess: () => {
						toast.success("Keyword added successfully.");
						queryClient.invalidateQueries("primaryCategoriesWithDetails");
						refetch();
					},
					onError: (error) => {
						toast.error("Failed to add keyword.");
					},
				}
			);
		}
	};

	const handleDeleteKeyword = async (keywordToDelete: any) => {
		const loadingToastId = toast.loading("Deleting keyword...");

		try {
			if (keywordToDelete) {
				await deleteKeyword(keywordToDelete);

				toast.success("Keyword deleted successfully", {
					id: loadingToastId,
					duration: 2000,
				});
				queryClient.invalidateQueries("primaryCategoriesWithDetails");
				refetch();
			}
		} catch (error) {
			toast.error("Failed to delete keyword", {
				id: loadingToastId,
				duration: 2000,
			});
			console.error("Error deleting keyword:", error);
		}
	};

	const handleOpenParentModal = () => setParentModalOpen(true);
	const handleCloseParentModal = () => setParentModalOpen(false);
	const createPrimaryCategoryMutation = useCreatePrimaryCategory();

	const handleAddParentCategory = () => {
		if (newParentCategory.trim()) {
			const trimmedCategory = newParentCategory.trim();
			setCategoryData((prev) => ({ ...prev, parentCategory: trimmedCategory }));
			setNewParentCategory("");

			createPrimaryCategoryMutation.mutate(
				{ primaryCategory: trimmedCategory },
				{
					onSuccess: () => {
						setNewParentCategory("");
						toast.success("Primary category created successfully.");
						queryClient.invalidateQueries("primaryCategoriesWithDetails");
						refetch();
					},
				}
			);

			handleCloseParentModal();
		}
	};

	const handleSubmit = async () => {
		setLoading(true);

		try {
			if (isEdit) {
				await updateSecondaryCategory(
					initialData.secondaryCategory,
					categoryData.secondaryCategory
				);

				toast.success("Secondary category updated successfully.");
				queryClient.invalidateQueries("primaryCategoriesWithDetails");
				refetch();
			} else {
				await createSecondaryCategory(
					categoryData.primaryCategory,
					categoryData.secondaryCategory
				);

				toast.success("Secondary category created successfully.");
			}
		} catch (error) {
			toast.error("An error occurred. Please try again.");
		} finally {
			setLoading(false);
			onClose();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box
					sx={{
						width: "600px",
						backgroundColor: theme.palette.background.paper,
						padding: "24px",
						borderRadius: "8px",
						margin: "auto",
						top: "20%",
						position: "relative",
						boxShadow: theme.shadows[5],
					}}
				>
					<IconButton
						sx={{
							position: "absolute",
							top: "16px",
							right: "16px",
							color: theme.palette.grey[500],
						}}
						onClick={onClose}
					>
						<Close />
					</IconButton>

					<Typography
						variant="h5"
						mb={3}
						sx={{ color: theme.palette.text.primary }}
					>
						{isEdit
							? "Edit Category and Keywords"
							: "Create Category and Keywords"}
					</Typography>

					<TextField
						label="Category Name"
						fullWidth
						value={categoryData.secondaryCategory}
						onChange={(e) =>
							setCategoryData({
								...categoryData,
								secondaryCategory: e.target.value,
							})
						}
						sx={{ marginBottom: "20px" }}
					/>

					<Box
						sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
					>
						<Select
							fullWidth
							value={categoryData.primaryCategory}
							onChange={(e) =>
								setCategoryData({
									...categoryData,
									primaryCategory: e.target.value,
								})
							}
							displayEmpty
						>
							<MenuItem value="" disabled>
								Select Parent Category
							</MenuItem>
							{parentCategories.map((parent: any) => (
								<MenuItem key={parent} value={parent}>
									{parent}
								</MenuItem>
							))}
						</Select>
						<IconButton onClick={handleOpenParentModal}>
							<Add />
						</IconButton>
					</Box>

					<TextField
						label="Add Keywords"
						fullWidth
						value={newKeyword}
						onChange={(e) => setNewKeyword(e.target.value)}
						onKeyDown={handleAddKeyword}
						sx={{ marginBottom: "20px" }}
					/>

					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: "8px",
							marginBottom: "20px",
						}}
					>
						{categoryData.keywords.map((keyword, index) => (
							<Chip
								key={index}
								label={keyword.keyword}
								onDelete={() => handleDeleteKeyword(keyword.id)}
								sx={{ backgroundColor: theme.palette.grey[200] }}
							/>
						))}
					</Box>

					<Box
						sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
					>
						<CustomButton
							text="Cancel"
							variant="outlined"
							color="secondary"
							onClick={handleCancel}
						/>
						<CustomButton
							text={isEdit ? "Update Category" : "Create Category"}
							variant="contained"
							onClick={handleSubmit}
							disabled={loading}
						/>
					</Box>
					{loading && (
						<CircularProgress
							size={24}
							sx={{ position: "absolute", top: "20px", right: "20px" }}
						/>
					)}
				</Box>
			</Modal>

			<Modal open={parentModalOpen} onClose={handleCloseParentModal}>
				<Box
					sx={{
						width: "400px",
						backgroundColor: theme.palette.background.paper,
						padding: "16px",
						borderRadius: "8px",
						margin: "auto",
						top: "30%",
						position: "relative",
						boxShadow: theme.shadows[5],
					}}
				>
					<Typography
						variant="h6"
						mb={3}
						sx={{ color: theme.palette.text.primary }}
					>
						Add New Parent Category
					</Typography>
					<TextField
						label="New Parent Category"
						fullWidth
						value={newParentCategory}
						onChange={(e) => setNewParentCategory(e.target.value)}
						sx={{ marginBottom: "20px" }}
					/>
					<Box
						sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
					>
						<CustomButton
							text="Cancel"
							variant="outlined"
							color="secondary"
							onClick={handleCloseParentModal}
						/>
						<CustomButton
							text="Add Parent Category"
							variant="contained"
							onClick={handleAddParentCategory}
						/>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default CreateAndUpdateCategoryModal;
