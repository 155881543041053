import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import BaseCard from '../../common/components/Cards/BaseCard';
import { Client, clientsDummyData } from '../../data/clients';
import { ArrowBack, CalendarToday, PushPin } from '@mui/icons-material';
import CustomTable from '../../common/components/Tables/CustomTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography, useTheme } from '@mui/material';
import ListCard from '../../common/components/Cards/ListCard';
import CircleWithText from '../../common/components/Elements/CircleWithText';
import LinearProfileCard from '../../common/components/Cards/LinearProfileCard';
import HorizontalCard from '../../common/components/Elements/HorizontalCard';

import FinanceDetails from '../../common/components/Charts/FinancialDetails';
import LineChartDetails from '../../common/components/Charts/LineChartDetails';
import { useNavigate, useParams } from 'react-router-dom';

const SingleClientPage: React.FC = () => {

  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();

  const { id } = useParams(); 
  const [clientDetails, setClientDetails] = useState<Client | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = clientsDummyData.filter(client =>
    client.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.contact.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBack = () => { navigate("/clients")}

  useEffect(() => {
    
    const foundClient = clientsDummyData.find(client => client.id === id) || null;
    setClientDetails(foundClient);
  }, [id]);


  return (
    <Box
      sx={{
        py: 4,
        px: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        gap: 2,
      }}
    >
      <Box sx={{ cursor: 'pointer'}} onClick = {handleBack} p={2} display="flex" gap={1} alignItems="center" justifyContent="center" width="100%">
        <ArrowBack sx={{ fill: 'rgba(73, 69, 79, 1)' }} />
        <GreetingMessage title= {clientDetails ? clientDetails.contact : 'Client not found'} />
      </Box>

      <Grid container spacing={1} justifyContent="center" width="100%">
        <Grid item xs={12} lg={8}>
          <Grid mb={1} container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={6}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
                <CircleWithText bgcolor='rgba(179, 38, 30, 1)' innerText='4' besideText='Flagged transactions' />
              </BaseCard>
            </Grid>
            <Grid item xs={12} lg={6}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
                <CircleWithText bgcolor='rgba(229, 160, 0, 1)' innerText='3' besideText='Suspicious transactions' />
              </BaseCard>
            </Grid>
          </Grid>
          
          <LinearProfileCard
            title='Profile risk status'
            risk='Medium'
            label='65% - Below portfolio average'
          />

        </Grid>

        <Grid item xs={12} lg={4}>
          <ListCard
            title="Activity Log"
            logs={{
              "Activity": [
                { text: "Bank account linked", alternativeText: '2022-2-2' },
                { text: "Repayment processed", alternativeText: '2022-2-2' },
                { text: "Risk status changed from low to medium", alternativeText: '2022-2-2' }
              ]
            }}
            showCheckboxes={false}
            tabs={false}
            alternativeTextInColumn={true}
            autoHeight={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid mb={1} container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={6}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
                <HorizontalCard besideTextDirection='row' innerText='Task 1/4' besideText='To do: Task description here' />
              </BaseCard>
            </Grid>
            <Grid item xs={12} lg={6}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
              <HorizontalCard 
                  besideTextDirection='column' 
                  innerText='4/8 monthly payments' 
                  besideText='Instalment history' 
                  circles={[
                      { isChecked: true },
                      { isChecked: true },
                      { isChecked: true },
                      { isChecked: false },
                      { isChecked: null },
                      { isChecked: null },
                      { isChecked: null },
                      { isChecked: null }
                  ]}
              />

              </BaseCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BaseCard sx={{ height: 'auto' }}>
        <Box width="100%" px={2} py={4}>
           
        <Typography
            sx={{
                fontSize: '22px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '-0.0026em',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
          >
            Transactions
          </Typography>
        
          <Box my={2} p={0} width="100%" display="flex" alignItems="center" justifyContent="flex-start" gap={1}>
    <Box style={{ 
        border: "1px solid rgba(121, 116, 126, 1)",
        borderRadius: "8px",
        padding: "5px 10px",
    }}>
        <Typography
            style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.15px',
                textAlign: 'center',
                color: theme.palette.text.primary,
            }}
        >
            Last 12 months
        </Typography>
    </Box>
    <Box style={{ 
        border: "1px solid rgba(121, 116, 126, 1)",
        borderRadius: "8px",
        padding: "5px 10px",
    }}>
        <Typography
            style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.15px',
                textAlign: 'center',
                color: theme.palette.text.primary,
            }}
        >
            Calendar year to date
        </Typography>
    </Box>
    <Box style={{ 
        border: "1px solid rgba(121, 116, 126, 1)",
        borderRadius: "8px",
        padding: "5px 10px",
    }}>
        <Typography
            style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.15px',
                textAlign: 'center',
                color: theme.palette.text.primary,
            }}
        >
            Financial year to date
        </Typography>
    </Box>
    <Box style={{ width: '1px', height: '30px', backgroundColor: 'rgba(121, 116, 126, 1)', marginLeft: '8px', marginRight: '8px' }} />
    
    <Box style={{ position: 'relative', height: '48px' }}>
        <label style={{
            position: 'absolute',
            top: '-10px',
            left: '10px',
            backgroundColor: 'white',
            padding: '0 4px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: 'rgba(73, 69, 79, 1)',
            zIndex: 1,
        }}>
            From
        </label>
        <TextField
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            InputProps={{
                style: {
                    height: '48px',
                    border: '2px solid green',
                    borderRadius: '4px',
                    padding: '10px',
                },
            }}
        />
    </Box>
    
    <span style={{
    alignSelf: 'center',
    margin: '0',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'rgba(73, 69, 79, 1)',
}}>
    &mdash;
</span>

    
    <Box style={{ position: 'relative' }}>
        <label style={{
            position: 'absolute',
            top: '-10px',
            left: '10px',
            backgroundColor: 'white',
            padding: '0 4px',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: 'rgba(73, 69, 79, 1)',
            zIndex: 1,
        }}>
            To
        </label>
        <TextField
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            InputProps={{
                style: {
                    height: '48px',
                    border: '2px solid green',
                    borderRadius: '4px',
                    padding: '10px',
                },
            }}
        />
    </Box>
          </Box>


        <Grid padding={0} container spacing={2} my={2}>
            <Grid item xs={12} lg={5}>
                <FinanceDetails
                    total={95000}
                    income={95000}
                    expense={95000}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <CircleWithText bgcolor='rgba(179, 38, 30, 1)' innerText='4' besideText='Flagged transactions' />
                  <CircleWithText bgcolor='rgba(229, 160, 0, 1)' innerText='3' besideText='Suspicious transactions' />
                  <CircleWithText bgcolor='rgba(0, 153, 81, 1)' innerText='3' besideText='Flags cleared' />
                </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
            <Box display="flex" justifyContent="space-between" p={2}>
                <LineChartDetails 
                labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}  
                data={{ data1: [500000, 800000, 900000, 600000, 750000, 1000000, 1200000, 900000, 950000, 850000, 900000, 1000000], 
                    data2: [400000, 600000, 1000000, 500000, 800000, 1200000, 1300000, 1000000, 950000, 950000, 1100000, 900000] }}
                maxValue={1300000} />
            </Box>
            </Grid>
        </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
           
          <Typography
            sx={{
                fontSize: '22px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '-0.0026em',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
          >
              Linked account transactions
          </Typography>


            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder='Search clients'
                sx={{
                  width: '360px',
                  height: '56px',
                  borderRadius: '28px',
                  background: 'rgba(245, 245, 245, 1)',
                  '& input': {
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.5px',
                    textAlign: 'left',
                    color: '#000',
                    padding: '15px 12px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ color: 'rgba(90, 90, 90, 1)', fontSize: '30px' }} />
                  ),
                }}
              />
            </Box>
          </Box>
          
          <CustomTable
            actionColumn={{
              title: 'Pinned',
              icon: <PushPin sx={{ fill: '#000' }} />,
            }}
            columns={[
              { title: 'Company', key: 'company', sortable: true },
              { title: 'Contact', key: 'contact', sortable: true },
              { title: 'Active Since', key: 'activeSince', sortable: true },
              { title: 'Borrowed', key: 'borrowed', sortable: true },
              { title: 'Remaining Term', key: 'remainingTerm', sortable: true },
              { title: 'Tasks', key: 'tasks', sortable: true },
              { title: 'Risk Status', key: 'riskStatus', sortable: true },
              { title: 'Flags', key: 'flags', sortable: true },
            ]}
            data={filteredData}
          />
        </Box>
      </BaseCard>
    </Box>
  );
};

export default SingleClientPage;
