import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	Checkbox,
	CircularProgress,
	useTheme,
} from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import toast from "react-hot-toast";
import { updateLoanContractStatus } from "../../services/retool-service";
import { useQueryClient } from "react-query";

const UpdateContractStatus = ({ open, handleClose, selectedRow }) => {
	const theme = useTheme();
	const [isSigned, setIsSigned] = useState(selectedRow?.contractSigned);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const handleValidation = () => {
		setIsSigned((prev) => !prev);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const response = await updateLoanContractStatus({
				contractId: selectedRow?.id,
				contractSigned: isSigned,
			});

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("loanContracts");
			} else {
				toast.error(response.data.message);
			}

			handleClose();
		} catch (error) {
			toast.error("Failed to update contract");
		}
		setIsLoading(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					padding: 4,
					backgroundColor: theme.palette.background.paper,
					borderRadius: 2,
					outline: "none",
					maxWidth: 400,
					margin: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<Typography variant="h6" mb={2}>
					Update Loan Contract
				</Typography>
				<Typography>Kindly check the box if the contract is signed.</Typography>
				<Box display="flex" alignItems="center" mt={2}>
					<Checkbox
						checked={isSigned}
						onChange={handleValidation}
						color="success"
					/>
					Contract Signed
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					mt={2}
					gap={2}
					alignItems="center"
				>
					<CustomButton
						text="Cancel"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
					/>
					{isLoading ? (
						<CircularProgress size="1rem" color="success" />
					) : (
						<CustomButton
							text="Submit"
							onClick={handleSubmit}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateContractStatus;
