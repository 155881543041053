import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface DottedLabelProps {
    dottedText: string;
    dottedColor: string;
    dottedSize: string;
    dottedSubText?: string;
}

const DottedLabel: React.FC<DottedLabelProps> = ({ dottedText, dottedColor, dottedSize, dottedSubText }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
            <Box
                sx={{
                    width: dottedSize,
                    height: dottedSize,
                    borderRadius: '50%',
                    marginRight: '8px',
                    bgcolor: dottedColor
                }}
            />
            {dottedSubText ? (
                <Box display="flex" flexDirection="column">
                    <Typography
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '19.07px',
                            textAlign: 'left',
                        }}
                    >
                        {dottedText}
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: '10px',
                            fontWeight: 400,
                            lineHeight: '15px',
                            letterSpacing: '-0.15%',
                            textAlign: 'left',
                        }}
                    >
                        {dottedSubText}
                    </Typography>
                </Box>
            ) : (
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '19.07px',
                        textAlign: 'left',
                    }}
                >
                    {dottedText}
                </Typography>
            )}
        </Box>
    );
};

export default DottedLabel;
