import React, { useEffect, useState } from "react";
import {
	Box,
	Modal,
	Typography,
	CircularProgress,
	IconButton,
	Switch,
	FormControlLabel,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomSelect from "../Elements/CustomSelect";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import {
	DatePicker,
	TimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { createOrUpdateLendingReminder } from "../../../common/services/retool-service";
import ToggleSwitch from "../Elements/CustomSwitch";
import { getCurrentUserEmail } from "../../../common/services/LocalStorageService";

const ReminderModal = ({ open, onClose, reminderData, deals }) => {
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState(reminderData?.name || "");
	const [description, setDescription] = useState(
		reminderData?.description || ""
	);
	const [status, setStatus] = useState(reminderData?.status || "Pending");
	const [email, setEmail] = useState(
		reminderData?.email || getCurrentUserEmail() || ""
	);
	const [frequency, setFrequency] = useState(
		reminderData?.frequency || "Daily"
	);
	const [dealId, setDealId] = useState(reminderData?.dealId || 0);
	const [isAssociatedWithDeal, setIsAssociatedWithDeal] = useState(!!dealId);
	const [endDate, setEndDate] = useState(
		reminderData?.endDate ? moment(reminderData.endDate) : null
	);
	const [customTime, setCustomTime] = useState(
		reminderData?.customTime
			? moment(reminderData.customTime, "HH:mm")
			: moment("08:00", "HH:mm")
	);
	const [isEndDateEnabled, setIsEndDateEnabled] = useState(!!endDate);

	const handleDealAssociationToggle = () => {
		setIsAssociatedWithDeal(!isAssociatedWithDeal);
		if (!isAssociatedWithDeal) setDealId(null);
	};

	const handleEndDateToggle = () => {
		setIsEndDateEnabled(!isEndDateEnabled);
		if (!isEndDateEnabled) setEndDate(null);
	};

	const clearData = () => {
		setName("");
		setDescription("");
		setStatus("Pending");
		setEmail(getCurrentUserEmail() || "");
		setFrequency("Daily");
		setDealId(0);
		setIsAssociatedWithDeal(false);
		setEndDate(null);
		setCustomTime(moment("08:00", "HH:mm"));
		setIsEndDateEnabled(false);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving reminder...");

		const payload = {
			id: reminderData?.id || 0,
			name,
			description,
			status,
			email,
			frequency,
			dealId,
			endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
			customTime: customTime ? customTime.format("HH:mm") : "08:00",
		};

		try {
			const response = await createOrUpdateLendingReminder(payload);
			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				queryClient.invalidateQueries("lendingReminders");
				clearData();
			} else {
				toast.error(response.data.message, { id: toastId });
			}

			onClose();
		} catch (error) {
			toast.error("Failed to save reminder.", { id: toastId });
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (reminderData) {
			setName(reminderData?.name);
			setCustomTime(
				reminderData?.customTime
					? moment(reminderData.customTime, "HH:mm")
					: moment("08:00", "HH:mm")
			);
			setDealId(reminderData?.dealId || 0);
			setDescription(reminderData?.description);
			setEmail(reminderData?.email || getCurrentUserEmail());
			setEndDate(reminderData?.endDate ? moment(reminderData.endDate) : null);
			setFrequency(reminderData?.frequency);
			setStatus(reminderData?.status);
			setIsAssociatedWithDeal(reminderData?.dealId ? true : false);
			setIsEndDateEnabled(reminderData?.endDate ? true : false);
		} else {
			clearData();
		}
	}, [reminderData, deals]);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					maxWidth: 800,
					height: "100%",
					padding: 3,
					borderRadius: 1,
					backgroundColor: "background.paper",
					position: "relative",
					margin: "auto",
					overflowY: "scroll",
				}}
			>
				<IconButton
					onClick={onClose}
					sx={{ position: "absolute", top: 10, right: 10 }}
				>
					<Close />
				</IconButton>

				<Typography variant="h6" mb={2}>
					{reminderData ? "Update Reminder" : "Create Reminder"}
				</Typography>

				<Typography variant="body2" color="orange" mb={2}>
					All reminders are sent at 8:00AM by default. If an alternative time is
					preferred, specify it below.
				</Typography>

				<Box
					display="flex"
					flexDirection="column"
					justifyContent="flex-start"
					gap={1}
				>
					<CustomTextInput
						label="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<CustomTextInput
						label="Description"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						multiline
						rows={4}
					/>

					<CustomSelect
						label="Status"
						value={status}
						onChange={(e) => setStatus(e.target.value)}
						options={[
							{ value: "Pending", label: "Pending" },
							{ value: "Sent", label: "Sent" },
							{ value: "Failed", label: "Failed" },
						]}
					/>

					<CustomSelect
						label="Frequency"
						value={frequency}
						onChange={(e) => setFrequency(e.target.value)}
						options={[
							{ value: "Daily", label: "Daily" },
							{ value: "Weekly", label: "Weekly" },
							{ value: "Monthly", label: "Monthly" },
						]}
					/>

					<CustomTextInput
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Box display="flex" alignItems="center" gap={1} width="100%">
						<ToggleSwitch
							checked={isAssociatedWithDeal}
							onChange={handleDealAssociationToggle}
						/>
						<Typography variant="body1">
							Is this reminder associated with a specific deal?
						</Typography>
					</Box>
					{isAssociatedWithDeal && (
						<CustomSelect
							label="Deal"
							value={dealId}
							onChange={(e) => setDealId(e.target.value)}
							options={deals?.map((deal) => ({
								value: deal.id,
								label: `${deal.email} - R${deal.fundingAmount.toLocaleString()} - ${deal.duration}days`,
							}))}
						/>
					)}

					<Box display="flex" alignItems="center" gap={1} mb={2}>
						<ToggleSwitch
							checked={isEndDateEnabled}
							onChange={handleEndDateToggle}
						/>
						<Typography variant="body1">Specify an end date</Typography>
					</Box>

					{isEndDateEnabled && (
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								label="End Date"
								value={endDate}
								onChange={(date) => setEndDate(date)}
								sx={{ mb: 2, width: "100%" }}
							/>
							<TimePicker
								label="Reminder Time"
								value={customTime}
								onChange={(time) => setCustomTime(time)}
								sx={{ mb: 2, width: "100%" }}
							/>
						</LocalizationProvider>
					)}
				</Box>

				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					mt={3}
					gap={2}
				>
					<CustomButton
						text="Cancel"
						onClick={onClose}
						variant="outlined"
						color="secondary"
					/>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Save Reminder"
							onClick={handleSubmit}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ReminderModal;
