import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import DottedLabel from '../Elements/DottedLabel';
import PrimaryHeading from '../Elements/PrimaryHeading';
import SecondaryText from '../Elements/SecondaryText';
import PillLabel from '../Elements/PillLabel';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FinanceDetails from '../Charts/FinancialDetails';

interface FinanceCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    income: number;
    expense: number;
    total: number;
}

const FinanceCard: React.FC<FinanceCardProps> = ({
    title,
    titleIcon,
    income,
    expense,
    total
}) => {
    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <FinanceDetails total={total} income={income} expense={expense} />
        </BaseCard>
    );
};

export default FinanceCard;
