import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTable from "../Tables/CustomTable";
import DealsDealChecklistFloatingActionMenu from "../Menus/DealsDealchecklistFloatingActionMenu";
import { useTheme } from "@emotion/react";
import { useDealChecklists } from "../../../common/helpers/hooks/useClientService";
import { validateUploadedApplicationDocs } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";

interface DealChecklistProps {
	selectedApplication: any;
	handlePrev: Function;
	handleNext: Function;
}

interface DealChecklistRow {
	id: string;
	requirementName: string;
	requirementDescription: string;
	category: string;
	documentRef: string;
	status: string;
	isExpired: string;
	isValidated: string;
	isClientRequestAlreadyAdded: boolean;
	uploadedDocuments?: {
		downloadUrl: string;
		documentRef: string;
		documentName: string;
		uploadedTimestamp: string;
		isExpired: boolean;
	}[];
}

const DealChecklist: React.FC<DealChecklistProps> = ({
	selectedApplication,
	handlePrev,
	handleNext,
}) => {
	const theme = useTheme();

	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const {
		data: clientDealchecklists,
		isLoading: isClientDealChecklistsFetching,
	} = useDealChecklists(selectedApplication?.id);

	const handleRowSelect = (row: any) => setSelectedRow(row);

	const queryClient = useQueryClient();

	const handleValidate = async (row: any, value: any) => {
		const toastId = toast.loading("Validating document...");

		try {
			const response = await validateUploadedApplicationDocs({
				id: row?.id?.toString(),
				documentRef: row?.documentRef,
				isValidated: !value,
				userId: selectedApplication?.userId,
			});

			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				queryClient.invalidateQueries("clientDealChecklists");
				queryClient.invalidateQueries("dealChecklists");
			} else {
				toast.error(response.data.message, { id: toastId });
			}
		} catch (error) {
			toast.error("Failed to validate document", { id: toastId });
		}
	};

	return (
		<>
			<CustomTable
				isLoading={isClientDealChecklistsFetching}
				columns={[
					{ title: "Requirement Name", key: "requirementName", sortable: true },
					{
						title: "Description",
						key: "requirementDescription",
						sortable: true,
					},
					{ title: "Category", key: "category", sortable: true },
					{ title: "Document Ref", key: "documentRef", sortable: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{
						title: "Is Expired",
						key: "isExpired",
						sortable: true,
						isPill: true,
					},
					{
						title: "Is Validated",
						key: "isValidated",
						sortable: true,
						isSwitch: true,
						handler: (row, value) => {
							if (row?.status?.toLowerCase() != "uploaded")
								toast.error("Please upload document before validation.");
							else handleValidate(row, value);
						},
					},
				]}
				data={clientDealchecklists?.mainData || []}
				onRowSelect={(rows) =>
					handleRowSelect(rows[rows ? rows.length - 1 : 0])
				}
				selected={selectedRows}
			/>

			{selectedRow && (
				<DealsDealChecklistFloatingActionMenu
					deal={selectedApplication}
					selectedRow={selectedRow}
				/>
			)}

			<Box
				mt={2}
				display="flex"
				justifyContent="center"
				alignItems="center"
				gap={2}
			>
				<CustomButton
					text="Back"
					onClick={() => handlePrev()}
					variant="outlined"
					color="secondary"
					sx={{ mr: 2 }}
				/>

				<CustomButton
					text="Proceed"
					onClick={() => handleNext()}
					variant="contained"
					color="success"
				/>
			</Box>
		</>
	);
};

export default DealChecklist;
