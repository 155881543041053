import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CustomCircularProgressBar = ({ value, progressColor, text }) => {
    return (
        <div style={{ width: 100, height: 100, position: 'relative' }}>
            <CircularProgressbar 
                value={value} 
                text=""
                strokeWidth="10"
                styles={buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'round',
                    textSize: '10px',
                    pathTransitionDuration: 0.5,
                    pathColor: progressColor,
                    textColor: '#5D5E62',
                    trailColor: '#d6d6d6',
                   

                })}
            />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '10px',
                fontWeight: 'bold',
                lineHeight: '14px',
                color: '#5D5E62',
                padding: '5px'
            }}>
                {text}
            </div>
        </div>
    );
};

export default CustomCircularProgressBar;
