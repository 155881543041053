import React, { useState } from "react";
import { Fab, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { Add } from "@mui/icons-material";

export interface Action {
	label: string;
	icon: React.ReactNode;
	onClick: () => void;
}

interface FloatingActionMenuProps {
	actions: Action[];
}

const FloatingActionMenu: React.FC<FloatingActionMenuProps> = ({ actions }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Fab
				aria-label="add"
				onClick={handleClick}
				style={{
					backgroundColor: "rgba(61, 127, 142, 1)",
					position: "fixed",
					bottom: 16,
					right: 16,
					color: "white",
				}}
			>
				<Add />
			</Fab>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{actions.map((action, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							action.onClick();
							handleClose();
						}}
					>
						<ListItemIcon>{action.icon}</ListItemIcon>
						<ListItemText primary={action.label} />
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default FloatingActionMenu;
