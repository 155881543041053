import React, { useState } from "react";
import { RemoveRedEye, CheckBox, Upload } from "@mui/icons-material";
import FloatingActionMenu, { Action } from "../Elements/Fab";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ViewDocumentsModal from "../Modals/ViewDocumentsModal";
import ValidateDocumentModal from "../Modals/ValidateDocuemntModal";
import UploadDocumentsModal from "../Modals/UploadDocumentsModal";
import PdfViewerModal from "../Modals/PdfViewModal";
import ClientRequestModal from "../Modals/ClientRequestModal";

interface FabProps {
	client?: any;
	selectedRow?: any;
}

const ClientsDealChecklistFloatingActionMenu: React.FC<FabProps> = ({
	client,
	selectedRow,
}) => {
	const [viewDialogOpen, setViewDialogOpen] = useState(false);
	const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
	const [validateDialogOpen, setValidateDialogOpen] = useState(false);
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");

	const plugin = defaultLayoutPlugin();

	const handleViewDocuments = () => {
		if (selectedRow?.uploadedDocuments?.length > 0) {
			setViewDialogOpen(true);
		} else {
			setPdfUrl(selectedRow.uploadedDocuments[0]?.downloadUrl || "");
		}
	};

	const actions: Action[] = [
		{
			label: "Upload",
			icon: <Upload />,
			onClick: () => setUploadDialogOpen(true),
		},
		{
			label: "View",
			icon: <RemoveRedEye />,
			onClick: () => setViewDialogOpen(true),
		},
		{
			label: "Validate",
			icon: <CheckBox />,
			onClick: () => setValidateDialogOpen(true),
		},

	];

	return (
		<>
			<ViewDocumentsModal
				open={viewDialogOpen}
				handleClose={() => setViewDialogOpen(false)}
				selectedRow={selectedRow}
			/>

			<ValidateDocumentModal
				open={validateDialogOpen}
				handleClose={() => setValidateDialogOpen(false)}
				selectedRow={selectedRow}
			/>

			<UploadDocumentsModal
				open={uploadDialogOpen}
				handleClose={() => setUploadDialogOpen(false)}
				data={client}
				doc={selectedRow}
			/>

			<PdfViewerModal
				open={openPdfDialog}
				handleClose={() => setOpenPdfDialog(false)}
				pdfUrl={pdfUrl}
				plugin={plugin}
			/>

			<FloatingActionMenu actions={actions} />
		</>
	);
};

export default ClientsDealChecklistFloatingActionMenu;
