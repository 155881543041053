import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomSelect from "../Elements/CustomSelect";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import { saveFundingNeed } from "../../../common/services/retool-service";
import { getProductType } from "../../../common/helpers/utils/productUtils";

interface FundingProps {
	selectedApplication: any;
	handlePrev: Function;
	handleNext: Function;
}

const FundingNeed: React.FC<FundingProps> = ({
	selectedApplication,
	handleNext,
	handlePrev,
}) => {
	const [isLoading, setIsLoading] = useState<Boolean>(false);
	const [formData, setFormData] = useState({
		fundingAmount: 5000,
		duration: 5,
		useOfFunds: [],
		securityOrCollateral: "None",
		productType: "",
		productTypeAdditionalContext: "",
		useOfFundsAdditionalContext: "",
		securityAdditionalContext: "",
	});

	useEffect(() => {
		if (selectedApplication) {
			setFormData((prevFormData) => ({
				...prevFormData,
				...selectedApplication,
				useOfFunds: selectedApplication?.useOfFunds?.split(",") || [],
				productType: getProductType(selectedApplication?.loanType || ""),
			}));
		}
	}, [selectedApplication]);

	const handleInputChange = (event: any) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSelectChange = (name: string) => (event: any) => {
		const value = event.target.value;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving funding need...");

		const payload = {
			userId: selectedApplication?.client?.userId,
			fundingAmount: formData.fundingAmount,
			duration: formData.duration,
			useOfFunds: formData.useOfFunds.join(","),
			securityOrCollateral: formData.securityOrCollateral,
			productType: formData.productType,
			productTypeAdditionalContext: formData.productTypeAdditionalContext,
			useOfFundsAdditionalContext: formData.useOfFundsAdditionalContext,
			securityAdditionalContext: formData.securityAdditionalContext,
			applicationId: selectedApplication?.id || 0,
		};

		try {
			const response = await saveFundingNeed(payload);
			toast.success(response.data.message, { id: toastId });
			handleNext();
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<ResponsiveGrid columnCount={2}>
				<BaseCard sx={{ padding: "20px", height: "auto" }}>
					<Box component="form">
						<CustomCardHeader title="Funding Details" />

						<CustomTextInput
							key="fundingAmount"
							name="fundingAmount"
							label="Estimated Funding Amount (Rands)"
							type="number"
							value={formData.fundingAmount}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomTextInput
							key="duration"
							name="duration"
							label="Estimated Duration (Days)"
							type="number"
							value={formData.duration}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomSelect
							name="productType"
							label="Proposed Funding Product"
							options={[
								{ value: "buy-now-pay-later", label: "Buy Now Pay Later" },
								{ value: "invoice-finance", label: "Invoice Finance" },
								{ value: "purchase-order-loan", label: "Purchase Order Loan" },
								{ value: "asset-finance", label: "Asset Finance" },
								{ value: "unspecified", label: "Unspecified" },
							]}
							value={formData.productType}
							onChange={handleSelectChange("productType")}
						/>

						<CustomSelect
							name="useOfFunds"
							label="Proposed Use of Funds (select all that apply)"
							options={[
								{ value: "Business Inputs", label: "Business Inputs" },
								{ value: "Consumables", label: "Consumables" },
							]}
							value={formData.useOfFunds}
							onChange={handleSelectChange("useOfFunds")}
							multiple
						/>

						<CustomSelect
							name="securityOrCollateral"
							label="Select security or collateral"
							options={[
								{
									value: "Contract -w/o Cession",
									label: "Contract - w/o Cession",
								},
								{
									value: "Asset(vehicle, machinery, etc)",
									label: "Asset (vehicle, machinery, etc)",
								},
								{ value: "Purchase Order", label: "Purchase Order" },
								{ value: "None", label: "None" },
							]}
							value={formData.securityOrCollateral}
							onChange={handleSelectChange("securityOrCollateral")}
						/>
					</Box>
				</BaseCard>

				<BaseCard sx={{ padding: "20px", height: "auto" }}>
					<Box component="form" mt={2}>
						<CustomCardHeader title="Additional Information" />

						<CustomTextInput
							key="productTypeAdditionalContext"
							name="productTypeAdditionalContext"
							label="Provide additional context, if available."
							multiline
							rows={3}
							value={formData.productTypeAdditionalContext}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomTextInput
							key="useOfFundsAdditionalContext"
							name="useOfFundsAdditionalContext"
							label="Additional Use of Funds Context"
							multiline
							rows={3}
							value={formData.useOfFundsAdditionalContext}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>

						<CustomTextInput
							key="securityAdditionalContext"
							name="securityAdditionalContext"
							label="Security or Collateral Additional Context"
							multiline
							rows={3}
							value={formData.securityAdditionalContext}
							onChange={handleInputChange}
							sx={{ mb: 2 }}
						/>
					</Box>
				</BaseCard>
			</ResponsiveGrid>

			<Box mt={2} display="flex" justifyContent="center" alignItems="center">
				<CustomButton
					text="Back"
					onClick={() => handlePrev()}
					variant="outlined"
					color="secondary"
					sx={{ mr: 2 }}
				/>

				{isLoading ? (
					<CircularProgress size="1rem" color="success" />
				) : (
					<CustomButton
						text="Proceed"
						onClick={handleSubmit}
						variant="contained"
						color="success"
					/>
				)}
			</Box>
		</>
	);
};

export default FundingNeed;
