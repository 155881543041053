import React, { ReactNode, useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import CustomButton from '../Elements/CustomButton';
import ViewModelDetailsModal from './ViewModelDetailsModal';

interface ModelSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onOnboard: (model: string) => void;
}

const ModelSelectionModal: React.FC<ModelSelectionModalProps> = ({
  open,
  onClose,
  onOnboard,
}) => {
  const theme = useTheme();
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [viewModelOpen, setViewModelOpen] = useState(false);

  const handleModelChange = (event: any) => {
    const selectedValue = event.target.value as string;
    if (selectedValue === 'viewDetails') {
      setViewModelOpen(true);
    } else {
      setSelectedModel(selectedValue);
    }
  };

  const handleOnboard = () => {
    onOnboard(selectedModel);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
         sx={{
            width: '600px',
            
            backgroundColor: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
            padding: '24px',
            borderRadius: '8px 8px',
            border: 0,
            margin: 'auto',
            overflowY: 'auto',
            top: '50%',
            bottom: '50%'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Review Model
        </Typography>
        
        <Box
          sx={{
            p: 2,
            mb: 0,
            backgroundColor: 'rgba(255, 165, 0, 0.2)',
            border: '1px solid orange',
            borderRadius: '4px',
          }}
        >
          <Typography variant="body2" color="orange">
            The ability to create models and rules is only available on the enterprise package.
            Please contact support for more information.
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={2}>
          <Tooltip title="Monitoring transactions involves reviewing financial activities to ensure compliance and to identify potential fraud." placement="top">
            <InfoIcon sx={{ color: 'rgba(61, 127, 142, 1)'}} />
          </Tooltip>
          <Typography variant="body1" sx={{ ml: 1 }}>
            Select a model:
          </Typography>
        </Box>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <Select
            value={selectedModel}
            onChange={(e) => handleModelChange(e)}
            
          >
            <MenuItem value="Model A">Model A - v1.0</MenuItem>
            <MenuItem value="viewDetails" sx={{ fontWeight: 'bold' }}>
              View Model Details
            </MenuItem>
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
          <CustomButton
            text="Cancel"
            onClick={onClose}
            variant="outlined"
            color="secondary"
          />
          <CustomButton
            text="Onboard"
            onClick={handleOnboard}
            variant="contained"
            color="success"
            disabled={!selectedModel || selectedModel === 'viewDetails'}
          />
        </Box>

        <ViewModelDetailsModal
          open={viewModelOpen}
          handleClose={() => setViewModelOpen(false)}
        />
      </Box>
    </Modal>
  );
};

export default ModelSelectionModal;