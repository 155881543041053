
import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppRoutes from '../../../routes/routes';
import AuthenticationRoutes from '../../../routes/auth';

const DemoPageContent = () => {

  const theme = useTheme();

  return (
    <Box
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'center',
        background: theme.palette.mode === 'dark' ? theme.palette.background.default : "#e3e3e3",
      }}
    >
      <AppRoutes />
      
    </Box>
  );
};

export default DemoPageContent;
