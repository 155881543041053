import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables); 

interface DoughnutChartWrapperProps {
    data: number[];
    colors: string[];
}

const DoughnutChartWrapper: React.FC<DoughnutChartWrapperProps> = ({ data, colors }) => {
    const chartData = {
        labels: [], 
        datasets: [
            {
                data: data,
                backgroundColor: colors,
                hoverBackgroundColor: colors.map(color => `${color}80`),
            },
        ],
    };

    const options = {
        cutout: '60%',
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem: { raw: any; chart: { data: { datasets: { data: any[]; }[]; }; }; label: any; }) => {
                        const value = tooltipItem.raw;
                        const total = tooltipItem.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = ((value / total) * 100).toFixed(1) + '%';
                        return `${percentage}`; 
                    },
                },
            },
            datalabels: {
                color: '#fff',
                font: {
                    size: 8,
                },
                formatter: (value: number) => {
                    return value.toString() + "%";
                },
            },
        },
    };

    return (
        <Doughnut 
            data={chartData} 
            options={options} 
            plugins={[ChartDataLabels as any]} 
            width={130} 
            height={130} 
        />
    );
};

export default DoughnutChartWrapper;
