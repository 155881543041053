import React from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import ResponsiveGrid from '../../common/components/Grids/ResponsiveGrid';
import { useComponentRegistryContext } from '../../contexts/componentRegistryContext'; 

const InsightsPage: React.FC = () => {
    
    const { components } = useComponentRegistryContext();
    
    return (
        <Box
            sx={{
                py: 4,
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                gap: 2,
            }}
        >
            <GreetingMessage title='Hi Ben!' subtitle='Pin the insights you want to see on your dashboard' />

            <ResponsiveGrid columnCount={2}>
                {components
                    .filter(component => !component.isFullWidth)
                    .map((component, index) => (
                        <Box width="100%" key={index}>
                            {component.component}
                        </Box>
                    ))}
            </ResponsiveGrid>

            <ResponsiveGrid columnCount={1}>
                {components
                    .filter(component => component.isFullWidth)
                    .map((component, index) => (
                        <Box width="100%" key={index}>
                            {component.component}
                        </Box>
                    ))}
            </ResponsiveGrid>

        </Box>
    );
};

export default InsightsPage;
