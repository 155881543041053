import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	TextField,
	Grid,
	useTheme,
} from "@mui/material";
import {
	AddCircleOutline,
	Close,
	Delete,
	Remove as RemoveIcon,
} from "@mui/icons-material";
import CustomButton from "../Elements/CustomButton";
import toast from "react-hot-toast";

interface ViewModelDetailsModalProps {
	open: boolean;
	handleClose: () => void;
}

const ViewModelDetailsModal: React.FC<ViewModelDetailsModalProps> = ({
	open,
	handleClose,
}) => {
	const theme = useTheme();
	const [rules, setRules] = useState([
		{ field: "Transaction amount", operation: "Greater than", value: "50000" },
		{ field: "Description", operation: "Contains", value: "lotto" },
		{ field: "Merchant name", operation: "Equals", value: "BMW" },
	]);

	const handleRemoveRule = (index: number) => {
		const newRules = [...rules];
		newRules.splice(index, 1);
		setRules(newRules);
	};

	const canAddCondition =
		rules.length === 0 ||
		(rules[rules.length - 1].field &&
			rules[rules.length - 1].operation &&
			rules[rules.length - 1].value);

	const handleAddRule = () => {
		const lastRule = rules[rules.length - 1];
		if (lastRule.field && lastRule.operation && lastRule.value) {
			setRules([...rules, { field: "", operation: "", value: "" }]);
		}
	};

	const modelDetails = {
		modelName: "Model A - v1.0",
		category: "Financial Analysis",
		description:
			'This model is used for reviewing transactions for compliance and risk management. The model includes rules for identifying high-risk transactions. This is done by checking for transactions with amounts greater than R50,000, descriptions containing the word "lotto", and merchant names equal to "BMW". The list of rules and thresholds can be customized based on an organization\'s requirements.',
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					width: "100vw",
					maxHeight: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					overflowY: "auto",
					top: "0%",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
					<Typography variant="h6">Model Details</Typography>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Box>

				<Box
					sx={{
						p: 2,
						mb: 2,
						backgroundColor: "rgba(255, 165, 0, 0.2)",
						border: "1px solid orange",
						borderRadius: "4px",
					}}
				>
					<Typography variant="body2" color="orange">
						The ability to create models and rules is only available on the
						enterprise package. Please contact support for more information.
					</Typography>
				</Box>

				<TextField
					fullWidth
					label="Model Name"
					margin="normal"
					value={modelDetails.modelName}
					disabled
				/>
				<TextField
					fullWidth
					label="Category"
					margin="normal"
					value={modelDetails.category}
					disabled
				/>
				<TextField
					fullWidth
					label="Description"
					margin="normal"
					multiline
					rows={2}
					value={modelDetails.description}
					disabled
				/>

				{rules.map((rule, index) => (
					<>
						<Box
							mt={0}
							mb={0}
							width="100%"
							display="flex"
							justifyContent="space-around"
							alignItems="center"
						>
							<Typography variant="body1" fontWeight="600" width="100%" py={1}>
								Rule {index + 1}
							</Typography>

							{rules.length > 1 && (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => handleRemoveRule(index)}
								>
									<Delete sx={{ fontSize: "18px" }} color="success" />
									<Typography
										sx={{
											marginLeft: "8px",
											color: "rgba(61, 127, 142, 1)",
											fontSize: "14px",
											lineHeight: "19px",
											letterSpacing: "-0.15%",
											fontWeight: 600,
										}}
									>
										Remove
									</Typography>
								</Box>
							)}
						</Box>

						<Grid
							container
							spacing={2}
							alignItems="center"
							key={index}
							sx={{ mb: 0 }}
						>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Field"
									value={rule.field}
									onChange={(e) => {
										const newRules = [...rules];
										newRules[index].field = e.target.value;
										setRules(newRules);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Operation"
									value={rule.operation}
									onChange={(e) => {
										const newRules = [...rules];
										newRules[index].operation = e.target.value;
										setRules(newRules);
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Value"
									value={rule.value}
									onChange={(e) => {
										const newRules = [...rules];
										newRules[index].value = e.target.value;
										setRules(newRules);
									}}
								/>
							</Grid>
						</Grid>
					</>
				))}

				{canAddCondition && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							cursor: "pointer",
							marginBottom: "24px",
							my: 1,
						}}
						onClick={handleAddRule}
					>
						<AddCircleOutline color="success" />
						<Typography
							sx={{
								marginLeft: "8px",
								color: "rgba(61, 127, 142, 1)",
								fontSize: "14px",
								lineHeight: "19px",
								letterSpacing: "-0.15%",
								fontWeight: 600,
							}}
						>
							Add a rule
						</Typography>
					</Box>
				)}

				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
					<CustomButton
						text="Add Rule"
						onClick={() => toast.success("Rules added successfully")}
						variant="contained"
						color="success"
					/>
					<CustomButton
						text="Close"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
						sx={{ ml: 2 }}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default ViewModelDetailsModal;
