import React, { useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomTabs from "../Elements/CustomTabs";
import CustomTable from "../Tables/CustomTable";
import { useFetchClientDeals } from "../../../common/helpers/hooks/useClientService";
import LoadingIconButton from "../Elements/LoadingIconButton";

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
	showDeal?: boolean;
}

const ProfileDetailsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedApplication,
	showDeal = true,
}) => {
	const theme = useTheme();
	const [tabIndex, setTabIndex] = useState(0);

	const {
		data: deals,
		isLoading,
		refetch,
		isFetching,
	} = useFetchClientDeals(selectedApplication?.userId);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const entrepreneurFields = [
		{ label: "First Name", value: selectedApplication?.userProfile?.firstName },
		{ label: "Surname", value: selectedApplication?.userProfile?.surname },
		{ label: "Email", value: selectedApplication?.userProfile?.email },
		{ label: "Mobile No", value: selectedApplication?.userProfile?.mobileNo },
		{ label: "Gender", value: selectedApplication?.userProfile?.gender },
		{ label: "ID Number", value: selectedApplication?.userProfile?.idNumber },
		{ label: "Age", value: selectedApplication?.userProfile?.age },
		{
			label: "Date of Birth",
			value: selectedApplication?.userProfile?.dateOfBirth,
		},
		{
			label: "Residential Address",
			value: selectedApplication?.userProfile?.residentialAddress,
		},
	];

	const businessFields = [
		{
			label: "Business Name",
			value: selectedApplication?.businessProfile?.businessName,
		},
		{
			label: "Company Reg No",
			value: selectedApplication?.businessProfile?.companyRegNo,
		},
		{
			label: "Financial Year End",
			value: selectedApplication?.businessProfile?.financialYearEnd,
		},
		{
			label: "Commercial Status",
			value: selectedApplication?.businessProfile?.commercialStatus,
		},
		{
			label: "Physical Address",
			value: selectedApplication?.businessProfile?.physicalAddress,
		},
		{
			label: "Director Count",
			value: selectedApplication?.businessProfile?.directorCount,
		},
		{
			label: "Legal Entity Type",
			value: selectedApplication?.businessProfile?.legalEntityType,
		},
		{ label: "Tax No", value: selectedApplication?.businessProfile?.taxNo }
	];

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">Client Details</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ ml: "auto" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<CustomTabs
					tabs={[
						...(showDeal ? [{ label: "Deals" }] : []),
						{ label: "Entrepreneur Profile" },
						{ label: "Business Profile" },
					]}
					selectedTab={tabIndex}
					onTabChange={handleTabChange}
				/>

				<br />
				{tabIndex === 0 && showDeal && (
					<>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<LoadingIconButton onClick={refetch} isLoading={isFetching} />
						</Box>
						<Box p={2}>
							<CustomTable
								isLoading={isLoading}
								columns={[
									{ title: "Email", key: "email", sortable: true },
									{ title: "Amount", key: "fundingAmount", sortable: true },
									{ title: "Duration", key: "duration", sortable: true },
									{
										title: "Status",
										key: "status",
										sortable: true,
										isPill: true,
									},
									{
										title: "Offer Status",
										key: "offerStatus",
										sortable: true,
										isPill: true,
									},
									{ title: "Use of funds", key: "useOfFunds", sortable: true },
									{
										title: "Sector",
										key: "sector",
										sortable: true,
									}
								]}
								data={deals?.data ? deals?.data : []}
							/>
						</Box>
					</>
				)}

				{((tabIndex === 1 && showDeal) || (!showDeal && tabIndex == 0)) && (
					<Box p={2}>
						<Typography variant="h6">Entrepreneur Profile</Typography>
						<form>
							<Grid container spacing={2}>
								{entrepreneurFields.map((field, index) => (
									<Grid item xs={12} md={4} key={index}>
										<CustomTextInput label={field.label} value={field.value} />
									</Grid>
								))}
							</Grid>
						</form>
					</Box>
				)}

				{((tabIndex === 2 && showDeal) ||
					(!showDeal && tabIndex == 1)) && (
						<Box p={2}>
							<Typography variant="h6">Business Profile</Typography>
							<form>
								<Grid container spacing={2}>
									{businessFields.map((field, index) => (
										<Grid item xs={12} md={4} key={index}>
											<CustomTextInput label={field.label} value={field.value} />
										</Grid>
									))}
								</Grid>
							</form>
						</Box>
					)}

				{tabIndex != 0 && (
					<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
						<CustomButton
							text="Close"
							onClick={onClose}
							variant="outlined"
							color="secondary"
						/>
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default ProfileDetailsModal;
