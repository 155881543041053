import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import PillLabel from '../Elements/PillLabel';

interface FinanceCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    highest: number;
    lowest: number;
    average: number;
    clientWithHighestValue: string;
    clientWithLowestValue: string;
    percentageIncrease: number;
}

const RatingCard: React.FC<FinanceCardProps> = ({
    title,
    titleIcon,
    highest,
    lowest,
    average,
    clientWithHighestValue,
    clientWithLowestValue,
    percentageIncrease
}) => {
    const theme = useTheme();

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2} mt={-4}>
                <Box display="flex" alignItems="center" flexGrow={1}>
                    <Box flexShrink={0}>
                        <Typography 
                            textAlign="left" 
                            color={theme.palette.text.primary} 
                            fontWeight="400" 
                            fontSize="16px" 
                            lineHeight="20px" 
                            letterSpacing="0.25px">
                            Average
                        </Typography>
                        <Typography 
                            mt={1} 
                            textAlign="left" 
                            fontSize="36px" 
                            lineHeight="32px" 
                            color={theme.palette.text.primary} 
                            fontWeight="700">
                            {average.toLocaleString()}
                        </Typography>

                        <Box mt={2}>
                            <PillLabel 
                                fontSize='14px' 
                                bgcolor="white" 
                                besideText="Over the last 30 days" 
                                innerText={`+${percentageIncrease}%`} 
                            />
                        </Box>
                    </Box>

                    <Box ml={2} pl={2} borderLeft={`2px solid ${theme.palette.divider}`} display="flex" flexDirection="column" flexGrow={1} gap={1}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Box display="flex" flexDirection="column">
                                <Typography 
                                    textAlign="left" 
                                    color={theme.palette.text.primary} 
                                    fontWeight="600" 
                                    fontSize="20px" 
                                    lineHeight="32px">
                                    Highest
                                </Typography>
                                <Typography textAlign="left">
                                    <span style={{ color: '#14AE5C', fontWeight: '700', fontSize: '16px', lineHeight: '20px', letterSpacing:'0.25px'}}>
                                        {highest.toLocaleString()}
                                    </span>
                                    {`  `}
                                    <span style={{ color: theme.palette.text.secondary, fontWeight: '400', fontSize: '16px', lineHeight: '20px', letterSpacing:'0.25px'}}>
                                        {clientWithHighestValue}
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center" gap={2}>
                            <Box>
                                <Typography 
                                    textAlign="left" 
                                    color={theme.palette.text.primary} 
                                    fontWeight="600" 
                                    fontSize="20px" 
                                    lineHeight="32px">
                                    Lowest
                                </Typography>
                                <Typography textAlign="left">
                                    <span style={{ color: '#EC221F', fontWeight: '700', fontSize: '16px', lineHeight: '20px', letterSpacing:'0.25px'}}>
                                        {lowest.toLocaleString()}
                                    </span>
                                    {`  `}
                                    <span style={{ color: theme.palette.text.secondary, fontWeight: '400', fontSize: '16px', lineHeight: '20px', letterSpacing:'0.25px'}}>
                                        {clientWithLowestValue}
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default RatingCard;
