import authService from "../../../common/services/auth-service";
import { useFormik } from "formik";
import * as Yup from "yup";

const useResetPasswordForm = (userId: string, token: string) => {
  return useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      submit: null,
    },
    validationSchema: Yup.object({
      password: Yup.string().max(255).required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await authService.resetPassword(userId, token, values.password);
        helpers.setStatus({ success: true });
      } catch (err) {
        console.error(err);

        if (err instanceof Error) {
          const errorMessage = (err as any)?.response?.data?.error || "Failed to reset password";
          helpers.setErrors({ submit: errorMessage });
        } else {
          helpers.setErrors({ submit: "An unknown error occurred" });
        }

        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });
};

export default useResetPasswordForm;
