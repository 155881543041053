export function parseBoolean(value: any) {
    if (typeof value === 'boolean') {
        return value;
    }

    if (typeof value === 'string') {
        const lowerValue = value.toLowerCase();
        if (lowerValue === 'yes' || lowerValue === 'true') {
            return true;
        } else if (lowerValue === 'no' || lowerValue === 'false') {
            return false;
        }
    }

    
    return value;
}
