import React, { useState } from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import BaseCard from '../../common/components/Cards/BaseCard';
import { clientsDummyData } from '../../data/clients';
import { PushPin, Info, Visibility } from '@mui/icons-material';
import CustomTable from '../../common/components/Tables/CustomTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import TabsWithSearch from '../../common/components/Widgets/TabsWithSearch';
import { useTheme } from '@mui/material';

const ClientsPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const tabs = [
    { label: "Active (2)" },
    { label: "Onboarding (2)" },
    { label: "Archive (1)" },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = clientsDummyData.filter(client =>
    client.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.contact.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePin = (row:any) => {
    
  }

  const handleView = (row: any) => {
    navigate(`/clients/${row.id}`);
  };


  return (
    <Box
      sx={{
        py: 4,
        px: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        gap: 2,
      }}
    >
      <GreetingMessage title="My clients" />
      <BaseCard sx={{ height: 'auto' }}>
        <Box px={2} py={4}>
        <TabsWithSearch
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
          <CustomTable
              icons={(client) => [
              {
                icon: (
                  <Tooltip title="Pin Client" arrow>
                    <PushPin sx={{fill: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white', fontSize: '16px' }} />
                  </Tooltip>
                ),
                onClick: () => handlePin(client), 
              },
              {
                icon: (
                  <Tooltip title="View Details" arrow>
                    <Visibility sx={{ fill: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white', fontSize: '16px' }} />
                  </Tooltip>
                ),
                onClick: () => handleView(client),
              },
            ]}
            
            columns={[
              { title: 'Company', key: 'company', sortable: true },
              { title: 'Contact', key: 'contact', sortable: true },
              { title: 'Active Since', key: 'activeSince', sortable: true },
              { title: 'Borrowed', key: 'borrowed', sortable: true },
              { title: 'Remaining Term', key: 'remainingTerm', sortable: true },
              { title: 'Tasks', key: 'tasks', sortable: true },
              { title: 'Risk Status', key: 'riskStatus', sortable: true },
              { title: 'Flags', key: 'flags', sortable: true },
            ]}
            data={filteredData}
            actionColumn={{
              title: 'Actions',
            }}
          />
        </Box>
      </BaseCard>
    </Box>
  );
};

export default ClientsPage;
