import React, { FC, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BaseCard from "../../common/components/Cards/BaseCard";
import ForgotPasswordForm from "../../common/components/Forms/ForgotPasswordForm";

export const ForgotPasswordPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordResetDialog, setShowPasswordResetDialog] =
    useState<boolean>(false);

  return (
    <Container
      maxWidth="sm"
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <BaseCard sx={{ height: "auto", width: "100%", padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <img
                src="/static/pumpkn-logo2.png"
                alt="Pumpkn logo"
                width={80}
                height={80}
              />
              <Typography
                lineHeight="24.75px"
                fontSize="18px"
                fontWeight="700"
                color="#111827"
                sx={{ mt: 2 }}
                variant="body1"
              >
                Forgot Password
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body1">
                Enter your email to reset your password
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ForgotPasswordForm
              isLoading={isLoading}
              showPasswordResetDialog={showPasswordResetDialog}
            />
          </Grid>
          <Grid className="grid__item" item xs={12}>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column" alignItems="center">
               
                <RouterLink to="/authentication/login" style={{ textDecoration: 'none' }}>
                  <Typography color="textSecondary" variant="body2">
                    Remember your password? Login
                  </Typography>
                </RouterLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BaseCard>
    </Container>
  );
};

export default ForgotPasswordPage;
