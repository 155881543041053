import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Modal, Stepper, Step, StepLabel } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import GreetingMessage from "../Headings/GreetingMessage";
import FundingNeed from "../../../common/components/Forms/FundingNeed";

import { useTheme } from "@mui/material";
import CompleteProfile from "../Forms/CompleteProfile";
import DealChecklist from "../Forms/Dealchecklist";
import DealSummary from "../Forms/DealSummary";
import CustomStepper from "../Elements/CustomStepper";

interface ReviewDealModalProps {
	open: boolean;
	onClose: () => void;
	deal: any;
}

const steps = [
	"Deal Details",
	"Deal Checklist",
	"Profile Details",
	"Deal Summary",
];

const ReviewDealModal: React.FC<ReviewDealModalProps> = ({
	open,
	onClose,
	deal,
}) => {
	const theme = useTheme();
	const [selectedStep, setSelectedStep] = useState<number>(0);

	const navigate = useNavigate();

	const handleBack = () => {
		navigate("/dashboard");
	};

	const handleStepChange = (step: number) => {
		setSelectedStep(step);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					py: 2,
					px: 3,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
					width: "100%",
					height: "100%",
					backgroundColor: theme.palette.background.paper,
					gap: 2,
					overflow: "auto",
				}}
			>
				<Box
					sx={{ cursor: "pointer", width: "100%" }}
					onClick={onClose}
					display="flex"
					gap={1}
					alignItems="center"
					justifyContent="flex-start"
				>
					<ArrowBack sx={{ fill: "rgba(73, 69, 79, 1)" }} />
					<GreetingMessage title="Back" />
				</Box>
				<Box width="70%">
					<CustomStepper
						steps={steps}
						activeStep={selectedStep}
						setActiveStep={setSelectedStep}
					/>
				</Box>

				<Box sx={{ width: "100%", mt: 2 }}>
					{selectedStep === 0 && (
						<FundingNeed
							selectedApplication={deal}
							handleNext={() => handleStepChange(2)}
							handlePrev={() => handleStepChange(0)}
						/>
					)}
					{selectedStep === 1 && (
						<DealChecklist
							selectedApplication={deal}
							handleNext={() => handleStepChange(3)}
							handlePrev={() => handleStepChange(1)}
						/>
					)}
					{selectedStep === 2 && (
						<CompleteProfile
							selectedApplication={deal}
							handleNext={() => handleStepChange(1)}
							handlePrev={() => handleStepChange(0)}
						/>
					)}

					{selectedStep === 3 && (
						<DealSummary
							selectedApplication={deal}
							handlePrev={() => handleStepChange(2)}
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ReviewDealModal;
