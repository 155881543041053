import React from 'react';
import { Typography } from '@mui/material';

interface TextLabelProps {
    text: string;
    color: string;
}

const TextLabel: React.FC<TextLabelProps> = ({ text, color }) => {
    return (
        <Typography
            sx={{
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '19.07px',
                textAlign: 'left',
                marginTop: '8px',
                color: {color}
            }}
        >
            {text}
        </Typography>
    );
};

export default TextLabel;
