export interface FlagData {
    id: number;
    flag: boolean;
    category: string;
    flagDetails: string;
    transactionFlow: 'In' | 'Out' | 'Both';
    conditions: number;
    timesTriggered: number;
}

export const flagsDummyData: FlagData[] = [
    {
        id: 1,
        flag: true,
        category: "Non-business car purchases",
        flagDetails: "Covers major luxury and personal-use car brands",
        transactionFlow: 'In',
        conditions: 5,
        timesTriggered: 5,
    },
    {
        id: 2,
        flag: true,
        category: "AML compliance",
        flagDetails: "Lottery/Gambling",
        transactionFlow: 'Out',
        conditions: 2,
        timesTriggered: 0,
    },
    {
        id: 3,
        flag: true,
        category: "Business banking fraud",
        flagDetails: "Personal account transfer + Transaction over R 20,000",
        transactionFlow: 'In',
        conditions: 1,
        timesTriggered: 1,
    },
    {
        id: 4,
        flag: false,
        category: "Retain (individual) banking fraud",
        flagDetails: "Large purchases",
        transactionFlow: 'Both',
        conditions: 3,
        timesTriggered: 2,
    },
    {
        id: 5,
        flag: true,
        category: "Cross-border fraud",
        flagDetails: "Out of country expense",
        transactionFlow: 'Out',
        conditions: 1,
        timesTriggered: 22,
    },
];
