import React, { useState } from 'react';
import Box from '@mui/material/Box';
import GreetingMessage from '../../common/components/Headings/GreetingMessage';
import BaseCard from '../../common/components/Cards/BaseCard';
import { clientsDummyData } from '../../data/clients';
import { Flag, PushPin, Visibility } from '@mui/icons-material';
import CustomTable from '../../common/components/Tables/CustomTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import transactionsDummyData from '../../data/transactions';
import { Grid, Tooltip, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import IconWithText from '../../common/components/Elements/IconWithText';
import { useNavigate } from 'react-router-dom';
import TabsWithSearch from '../../common/components/Widgets/TabsWithSearch';

const FlaggingPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const tabs = [
    { label: "All (2)" },
    { label: "My flags (2)" },
    { label: "Suspicious activity (3)" },
    { label: "Cleared flags (2)" },
  ];
  const navigate = useNavigate();
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  let filteredData = transactionsDummyData.filter((x:any) =>
    x.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
    x.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  
  const handleView = (row: any) => {
    navigate(`/flagging/${row.id}`);
  };

  const handleManageFlags = (type:string) => { navigate(`/flagging/manage?type=${type}`)}

  const handleToggleFlag = (row:any) => {
    const newFlag = row?.flag?.toLowerCase() == 'low' ? 'High' : 'Low';
    
    filteredData.forEach((data:any) => {
      if(data.id == row.id) data.flag = newFlag;
    } )

  }

  return (
    <Box
      sx={{
        py: 4,
        px: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        gap: 2,
      }}
    >
      <GreetingMessage title="Transaction flagging" />
      <Grid container spacing={1} justifyContent="flex-start" width="100%">
      <Grid sx={{ cursor: 'pointer'}} item xs={12} lg={3.5} onClick = {() => handleManageFlags("flags")}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
                <IconWithText
                    icon={<Flag style={{ color: 'red', marginRight: '5px', fontSize: '34px' }} />}
                    besideText="Manage your flags"
                />

              </BaseCard>
            </Grid>
            <Grid sx={{ cursor: 'pointer'}} item xs={12} lg={3.5} onClick = {() => handleManageFlags("suspicious_transactions")}>
              <BaseCard sx={{ height: 'auto', padding: '10px' }}>
                <IconWithText  icon={<ErrorIcon style={{ color: '#C7C000', marginRight: '5px', fontSize: '34px' }} />} besideText='Manage suspicious transactions' />
              </BaseCard>
            </Grid>
    </Grid>
      <BaseCard sx={{ height: 'auto' }}>
        <Box px={2} py={4}>
        <GreetingMessage title="Flagged transactions" />
          <TabsWithSearch 
             tabs={tabs} 
             selectedTab={selectedTab} 
             onTabChange={handleTabChange} 
             searchTerm={searchTerm} 
             onSearchChange={handleSearchChange} 
         />
          
          <CustomTable
            icons={(client) => [
                
                {
                  icon: (
                    <Tooltip title="View Details" arrow>
                      <Visibility sx={{ fill: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white', fontSize: '20px' }} />
                    </Tooltip>
                  ),
                  onClick: () => handleView(client),
                },
              ]}

            columns={[
                { title: 'Company', key: 'company', sortable: true },
                { title: 'Date', key: 'date', sortable: true },
                { title: 'Description', key: 'description', sortable: true },
                { title: 'Amount', key: 'amount', sortable: true },
                { title: 'Flag', key: 'flag', sortable: true, handler: (row) => handleToggleFlag(row)  },
                { title: 'Flag Details', key: 'flagDetails', sortable: true },
            ]}
            data={filteredData}
            actionColumn={{
                title: 'Action',
              }}
          />
        </Box>
      </BaseCard>
    </Box>
  );
};

export default FlaggingPage;
