import React, { useState } from 'react';
import { Modal, Box, Typography, useTheme } from '@mui/material';
import { useDropzone } from "react-dropzone";
import CustomButton from '../Elements/CustomButton';
import { Download } from '@mui/icons-material';

interface TransactionCSVUploadModalProps {
    open: boolean;
    onClose: () => void;
    currentBusinessName: string | null;
    handleTransactionsCSVUpload: (file: File) => void; 
}

const UploadTransactionModal: React.FC<TransactionCSVUploadModalProps> = ({ open, onClose, currentBusinessName, handleTransactionsCSVUpload }) => {
    const theme = useTheme();
    const [csvData, setCsvData] = useState<any[]>([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'text/csv': ['.csv'] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target?.result;
                if (typeof text === 'string') {
                    const data: any[] = text.split("\n").map((row: string) => {
                        const columns = row.split(",").map(field => field.trim().replace(/\r/g, ""));
                        if (columns.length >= 12) {
                            return {
                                Email: columns[0],
                                IdNumber: columns[1],
                                CipcRegNo: columns[2],
                                EntrepreneurName: columns[3],
                                BusinessName: columns[4],
                                LoanAmount: parseFloat(columns[5]),
                                Duration: parseInt(columns[6], 10),
                                UseOfFunds: columns[7],
                                Sector: columns[8],
                                Location: columns[9],
                                RequestBankAccountLinkage: columns[10],
                                InDefault: columns[11],
                            };
                        }
                        console.warn(`Skipping malformed row: ${row}`);
                        return null;
                    }).filter(item => item !== null);
                    setCsvData(data);
                    handleTransactionsCSVUpload(file);
                }
            };
            reader.readAsText(file);
        },
    });

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: '600px',
                    height: 'auto',
                    backgroundColor: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
                    padding: '24px',
                    borderRadius: '8px',
                    border: 0,
                    margin: 'auto',
                    
                    top: '30%',
                    position: 'relative',
                }}
            >
                <Typography
                    mb={3}
                    sx={{
                        fontSize: '24px',
                        fontWeight: '500',
                        lineHeight: '32px',
                        fontFamily: 'Roboto',
                        color: theme.palette.text.primary,
                    }}
                >
                    Upload transactions for {currentBusinessName}
                </Typography>

                {/* File Upload Section */}
                <Box sx={{ padding: '20px', textAlign: 'center' }}>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #cccccc',
                            padding: '15px',
                            textAlign: 'center',
                            margin: '0 auto',
                            maxWidth: '80%',
                            marginBottom: '30px'
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body1">Drag 'n' drop a CSV file here, or click to select one</Typography>
                    </Box>

                   
                    <CustomButton
                        variant="outlined"
                        color="secondary"
                        startIcon={<Download />}
                        href="/sme_sample_transactions.csv"
                        text="Download Template"
                        download={true}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                    <CustomButton
                        text="Close"
                        onClick={onClose}
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default UploadTransactionModal;
