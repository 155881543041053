import React, { useEffect, useState } from 'react';
import { CardHeader, Typography } from '@mui/material';
import { PushPin } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getComponentIds, saveComponentId, removeComponentId } from '../../services/LocalStorageService';

const CustomCardHeaderWithIcon = ({ title, icon }) => {
    const theme = useTheme();
    const [iconColor, setIconColor] = useState(theme.palette.text.secondary);

    const handlePinComponentHandler = () => {
        const componentId = title
            ?.split(" ")
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join("");

        if (getComponentIds().includes(componentId)) {
            removeComponentId(componentId);
            setIconColor(theme.palette.text.secondary);
        } else {
            saveComponentId(componentId);
            setIconColor(theme.palette.text.primary);
        }
    };

    useEffect(() => {
        const componentId = title
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join("");

        if (getComponentIds().includes(componentId)) {
            setIconColor(theme.palette.text.primary);
        }
    }, [title, theme]);

    const renderedIcon = icon && React.isValidElement(icon) ? (
        React.cloneElement(icon, { 
            sx: { fill: iconColor, cursor: 'pointer' },
            onClick: handlePinComponentHandler 
        }) 
    ) : (
        <PushPin sx={{ fill: iconColor, cursor: 'pointer' }} onClick={handlePinComponentHandler} />
    );

    return (
        <CardHeader
            title={
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        letterSpacing: '-0.0026em',
                        textAlign: 'left',
                        color: theme.palette.text.primary, 
                        height: "56px"
                    }}
                >
                    {title}
                </Typography>
            }
            action={renderedIcon}
            sx={{ paddingBottom: '0' }}
        />
    );
};

export default CustomCardHeaderWithIcon;
