
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import MoneyOutlined from '@mui/icons-material/MoneyOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TopicIcon from '@mui/icons-material/Topic';
import { Edit, ListAltOutlined, Paid, People, Report } from '@mui/icons-material';
import { List } from '@mui/material';

export const NAVIGATION = [
  {
    kind: 'header',
    title: 'Loan Application Processing',
  },
  {
    kind: 'divider',
  },
  {
    segment: 'review',
    title: 'Review',
    icon: <TroubleshootIcon />,
    children: [
      {
        segment: 'manage-clients',
        title: 'Clients',
        icon: <People />,
        path: '/manage-clients',
      },
      {
        segment: 'manage-deals',
        title: 'Deals',
        icon: <Edit />,
        path: '/manage-deals',
      },
      {
        segment: 'manage-offers',
        title: 'Offers',
        icon: <Paid />,
        path: '/manage-offers',
      },
      {
        segment: 'manage-reports',
        title: 'Credit Reports',
        icon: <TopicIcon />,
        path: '/manage-reports',
      }, 
      {
        segment: 'financial-data',
        title: 'Financial Data',
        icon: <MoneyOutlined />,
        path: '/financial-data',
      },
      {
        segment: 'manage-collections',
        title: 'Collections',
        icon: <ListAltOutlined />,
        path: '/manage-collections',
      }
    ]
  },
  {
    kind: 'header',
    title: 'Post Investment Monitoring',
  },
  {
    kind: 'divider',
  },
  {
    segment: 'monitor',
    title: 'Under Construction',
    icon: <WarningAmberIcon />,
    // children: [
    //   {
    //     segment: 'dashboard',
    //     title: 'Overview',
    //     icon: <DashboardIcon />,
    //     path: '/dashboard',

    //   },
    //   {
    //     segment: 'clients',
    //     title: 'Clients',
    //     icon: <DescriptionIcon />,
    //     path: '/clients',
    //   },
    //   {
    //     segment: 'flagging',
    //     title: 'Flagging',
    //     icon: <LayersIcon />,
    //     path: '/flagging',
    //   },
    //   {
    //     segment: 'insights',
    //     title: 'Insights',
    //     icon: <BarChartIcon />,
    //     path: '/insights',
    //   }
    // ]
  }
];
