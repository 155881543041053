import React, { useState } from "react";
import {
	Tooltip,
	Modal,
	Box,
	Typography,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	CircularProgress,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { usePrimaryCategories } from "../../../common/helpers/hooks/useLabellingCategory";
import CustomTable from "./CustomTable";
import CustomButton from "../Elements/CustomButton";
import {
	createPrimaryCategory,
	deletePrimaryCategory,
	updatePrimaryCategory,
} from "../../../common/services/retool-service";
import toast from "react-hot-toast";

const CategoryTable: React.FC = () => {
	const theme = useTheme();
	const {
		data: primaryCategoriesOnly = [],
		isLoading: primaryCategoryLoading,
		refetch,
		isRefetching,
	} = usePrimaryCategories();

	const [parentModalOpen, setParentModalOpen] = useState(false);
	const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [newParentCategory, setNewParentCategory] = useState("");
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const handleView = (row: any) => {
		setSelectedCategory(row.primaryCategory);
		setNewParentCategory(row.primaryCategory);
		setParentModalOpen(true);
	};

	const handleCreateNewCategory = () => {
		setNewParentCategory("");
		setNewCategoryModalOpen(true);
	};

	const handleCloseParentModal = () => {
		setParentModalOpen(false);
		setSelectedCategory(null);
	};

	const handleCloseNewCategoryModal = () => {
		setNewCategoryModalOpen(false);
	};

	const handleCloseDeleteDialog = () => {
		setDeleteDialogOpen(false);
		setCategoryToDelete(null);
	};

	const handleAddParentCategory = async () => {
		setLoading(true);
		try {
			if (selectedCategory) {
				await updatePrimaryCategory(selectedCategory, newParentCategory);
				toast.success("Category updated successfully!");
				refetch();
			}
			handleCloseParentModal();
		} catch (error) {
			toast.error("Error updating category!");
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteCategory = async () => {
		setLoading(true);
		try {
			if (categoryToDelete) {
				await deletePrimaryCategory(categoryToDelete);
				toast.success("Category deleted successfully!");
				refetch();
			}
			handleCloseDeleteDialog();
		} catch (error) {
			toast.error("Error deleting category!");
		} finally {
			setLoading(false);
		}
	};

	const handleCreateCategory = async () => {
		setLoading(true);
		try {
			await createPrimaryCategory({ primaryCategory: newParentCategory });
			toast.success("Category created successfully!");
			refetch();
			handleCloseNewCategoryModal();
		} catch (error) {
			toast.error("Error creating category!");
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "20px",
					padding: "10px",
				}}
			>
				<Box>{isRefetching && <CircularProgress color="success" />}</Box>
				<CustomButton
					text="Add New Category"
					variant="contained"
					onClick={handleCreateNewCategory}
				/>
			</Box>

			<CustomTable
				isLoading={primaryCategoryLoading}
				icons={(row) => [
					{
						icon: (
							<Tooltip title="View/Edit" arrow>
								<Edit
									sx={{
										fill:
											theme.palette.mode === "light"
												? "rgba(73, 69, 79, 1)"
												: "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => handleView(row),
					},
					{
						icon: (
							<Tooltip title="Delete" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode === "light" ? "red" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setCategoryToDelete(row.primaryCategory);
							setDeleteDialogOpen(true);
						},
					},
				]}
				columns={[
					{ title: "Primary Category", key: "primaryCategory", sortable: true },
					{
						title: "Secondary Category",
						key: "secondaryCategory",
						sortable: true,
					},
				]}
				data={primaryCategoriesOnly}
				actionColumn={{ title: "Actions" }}
			/>

			<Modal open={parentModalOpen} onClose={handleCloseParentModal}>
				<Box
					sx={{
						width: "400px",
						backgroundColor: theme.palette.background.paper,
						padding: "16px",
						borderRadius: "8px",
						margin: "auto",
						top: "30%",
						position: "relative",
						boxShadow: theme.shadows[5],
					}}
				>
					<Typography
						variant="h6"
						mb={3}
						sx={{ color: theme.palette.text.primary }}
					>
						Edit Primary Category
					</Typography>
					<TextField
						label="Primary Category"
						fullWidth
						value={newParentCategory}
						onChange={(e) => setNewParentCategory(e.target.value)}
						sx={{ marginBottom: "20px" }}
					/>
					<Box
						sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
					>
						<CustomButton
							text="Cancel"
							variant="outlined"
							color="secondary"
							onClick={handleCloseParentModal}
						/>
						<CustomButton
							text={
								loading ? <CircularProgress size={20} /> : "Update Category"
							}
							variant="contained"
							onClick={handleAddParentCategory}
							disabled={loading}
						/>
					</Box>
				</Box>
			</Modal>

			<Modal open={newCategoryModalOpen} onClose={handleCloseNewCategoryModal}>
				<Box
					sx={{
						width: "400px",
						backgroundColor: theme.palette.background.paper,
						padding: "16px",
						borderRadius: "8px",
						margin: "auto",
						top: "30%",
						position: "relative",
						boxShadow: theme.shadows[5],
					}}
				>
					<Typography
						variant="h6"
						mb={3}
						sx={{ color: theme.palette.text.primary }}
					>
						Create New Category
					</Typography>
					<TextField
						label="Primary Category"
						fullWidth
						value={newParentCategory}
						onChange={(e) => setNewParentCategory(e.target.value)}
						sx={{ marginBottom: "20px" }}
					/>
					<Box
						sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
					>
						<CustomButton
							text="Cancel"
							variant="outlined"
							color="secondary"
							onClick={handleCloseNewCategoryModal}
						/>

						<CustomButton
							text={
								loading ? <CircularProgress size={20} /> : "Create Category"
							}
							variant="contained"
							onClick={handleCreateCategory}
							disabled={loading}
						/>
					</Box>
				</Box>
			</Modal>

			<Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
				<DialogTitle>Delete Category</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete the category "{categoryToDelete}"?
						Note: This will delete the primary category and associated secondary
						categories with keywords.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CustomButton
						text="Cancel"
						variant="outlined"
						onClick={handleCloseDeleteDialog}
					/>
					{loading ? (
						<CircularProgress size={20} />
					) : (
						<CustomButton
							text="Delete"
							variant="contained"
							color="success"
							onClick={handleDeleteCategory}
							disabled={loading}
						/>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CategoryTable;
