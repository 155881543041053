export const colors = {
    pumpkn: "#619C9C",
    light: {
        text: {
            primary: '#000000', // Black for primary text
            secondary: '#1E1E1E', // Very Dark Gray for secondary text
            tertiary: '#A0A0A0', // Gray for tertiary text
            white: '#FFFFFF', // White for backgrounds and elements
        },
        background: {
            default: '#FBFBFB', // Very Light Gray for main background
            card: '#FFFFFF', // White for cards
            table: '#F5F5F5', // Light Gray for table backgrounds
        },
        border: {
            default: '#E0E0E0', // Light gray for borders
        },
    },
    dark: {
        text: {
            primary: '#E0E0E0', // Light Gray for primary text
            secondary: '#B0B0B0', // Medium Gray for secondary text
            tertiary: '#C0C0C0', // Slightly Lighter Gray for tertiary text
            white: '#E0E0E0', // Light Gray instead of pure white
        },
        background: {
            default: '#000000', // Black for main background
            card: '#1C1C1C', // Very Dark Gray for cards
            table: '#1A1A1A', // Dark background for tables
        },
        border: {
            default: '#4F4F4F', // Darker gray for borders
        },
    },
};
