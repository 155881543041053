import React from 'react';
import { Box, Grid } from '@mui/material';

interface ResponsiveGridProps {
    children: React.ReactNode;
    columnCount: number;
}

const ResponsiveGrid: React.FC<ResponsiveGridProps> = ({ children, columnCount }) => {
    const childrenArray = React.Children.toArray(children);
    const itemsPerRow = Math.min(columnCount, childrenArray.length);

    return (
        <Box width="100%">
            <Grid container spacing={2} justifyContent="center">
                {childrenArray.map((child, index) => (
                    <Grid 
                      item 
                      xs={12} 
                      lg={12 / itemsPerRow} 
                      key={index} 
                     
                    >
                        {child}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ResponsiveGrid;
