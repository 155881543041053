import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
	text: any;
	onClick?: () => void;
	variant?: "text" | "outlined" | "contained";
	startIcon?: React.ReactNode;
	sx?: object;
	download?: boolean;
	href?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
	text,
	onClick,
	variant = "contained",
	color = "success",
	startIcon,
	download,
	sx,
	href,
	...props
}) => {
	const theme = useTheme();

	const backgroundColor =
		variant === "contained" && color === "success"
			? theme.palette.mode === "dark"
				? "rgba(61, 127, 142, 1)"
				: "rgba(61, 127, 142, 1)"
			: variant === "contained"
				? theme.palette.mode === "dark"
					? "rgba(255, 255, 255, 0.1)"
					: "rgba(0, 0, 0, 0.87)"
				: "transparent";

	const textColor =
		variant === "contained"
			? theme.palette.mode === "dark"
				? "#fff"
				: "white"
			: theme.palette.mode === "dark"
				? "rgba(255, 255, 255, 0.87)"
				: "rgba(61, 127, 142, 1)";

	return (
		<Button
			variant={variant}
			color={color}
			startIcon={startIcon}
			onClick={onClick}
			href={href}
			download={download ? true : false}
			sx={{
				width: "auto",
				height: "39px",
				padding: "10px 24px",
				borderRadius: "4px",
				backgroundColor: backgroundColor,
				color: textColor,
				borderColor:
					variant === "outlined"
						? theme.palette.mode === "dark"
							? "rgba(255, 255, 255, 0.5)"
							: "rgba(61, 127, 142, 1)"
						: undefined,
				...sx,
			}}
			component={href ? "a" : "button"}
			{...props}
		>
			{text}
		</Button>
	);
};

export default CustomButton;
