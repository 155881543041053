import React from 'react';
import { Modal, Box, Typography, IconButton, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Worker } from '@react-pdf-viewer/core'; 
import { Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css'; 
const PdfViewerModal = ({ open, handleClose, pdfUrl, plugin }) => {
    
    const theme = useTheme();

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                 sx={{
                    width: '100%',
                    height: '100%',
                    padding: '24px',
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">View Document</Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Worker workerUrl="/pdf.worker.min.js">
                    <Viewer fileUrl={pdfUrl} plugins={[plugin]} />
                </Worker>
            </Box>
        </Modal>
    );
};

export default PdfViewerModal;
