import React from 'react';
import { Box } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import DottedLabel from '../Elements/DottedLabel';
import PrimaryHeading from '../Elements/PrimaryHeading';
import SecondaryText from '../Elements/SecondaryText';
import PillLabel from '../Elements/PillLabel';

interface SummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    leftPrimaryHeading: string;
    leftSecondaryText?: string;
    rightPrimaryHeading: string;
    rightSecondaryText?: string;
    showPill?: boolean;
    pillInnerText?: string;
    pillBesideText?: string;
    pillBgColor: string;
    dottedColor: string;
    dottedSize: string;
    additionalLeftLabels: string[];
    additionalRightLabels: string[];
}

const SummaryCard: React.FC<SummaryCardProps> = ({
    title,
    titleIcon,
    leftPrimaryHeading,
    leftSecondaryText,
    rightPrimaryHeading,
    rightSecondaryText,
    showPill,
    pillInnerText,
    pillBesideText,
    pillBgColor,
    dottedColor,
    dottedSize,
    additionalLeftLabels,
    additionalRightLabels,
}) => {
    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2}>
                <Box display="flex" alignItems="center">
                    <Box>
                        <PrimaryHeading>{leftPrimaryHeading}</PrimaryHeading>
                        {leftSecondaryText && <SecondaryText>{leftSecondaryText}</SecondaryText>}
                        
                        {showPill && (
                            <Box mt={2}>
                                <PillLabel bgcolor={pillBgColor} besideText={pillBesideText} innerText={pillInnerText} />
                            </Box>
                        )}

                        <Box mt={2}>
                            {additionalLeftLabels && additionalLeftLabels.map((label, index) => (
                                <DottedLabel key={index} dottedColor={dottedColor} dottedSize={dottedSize} dottedText={label} />
                            ))}
                        </Box>
                        
                        
                    </Box>
                    <Box ml={2} pl={2} borderLeft="2px solid #D9D9D9">
                        <PrimaryHeading>{rightPrimaryHeading}</PrimaryHeading>
                        {rightSecondaryText && <SecondaryText>{rightSecondaryText}</SecondaryText>}
                        <Box mt={2}>
                            {additionalRightLabels && additionalRightLabels.map((label, index) => (
                                <DottedLabel key={index} dottedColor={dottedColor} dottedSize={dottedSize} dottedText={label} />
                            ))}
                        </Box>
                        
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default SummaryCard;
