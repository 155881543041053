import React from "react";
import { ToggleSwitch } from "../Elements/CustomSwitch";
import {
	CheckCircle,
	Error,
	ArrowBack,
	ArrowForward,
	Flag,
} from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import { TableCell, TableRowStyled } from "../Styled/TableComponents";

const CustomTableBody = ({
	actionColumn,
	icons,
	paginatedData,
	columns,
	renderCellValue,
	handleRowSelection,
	selectedRows,
	selectable,
}) => (
	<tbody>
		{paginatedData.length === 0 ? (
			<TableRowStyled>
				<TableCell
					colSpan={
						columns.length + (selectable ? 1 : 0) + (actionColumn ? 1 : 0)
					}
					style={{ textAlign: "center" }}
				>
					No data found.
				</TableCell>
			</TableRowStyled>
		) : (
			paginatedData.map((row, rowIndex) => (
				<TableRowStyled key={rowIndex} onClick={() => handleRowSelection(row)}>
					{selectable && (
						<th key={rowIndex}>
							<Checkbox
								color="success"
								checked={selectedRows.includes(row)}
								onChange={() => handleRowSelection(row)}
							/>
						</th>
					)}
					{columns.map((column) => (
						<TableCell key={column.key}>
							{renderCellValue(row, column)}
						</TableCell>
					))}
					{actionColumn && (
						<TableCell>
							{icons &&
								icons(row).map((icon, index) => (
									<IconButton key={index} onClick={() => icon.onClick(row)}>
										{icon.icon}
									</IconButton>
								))}
						</TableCell>
					)}
				</TableRowStyled>
			))
		)}
	</tbody>
);

export default CustomTableBody;
