import React, { useRef, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

interface PieChartWrapperProps {
    chartData: any;
    options: any;
}

const PieChartWrapper: React.FC<PieChartWrapperProps> = ({ chartData, options }) => {
    
    const chartRef = useRef<ChartJS<'pie', (number | null)[], unknown> | null>(null); 

    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    return <Pie ref={chartRef} data={chartData} options={options} />;
};

export default PieChartWrapper;
