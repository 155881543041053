import React, { useState } from "react";
import {
	IconButton,
	Box,
	Typography,
	CircularProgress,
	useMediaQuery,
	Modal,
	useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UploadIcon from "@mui/icons-material/Upload";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import CardWithIcon from "../Elements/CardWithIcon";
import CustomTable from "../Tables/CustomTable";
import ClientsDealChecklistFloatingActionMenu from "../Menus/ClientsDealchecklistFloatingActionMenu";
import toast from "react-hot-toast";
import { useIsFetching, useQueryClient } from "react-query";
import { validateUploadedApplicationDocs } from "../../../common/services/retool-service";
import LoadingIconButton from "../Elements/LoadingIconButton";

interface DealChecklistProps {
	data: any;
	open: boolean;
	onClose: () => void;
	client?: any;
	isLoading?: boolean;
}

const ClientDealChecklistModal: React.FC<DealChecklistProps> = ({
	data,
	open,
	onClose,
	client,
	isLoading,
}) => {
	const theme = useTheme();

	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [isValidating, setIsValidating] = useState(false);

	const isFetching = useIsFetching();

	const refetch = () => {
		queryClient.invalidateQueries("clientDealChecklists");
		queryClient.invalidateQueries("dealChecklists");
	};

	const handleRowSelect = (row: any) => setSelectedRow(row);
	const queryClient = useQueryClient();

	const handleValidate = async (row: any, value: any) => {
		const toastId = toast.loading("Validating document...");
		setIsValidating(true);

		try {
			const response = await validateUploadedApplicationDocs({
				id: row?.id?.toString(),
				documentRef: row?.documentRef,
				isValidated: !value,
				userId: client?.userId,
			});

			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				queryClient.invalidateQueries("clientDealChecklists");
				queryClient.invalidateQueries("dealChecklists");
			} else {
				toast.error(response.data.message, { id: toastId });
			}
		} catch (error) {
			toast.error("Failed to validate document", { id: toastId });
		}

		setIsValidating(false);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					backgroundColor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "24px", fontWeight: "500" }}
					>
						Deal Checklist
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<Box
					textAlign="right"
					width="100%"
					display="flex"
					justifyContent="flex-end"
				>
					<LoadingIconButton onClick={refetch} isLoading={isFetching > 0} />
				</Box>

				<CustomTable
					isLoading={isLoading}
					columns={[
						{
							title: "Requirement Name",
							key: "requirementName",
							sortable: true,
						},
						{
							title: "Description",
							key: "requirementDescription",
							sortable: true,
						},
						{ title: "Category", key: "category", sortable: true },
						{ title: "Document Ref", key: "documentRef", sortable: true },
						{ title: "Status", key: "status", sortable: true, isPill: true },
						{
							title: "Expired",
							key: "isExpired",
							sortable: true,
							isPill: true,
						},
						{
							title: "Valid",
							key: "isValidated",
							sortable: true,
							isSwitch: true,
							handler: (row, value) => {
								if (row?.status?.toLowerCase() != "uploaded")
									toast.error("Please upload document before validation.");
								else handleValidate(row, value);
							},
						},
					]}
					data={data || []}
					onRowSelect={(rows) => handleRowSelect(rows ? rows.length - 1 : 0)}
					selectable
					selected={selectedRows}
				/>

				{selectedRow && (
					<ClientsDealChecklistFloatingActionMenu
						client={client}
						selectedRow={selectedRow}
					/>
				)}
			</Box>
		</Modal>
	);
};

export default ClientDealChecklistModal;
