import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import { useTheme } from '@mui/material/styles';
import { colors } from '../../../configs/theme';

interface TaskCardProps {
    tasks: {
        status: string;
        taskCount: number;
        highPriorityCount: number;
        dueTodayCount: number;
    }[];
    title: string;
    titleIcon?: React.ReactNode;
}

const TaskCard: React.FC<TaskCardProps> = ({ tasks, title, titleIcon }) => {
    const theme = useTheme();

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={3} mt={-4}>
                {tasks?.map((task, index) => (
                    <Box 
                        key={index} 
                        flex={1} 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="flex-start" 
                        justifyContent="flex-start"
                        ml={index !== 0 ? 2 : 0} 
                        bgcolor={theme.palette.mode == 'light' ? colors.light.background.default : theme.palette.background.paper}
                        boxShadow={`0 4px 4px 0 ${theme.palette.background.paper}`}
                        p={2} 
                        borderRadius="8px" 
                    >
                        <Typography 
                            textAlign="left" 
                            variant="h6" 
                            component="div"
                            color={theme.palette.text.primary}
                        >
                            {task.status}
                        </Typography>
                        <Typography 
                            fontWeight="500" 
                            textAlign="left" 
                            variant="body1" 
                            component="div"
                            color={theme.palette.text.primary}
                        >
                            {task.taskCount} tasks
                        </Typography>
                        <Box mt={1}>
                            <Typography 
                                textAlign="left" 
                                variant="body2" 
                                component="div"
                                color={theme.palette.text.secondary}
                            >
                                {task.highPriorityCount} High Priority
                            </Typography>
                            <Typography 
                                textAlign="left" 
                                variant="body2" 
                                component="div"
                                color={theme.palette.text.secondary} 
                            >
                                {task.dueTodayCount} Due Today
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </BaseCard>
    );
};

export default TaskCard;
