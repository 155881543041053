import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import toast from "react-hot-toast";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ProfileDetailsModal from "../Modals/ProfileDetailsModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import {
	useRunKybUsingEmail,
	useRunKycUsingEmail,
} from "../../../common/helpers/hooks/useClientService";
import { useQueryClient } from "react-query";

interface CompleteProfileProps {
	selectedApplication: any;
	handlePrev: Function;
	handleNext: Function;
}

const CompleteProfile: React.FC<CompleteProfileProps> = ({
	selectedApplication,
	handleNext,
}) => {
	const [tabIndex, setTabIndex] = useState(0);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [formValues, setFormValues] = useState({
		idNumber: selectedApplication?.idNumber || "",
		name: `${selectedApplication?.userProfile?.firstName || ""} ${selectedApplication?.userProfile?.surname || ""}`,
		age: selectedApplication?.userProfile?.age || 0,
		companyRegNo: selectedApplication?.businessProfile?.companyRegNo || "",
		businessName: selectedApplication?.businessProfile?.businessName || "",
		commercialStatus:
			selectedApplication?.businessProfile?.commercialStatus || "",
	});

	const queryClient = useQueryClient();

	const runKycMutation = useRunKycUsingEmail();
	const runKybMutation = useRunKybUsingEmail();

	const handleDetailsOpen = () => {
		setDetailsOpen(true);
	};

	const handleDetailsClose = () => {
		setDetailsOpen(false);
	};

	const handleConfirmationOpen = (message: string) => {
		setConfirmationMessage(message);
		setConfirmationOpen(true);
	};

	const handleConfirmationClose = () => {
		setConfirmationOpen(false);
	};

	const handleRunKYC = () => {
		handleConfirmationOpen("Are you sure you want to run KYC?");
	};

	const handleRunKYB = () => {
		handleConfirmationOpen("Are you sure you want to run KYB?");
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleConfirmAction = async () => {
		setIsLoading(true);
		const email = selectedApplication?.email;
		const idNumber = formValues.idNumber;
		const companyRegNumber = formValues.companyRegNo;
		try {
			if (confirmationMessage.includes("KYC")) {
				const toastId = toast.loading("Running KYC...");
				runKycMutation.mutate(
					{ email, idNumber },
					{
						onSuccess: ({ success, message }) => {
							if (success) toast.success(message, { id: toastId });
							else toast.error(message, { id: toastId });
							queryClient.invalidateQueries("deals");
						},
						onError: () => {
							toast.error("Error running KYC.", { id: toastId });
						},
						onSettled: () => {
							setIsLoading(false);
						},
					}
				);
			} else if (confirmationMessage.includes("KYB")) {
				const toastId = toast.loading("Running KYB...");
				runKybMutation.mutate(
					{ email, companyRegNumber },
					{
						onSuccess: ({ success, message }) => {
							if (success) toast.success(message, { id: toastId });
							else toast.error(message, { id: toastId });
							queryClient.invalidateQueries("deals");
						},
						onError: () => {
							toast.error("Error running KYB.", { id: toastId });
						},
						onSettled: () => {
							setIsLoading(false);
						},
					}
				);
			}
			handleConfirmationClose();
		} catch (error) {
			console.error(`Error running query:`, error);
			toast.error(`Unable to run query.`);
			setIsLoading(false);
		}
	};

	const entrepreneurFields = [
		{
			name: "idNumber",
			label: "ID Number",
			value: formValues.idNumber,
		},
		{
			name: "name",
			label: "Name",
			value: formValues.name,
			condition: !!formValues.idNumber,
		},
		{
			name: "age",
			label: "Age",
			type: "number",
			value: formValues.age,
			condition: !!formValues.idNumber,
		},
	];

	const businessFields = [
		{
			name: "companyRegNo",
			label: "Registration Number",
			value: formValues.companyRegNo,
		},
		{
			name: "businessName",
			label: "Name",
			value: formValues.businessName,
			condition: !!formValues.companyRegNo,
		},
		{
			name: "commercialStatus",
			label: "Status",
			value: formValues.commercialStatus,
			condition: !!formValues.companyRegNo,
		},
	];

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<BaseCard sx={{ padding: "10px 20px", height: "auto" }}>
						<CustomCardHeader title="Entrepreneur Profile" />
						<form>
							{entrepreneurFields.map(
								({ name, label, value, type, condition }) =>
									condition !== false ? (
										<CustomTextInput
											key={name}
											name={name}
											label={label}
											value={value}
											type={type}
											onChange={handleChange}
											sx={{ mb: 1 }}
										/>
									) : null
							)}

							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								{!selectedApplication?.userProfile?.idNumber ? (
									<Typography variant="body1" color="error">
										KYC has not been run.
									</Typography>
								) : (
									<Typography variant="body1">
										KYC has been completed.
									</Typography>
								)}
								<CustomButton
									text="Run KYC"
									onClick={handleRunKYC}
									sx={{ ml: 2 }}
									variant="contained"
									color="success"
								/>
							</Box>
						</form>
					</BaseCard>
				</Grid>

				<Grid item xs={6}>
					<BaseCard sx={{ padding: "10px 20px", height: "auto" }}>
						<CustomCardHeader title="Business Profile" />
						<form>
							{businessFields.map(({ name, label, value, condition }) =>
								condition !== false ? (
									<CustomTextInput
										key={name}
										name={name}
										label={label}
										value={value}
										onChange={handleChange}
										sx={{ mb: 1 }}
									/>
								) : null
							)}

							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								{!selectedApplication?.businessProfile?.companyRegNo ? (
									<Typography variant="body1" color="error">
										KYB has not been run.
									</Typography>
								) : (
									<Typography variant="body1">
										KYB has been completed.
									</Typography>
								)}
								<CustomButton
									text="Run KYB"
									onClick={handleRunKYB}
									sx={{ ml: 2 }}
									color="success"
									variant="contained"
								/>
							</Box>
						</form>
					</BaseCard>
				</Grid>
			</Grid>

			<Box mt={2} display="flex" justifyContent="center">
				<CustomButton
					text="More Details"
					onClick={handleDetailsOpen}
					variant="outlined"
					color="secondary"
				/>
				<CustomButton
					text="Continue"
					onClick={() => handleNext()}
					sx={{ ml: 2 }}
					color="success"
					variant="contained"
				/>
			</Box>

			<ProfileDetailsModal
				open={detailsOpen}
				onClose={handleDetailsClose}
				selectedApplication={selectedApplication}
				showDeal={false}
			/>

			<ConfirmationModal
				open={confirmationOpen}
				onClose={handleConfirmationClose}
				message={confirmationMessage}
				handleConfirmAction={handleConfirmAction}
				isLoading={isLoading}
			/>
		</>
	);
};

export default CompleteProfile;
