import React from 'react';
import { Box } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import CircleWithText from '../Elements/CircleWithText';
import TextLabel from '../Elements/TextLabel';
import DottedLabel from '../Elements/DottedLabel';

interface InfoCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    circleSize?: number;
    circleInnerText: string;
    circleBesideText: string;
    circleColor: string;
    dottedColor: string;
    dottedSize: string;
    additionalLabels: string[];
    labelText: string;
    labelColor: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
    title,
    titleIcon,
    circleSize,
    circleInnerText,
    circleBesideText,
    circleColor,
    dottedColor,
    dottedSize,
    additionalLabels,
    labelText,
    labelColor
}) => {
  
    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2}>
                <Box display="flex" alignItems="center">
                    <CircleWithText bgcolor={circleColor} size={circleSize} innerText={circleInnerText} besideText={circleBesideText} />
                    <Box ml={2} pl={2} borderLeft="2px solid #D9D9D9">
                        {additionalLabels?.slice(0, 3).map((label, index) => (
                            <DottedLabel key={index} dottedColor={dottedColor} dottedSize={dottedSize} dottedText={label} />
                        ))}
                        {additionalLabels?.length > 3 && <TextLabel text={labelText} color={labelColor} />}
                    </Box>
                </Box>
                
            </Box>
        </BaseCard>
    );
};

export default InfoCard;
