import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import toast from "react-hot-toast";
import DealSummaryDialog from "../Modals/DealSummaryModal";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput"; // Assuming CustomTextInput is in Elements
import {
	updateDealSummary,
	getDealSummary,
} from "../../../common/services/retool-service";

interface DealSummaryProps {
	selectedApplication: any;
	handlePrev: () => void;
}

const DealSummary: React.FC<DealSummaryProps> = ({
	selectedApplication,
	handlePrev,
}) => {
	const [dealDescriptionOverview, setDealDescriptionOverview] = useState(
		selectedApplication?.dealSummary?.dealDescriptionOverview
	);
	const [companyProfileOverview, setCompanyProfileOverview] = useState(
		selectedApplication?.dealSummary.companyProfileOverView
	);

	const [dealSummaryData, setDealSummaryData] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);

	const fetchDealSummary = async () => {
		try {
			const response = await getDealSummary(
				selectedApplication?.dealSummary.dealSummaryId
			);

			setDealSummaryData(response?.data?.data);
			setDealDescriptionOverview(response?.data?.data.dealDescriptionOverview);
			setCompanyProfileOverview(response?.data?.data.companyProfileOverView);

			setIsLoading(false);
		} catch (error) {
			console.error("Error fetching deal summary:", error);
			toast.error("Failed to fetch deal summary.");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDealSummary();
	}, [selectedApplication]);

	const handleSave = async () => {
		const payload = {
			lendingApplicationId: selectedApplication?.id,
			dealDescriptionOverview,
			companyProfileOverview,
			...dealSummaryData,
		};

		const toastId = toast.loading("Updating deal summary...");

		try {
			await updateDealSummary(payload);
			toast.success("Deal summary updated successfully.", { id: toastId });
			fetchDealSummary();
		} catch (error) {
			console.error("Error updating deal summary:", error);
			toast.error("Failed to update deal summary.", { id: toastId });
		}
	};

	const handleExport = async () => {
		const toastId = toast.loading("Exporting deal summary...");

		try {
			// Logic for exporting deal summary
			toast.success("Deal summary exported successfully.", { id: toastId });
		} catch (error) {
			console.error("Error exporting deal summary:", error);
			toast.error("Failed to export deal summary.", { id: toastId });
		}
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	if (isLoading) {
		return <CircularProgress color="success" />;
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<CustomTextInput
						label="Deal Description Overview"
						fullWidth
						multiline
						minRows={4}
						value={dealDescriptionOverview}
						onChange={(e) => setDealDescriptionOverview(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomTextInput
						label="Company Profile Overview"
						fullWidth
						multiline
						minRows={4}
						value={companyProfileOverview}
						onChange={(e) => setCompanyProfileOverview(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="flex-end" gap={2}>
						<CustomButton
							text="Back"
							onClick={handlePrev}
							variant="outlined"
							color="secondary"
						/>
						<CustomButton
							text="Save"
							onClick={handleSave}
							variant="contained"
						/>
						<CustomButton
							text="Deal Summary"
							onClick={handleModalOpen}
							variant="contained"
						/>
					</Box>
				</Grid>
			</Grid>

			{modalOpen && (
				<DealSummaryDialog
					open={modalOpen}
					onClose={handleModalClose}
					dealSummaryData={dealSummaryData}
					setDealSummaryData={setDealSummaryData}
					handleExport={handleExport}
					handleSave={handleSave}
				/>
			)}
		</>
	);
};

export default DealSummary;
