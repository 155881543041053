import React from "react";
import { Box, Chip } from "@mui/material";
import BaseCard from "../Cards/BaseCard";
import IconWithText from "./IconWithText";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

interface CardWithIconProps {
	onClick?: () => void;
	icon: React.ReactNode;
	text: string;
	sx?: object;
	disabled?: boolean;
	completed?: boolean;
	label?: string;
}

const CardWithIcon: React.FC<CardWithIconProps> = ({
	onClick,
	icon,
	text,
	sx,
	disabled = false,
	completed = false,
	label,
}) => {
	const theme = useTheme();

	return (
		<Box
			onClick={disabled ? undefined : onClick}
			sx={{ opacity: disabled ? 0.5 : 1 }}
		>
			<BaseCard
				sx={{
					height: "auto",
					width: "auto",
					padding: "10px",
					cursor: disabled ? "not-allowed" : "pointer",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor:
						theme.palette.mode === "dark" ? "#424242" : "#FFFFFF",
					color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000000",
					...sx,
				}}
			>
				<IconWithText icon={icon} besideText={text} />
				{completed && (
					<Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
						<CheckCircleOutline sx={{ color: "green" }} />
					</Box>
				)}

				{label != null && (
					<Chip label={label} color={label ? "success" : "error"} />
				)}
			</BaseCard>
		</Box>
	);
};

export default CardWithIcon;
