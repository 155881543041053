import { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const PolarAreaChartWrapper: React.FC<{ chartData: any; options: any }> = ({ chartData, options }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            const chartInstance = new Chart(ctx!, {
                type: 'polarArea',
                data: chartData,
                options: options,
            });

            return () => {
                chartInstance.destroy();
            };
        }
    }, [chartData, options]);

    return <canvas ref={canvasRef} />;
};


export default PolarAreaChartWrapper;