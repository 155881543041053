import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface CircleWithTextProps {
    besideText: string;
    icon: React.ReactNode;
}

const IconWithText: React.FC<CircleWithTextProps> = ({ besideText, icon }) => {
    
    const theme = useTheme();

    return (
        <Box display="flex" alignItems="center">
            {icon}
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                    textAlign: 'left',
                    marginLeft: '3px',
                    color: theme.palette.text.primary,
                    fontFamily: 'Roboto'
                }}
            >
                {besideText}
            </Typography>
        </Box>
    );
};

export default IconWithText;
