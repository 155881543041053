import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type CustomTextInputProps = TextFieldProps & {
  label: string;
  value: string | number;
};

const CustomTextInput: React.FC<CustomTextInputProps> = ({ label, value, ...rest }) => {
  const theme = useTheme();

  return (
    <TextField

      label={label}
      value={value || ''}
      fullWidth
      margin="normal"
      InputProps={{
        readOnly: false,
        sx: {
          color: theme.palette.mode === 'light' ? theme.palette.text.primary : 'white',
        },
      }}
      sx={{

        '& .MuiInputLabel-root': {
          backgroundColor: 'transparent',
          color: theme.palette.mode === 'light' ? theme.palette.text.secondary : 'white',
        },

        '& .MuiOutlinedInput-input': {
          color: theme.palette.mode === 'light' ? theme.palette.text.secondary : 'white',
        },
        '& .MuiInputBase-input': {
          color: theme.palette.mode === 'light' ? theme.palette.text.primary : 'white',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: theme.palette.mode === 'light' ? theme.palette.text.disabled : 'rgba(255, 255, 255, 0.5)',
        },
      }}
      {...rest}
    />
  );
};

export default CustomTextInput;
