import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';
import DottedLabel from '../Elements/DottedLabel';
import LineChartWrapper from '../Charts/LineChartWrapper';
import LineChartDetails from '../Charts/LineChartDetails';

interface SummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    labels?: string[];
    data: { data1: number[]; data2: number[] };
    maxValue: number;
        
  
}

const LineChartSummaryCard: React.FC<SummaryCardProps> = ({
    
    title,
    titleIcon,
    labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    data,
    maxValue
    
}) => {
    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" justifyContent="space-between" p={2}>
                <LineChartDetails labels={labels} data={data} maxValue={maxValue} />
            </Box>
        </BaseCard>
    );
};

export default LineChartSummaryCard;
