import React, { useEffect, useState } from "react";
import {
	IconButton,
	Box,
	Typography,
	CircularProgress,
	useMediaQuery,
	Modal,
	useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomTable from "../Tables/CustomTable";
import DealsDealChecklistFloatingActionMenu from "../Menus/DealsDealchecklistFloatingActionMenu";
import toast from "react-hot-toast";
import { validateUploadedApplicationDocs } from "../../../common/services/retool-service";
import { useIsFetching, useQueryClient } from "react-query";
import LoadingIconButton from "../Elements/LoadingIconButton";

interface DealChecklistProps {
	data: any;
	open: boolean;
	onClose: () => void;
	deal?: any;
	isLoading?: boolean;
}

const DealChecklistModal: React.FC<DealChecklistProps> = ({
	data,
	open,
	onClose,
	deal,
	isLoading,
}) => {
	const theme = useTheme();
	const isFetching = useIsFetching();

	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const queryClient = useQueryClient();

	const handleRowSelect = (row: any) => setSelectedRow(row);

	const refetch = () => {
		queryClient.invalidateQueries("clientDealChecklists");
		queryClient.invalidateQueries("dealChecklists");
	};

	const handleValidate = async (row: any, value: any) => {
		const toastId = toast.loading("Validating document...");
		const payload = {
			id: row?.id?.toString(),
			documentRef: row?.documentRef,
			isValidated: !value,
			userId: deal?.userId,
		};

		try {
			const response = await validateUploadedApplicationDocs(payload);

			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				refetch();
			} else {
				toast.error(response.data.message, { id: toastId });
			}
		} catch (error) {
			toast.error("Failed to validate document", { id: toastId });
		}
	};

	useEffect(() => {
		setSelectedRows([]);
	}, [data]);

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					backgroundColor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "24px", fontWeight: "500" }}
					>
						Deal Checklist
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<Box
					textAlign="right"
					width="100%"
					display="flex"
					justifyContent="flex-end"
				>
					<LoadingIconButton onClick={refetch} isLoading={isFetching > 0} />
				</Box>

				<CustomTable
					isLoading={isLoading}
					columns={[
						{
							title: "Requirement Name",
							key: "requirementName",
							sortable: true,
						},
						{
							title: "Description",
							key: "requirementDescription",
							sortable: true,
						},
						{ title: "Category", key: "category", sortable: true },
						{ title: "Document Ref", key: "documentRef", sortable: true },
						{ title: "Status", key: "status", sortable: true, isPill: true },
						{
							title: "Expired",
							key: "isExpired",
							sortable: true,
							isPill: true,
						},
						{
							title: "Valid",
							key: "isValidated",
							sortable: true,
							isSwitch: true,
							handler: (row, value) => {
								if (row?.status?.toLowerCase() != "uploaded")
									toast.error("Please upload document before validation.");
								else handleValidate(row, value);
							},
						},
					]}
					data={data || []}
					onRowSelect={(rows) => {
						setSelectedRows(rows);
					}}
					selectable
					selected={selectedRows}
				/>

				{selectedRows.length > 0 && (
					<DealsDealChecklistFloatingActionMenu
						deal={deal}
						selectedRow={
							selectedRows?.length
								? selectedRows[selectedRows.length - 1]
								: selectedRows[0]
						}
					/>
				)}
			</Box>
		</Modal>
	);
};

export default DealChecklistModal;
