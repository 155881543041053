import React from 'react';
import { Checkbox, TableSortLabel, IconButton, Popover, Box, FormControlLabel } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { TableCell, TableRowStyled } from '../Styled/TableComponents';

const CustomTableHead = ({ actionColumn, columns, selectable, isAllSelected, handleSelectAll, sortConfig, handleSort, handleFilterClick, riskFilter, anchorEl, open, id, handleFilterClose, handleRiskFilterChange }) => (
  <thead>
  <TableRowStyled>
      {selectable && (
          <th>
              <Checkbox
                  color='success'
                  checked={isAllSelected}
                  onChange={handleSelectAll}
              />
          </th>
      )}
      {columns.map((column) => (
          <th key={column.key} style={{ padding: '15px 0px', textAlign: 'left', fontWeight: '500', fontSize: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                  
                  {column.icon && <span style={{ marginRight: '8px' }}>{column.icon}</span>}
                  {column.sortable ? (
                      <TableSortLabel
                          active={sortConfig?.key === column.key}
                          direction={sortConfig?.key === column.key ? sortConfig.direction : 'asc'}
                          onClick={() => column.sortable && handleSort(column.key)}
                      >
                          <span style={{ fontWeight: '500', fontSize: '14px' }}>{column.title}</span>
                      </TableSortLabel>
                  ) : (
                      <span style={{ fontWeight: '500', fontSize: '14px' }}>{column.title}</span>
                  )}
                  {column.key === 'riskStatus' && (
                      <>
                          <IconButton onClick={handleFilterClick}>
                              <FilterList />
                          </IconButton>
                          <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleFilterClose}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                              }}
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                              }}
                          >
                              <Box padding={2} display="flex" flexDirection="column">
                                  <FormControlLabel
                                      control={<Checkbox color='success' checked={riskFilter.Low} onChange={() => handleRiskFilterChange('Low')} />}
                                      label="Low"
                                  />
                                  <FormControlLabel
                                      control={<Checkbox color='success' checked={riskFilter.Medium} onChange={() => handleRiskFilterChange('Medium')} />}
                                      label="Medium"
                                  />
                                  <FormControlLabel
                                      control={<Checkbox color='success' checked={riskFilter.High} onChange={() => handleRiskFilterChange('High')} />}
                                      label="High"
                                  />
                              </Box>
                          </Popover>
                      </>
                  )}
              </div>
          </th>
      ))}

      {actionColumn && (
          <th style={{ padding: '15px 10px', textAlign: 'left', fontWeight: '500', fontSize: '10px' }}>
              <span style={{ fontWeight: '500', fontSize: '14px' }}>{actionColumn.title}</span>
          </th>
      )}

      
  </TableRowStyled>
</thead>                  
);

export default CustomTableHead;
