import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';

const NoDataFound = () => {
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="136px"
        >
            <ErrorOutlineIcon style={{ fontSize: 80, color: 'rgba(0, 0, 0, 0.54)' }} />
            <Typography fontSize="14px" lineHeight="19px" letterSpacing="-0.15%" fontWeight="400" style={{ marginTop: '10px', color: 'rgba(93, 94, 98, 1)' }}>
                No data found
            </Typography>
        </Box>
    );
};

export default NoDataFound;
