import React from "react";
import { CircularProgress, Button } from "@mui/material";
import Refresh from "@mui/icons-material/Refresh"; // Import the Refresh icon

interface LoadingIconButtonProps {
	isLoading: boolean;
	onClick: () => void;
}

const LoadingIconButton: React.FC<LoadingIconButtonProps> = ({
	isLoading,
	onClick,
}) => {
	return (
		<Button
			variant="outlined"
			color="secondary"
			onClick={onClick}
			sx={{ border: "none", display: "flex", alignItems: "center" }}
		>
			{isLoading ? (
				<CircularProgress size="20px" color="success" />
			) : (
				<Refresh sx={{ fill: "rgba(61, 127, 142, 1)" }} fontSize="large" />
			)}
		</Button>
	);
};

export default LoadingIconButton;
