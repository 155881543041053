import React from 'react';
import { Typography, useTheme } from '@mui/material';

const SecondaryText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();

    return (
        <Typography
            style={{
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '19px',
                letterSpacing: '-0.0015em',
                textAlign: 'left',
                color: theme.palette.text.secondary,
            }}
        >
            {children}
        </Typography>
    );
};

export default SecondaryText;
