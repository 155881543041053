import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface CircleWithTextProps {
  innerText: string;
  besideText: string;
  size?: number;
  bgcolor: string;
}

const CircleWithText: React.FC<CircleWithTextProps> = ({
  innerText,
  besideText,
  bgcolor,
  size = 40,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          backgroundColor: bgcolor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.mode === 'dark' ? '#FEF7FF' : '#FEF7FF',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '1.5',
            letterSpacing: '0.15px',
          }}
        >
          {innerText}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '1.5',
          letterSpacing: '0.15px',
          textAlign: 'left',
          marginLeft: '10px',
          color: theme.palette.mode === 'dark' ? '#FEF7FF' : '#1D1B20',
        }}
      >
        {besideText}
      </Typography>
    </Box>
  );
};

export default CircleWithText;
