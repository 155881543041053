import React, { useState } from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Modal,
	Box,
	TextField,
	IconButton,
	Chip,
	Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CustomButton from "./CustomButton";

interface CustomSelectProps {
	name: string;
	label: string;
	options: { value: string; label: string }[];
	value: string | string[];
	onChange: (event: SelectChangeEvent<string | string[]>) => void;
	multiple?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	name,
	label,
	options,
	value,
	onChange,
	multiple = false,
}) => {
	const theme = useTheme();
	const [openModal, setOpenModal] = useState(false);
	const [customValue, setCustomValue] = useState("");
	const [customOptions, setCustomOptions] = useState<
		{ value: string; label: string }[]
	>([]);

	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	const handleAddCustomOption = () => {
		if (customValue.trim()) {
			const newOption = { value: customValue, label: customValue };
			setCustomOptions([...customOptions, newOption]);
			if (multiple && Array.isArray(value)) {
				onChange({
					target: { value: [...(value as string[]), customValue] },
				} as SelectChangeEvent<string[]>);
			} else {
				onChange({
					target: { value: customValue },
				} as SelectChangeEvent<string>);
			}
			setCustomValue("");
		}
	};

	const handleDeleteCustomOption = (valueToDelete: string) => {
		setCustomOptions((prevOptions) =>
			prevOptions.filter((opt) => opt.value !== valueToDelete)
		);
		if (Array.isArray(value)) {
			onChange({
				target: { value: value.filter((v) => v !== valueToDelete) },
			} as SelectChangeEvent<string[]>);
		}
	};

	return (
		<>
			<FormControl fullWidth margin="normal">
				<InputLabel
					sx={{
						backgroundColor:
							theme.palette.mode === "dark" ? "transparent" : "white",
						color:
							theme.palette.mode === "dark"
								? "white"
								: theme.palette.text.primary,
					}}
				>
					{label}
				</InputLabel>
				<Select
					name={name}
					multiple={multiple}
					value={value}
					onChange={onChange}
					renderValue={(selected) => {
						const selectedValues = Array.isArray(selected)
							? selected
							: [selected];
						const displayValues = selectedValues
							.map((val) => {
								const option = options
									.concat(customOptions)
									.find((opt) => opt.value === val);
								return option ? option.label : val;
							})
							.join(", ");
						return displayValues.includes("Other")
							? `Others (${displayValues})`
							: displayValues;
					}}
					sx={{
						color:
							theme.palette.mode === "dark"
								? "white"
								: theme.palette.text.primary,
						textAlign: "left",
					}}
				>
					{options.concat(customOptions).map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
					<MenuItem value="Other" onClick={handleOpenModal}>
						Other
					</MenuItem>
				</Select>
			</FormControl>

			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						backgroundColor:
							theme.palette.mode == "dark"
								? theme.palette.background.default
								: theme.palette.background.paper,
						boxShadow: 24,
						p: 2,
						width: 400,
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Box
						mb={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Typography variant="h6">Add Others</Typography>
						<IconButton
							aria-label="close"
							onClick={handleCloseModal}
							sx={{
								color: theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</Box>

					<TextField
						label="Enter Other Value"
						value={customValue}
						onChange={(e) => setCustomValue(e.target.value)}
						fullWidth
					/>
					<Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
						{customOptions.map((option) => (
							<Chip
								key={option.value}
								label={option.label}
								onDelete={() => handleDeleteCustomOption(option.value)}
								sx={{ marginBottom: 1 }}
							/>
						))}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							mt: 2,
							gap: "10px",
						}}
					>
						<CustomButton
							text="Close"
							onClick={handleCloseModal}
							variant="outlined"
							color="secondary"
						/>
						<CustomButton
							text="Add"
							onClick={handleAddCustomOption}
							variant="contained"
						/>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default CustomSelect;
