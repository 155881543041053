import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";

const OffersTable = ({
	offersSearchTerm,
	handleOffersSearchChange,
	isLoadingOffers,
	filteredOffersData,
	setSelectedRow,
	setOpenCreateOfferModal,
	handleDelete,
	setSelectedRows,
	selectedRows,
}) => {
	const theme = useTheme();
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<CustomSearchBar
				searchTerm={offersSearchTerm}
				onSearchChange={handleOffersSearchChange}
			/>
			<CustomTable
				isLoading={isLoadingOffers}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="View/Update Loan Offer" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(offer);
							setOpenCreateOfferModal(true);
						},
					},
					{
						icon: (
							<Tooltip title="Delete Loan Offer" arrow>
								<Delete
									sx={{
										fill: theme.palette.mode === "light" ? "red" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => handleDelete(offer),
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Amount", key: "approvedFundingAmount", sortable: true },
					{ title: "Duration (days)", key: "approvedDuration", sortable: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredOffersData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => {
					setSelectedRows(rows);
				}}
				selected={selectedRows}
			/>
		</Box>
	);
};

export default OffersTable;
