import React, { FC } from "react";
import {
	Box,
	Button,
	CircularProgress,
	FormHelperText,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useLoginForm from "../../../common/helpers/hooks/useLoginForm";

interface LoginFormProps {
	email?: string;
	isLoading: boolean;
	showPassword: boolean;
	handleTogglePasswordVisibility: () => void;
}

const LoginForm: FC<LoginFormProps> = ({
	email = "",
	isLoading,
	showPassword,
	handleTogglePasswordVisibility,
}) => {
	const formik = useLoginForm(email);

	return (
		<form className="auth-form" noValidate onSubmit={formik.handleSubmit}>
			<TextField
				autoFocus
				error={Boolean(formik.touched.email && formik.errors.email)}
				fullWidth
				helperText={formik.touched.email && formik.errors.email}
				label="Email Address"
				margin="normal"
				name="email"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				type="email"
				size="small"
				value={formik.values.email}
			/>

			<TextField
				error={Boolean(formik.touched.password && formik.errors.password)}
				fullWidth
				helperText={formik.touched.password && formik.errors.password}
				label="Password"
				margin="normal"
				name="password"
				size="small"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				type={showPassword ? "text" : "password"}
				value={formik.values.password}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={handleTogglePasswordVisibility} edge="end">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>

			{formik.errors.submit && (
				<Box sx={{ mt: 3 }}>
					<FormHelperText error>{formik.errors.submit}</FormHelperText>
				</Box>
			)}

			<Box sx={{ mt: 2 }}>
				{isLoading ? (
					<Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
						<CircularProgress />
					</Box>
				) : (
					<Button
						disabled={formik.isSubmitting}
						fullWidth
						type="submit"
						sx={{ background: "#619C9C" }}
						size="small"
						variant="contained"
					>
						Login
					</Button>
				)}
			</Box>
		</form>
	);
};

export default LoginForm;
