import React from "react";
import {
	Modal,
	Box,
	Typography,
	CircularProgress,
	useTheme,
} from "@mui/material";

interface ConfirmationModalProps {
	open: boolean;
	message: any;
	onClose: () => void;
	handleConfirmAction: () => void;
	isLoading: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	open,
	message,
	onClose,
	handleConfirmAction,
	isLoading,
}) => {
	const theme = useTheme();
	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					maxWidth: "600px",
					height: "auto",
					maxHeight: "100vh",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
				}}
			>
				<Typography
					mb={3}
					color="rgba(29, 27, 32, 1)"
					fontSize="24px"
					lineHeight="32px"
					fontFamily="Roboto"
					fontWeight="500"
					gutterBottom
				>
					Confirmation
				</Typography>

				<Typography
					mb={3}
					color="rgba(82, 84, 89, 1)"
					fontSize="14px"
					lineHeight="20px"
					letterSpacing="0.25px"
					fontFamily="Roboto"
					fontWeight="400"
					gutterBottom
				>
					{message}
				</Typography>

				<Box
					p={2}
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "20px",
							letterSpacing: "0.1px",
							color: "rgba(61, 127, 142, 1)",
							cursor: "pointer",
						}}
						onClick={onClose}
					>
						Cancel
					</Typography>

					{isLoading ? (
						<CircularProgress size="1rem" color="success" />
					) : (
						<Typography
							onClick={handleConfirmAction}
							sx={{
								fontFamily: "Roboto",
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								letterSpacing: "0.1px",
								color: "rgba(61, 127, 142, 1)",
								cursor: "pointer",
							}}
						>
							Confirm
						</Typography>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ConfirmationModal;
