import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	Checkbox,
	CircularProgress,
	useTheme,
} from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import toast from "react-hot-toast";
import { validateUploadedApplicationDocs } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";

const ValidateDocumentModal = ({ open, handleClose, selectedRow }) => {
	const theme = useTheme();
	const [isValidated, setIsValidated] = useState(
		selectedRow?.isValidated === "Yes"
	);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const handleValidation = () => {
		setIsValidated((prev) => !prev);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const response = await validateUploadedApplicationDocs({
				id: selectedRow?.id.toString(),
				documentRef: selectedRow?.documentRef,
				isValidated: isValidated,
				userId: selectedRow?.userId,
			});

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("clientDealChecklists");
				queryClient.invalidateQueries("dealChecklists");
			} else {
				toast.error(response.data.message);
			}

			handleClose();
		} catch (error) {
			toast.error("Failed to validate document");
		}
		setIsLoading(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					padding: 4,
					backgroundColor: theme.palette.background.paper,
					borderRadius: 2,
					outline: "none",
					maxWidth: 400,
					margin: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<Typography variant="h6" mb={2}>
					Validate Document
				</Typography>
				<Typography>
					Kindly check the box if you want to validate this document.
				</Typography>
				<Box display="flex" alignItems="center" mt={2}>
					<Checkbox
						checked={isValidated}
						onChange={handleValidation}
						color="success"
					/>
					Validate
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					mt={2}
					gap={2}
					alignItems="center"
				>
					<CustomButton
						text="Cancel"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
					/>
					{isLoading ? (
						<CircularProgress size="1rem" color="success" />
					) : (
						<CustomButton
							text="Submit"
							onClick={handleSubmit}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ValidateDocumentModal;
