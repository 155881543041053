import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, useTheme } from "@mui/material";
import CustomTable from "../Tables/CustomTable";
import TabsWithSearch from "../Widgets/TabsWithSearch";
import CustomButton from "../Elements/CustomButton";

interface TransactionOverviewModalProps {
	open: boolean;
	onClose: () => void;
	transactions: any[];
	businessName: string | null;
	isLoading?: boolean;
}

const TransactionOverviewModal: React.FC<TransactionOverviewModalProps> = ({
	open,
	onClose,
	transactions,
	businessName,
	isLoading,
}) => {
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredTransactions, setFilteredTransactions] = useState<any[]>([]);

	useEffect(() => {
		const filtered = transactions?.filter((transaction) => {
			const description = transaction.Description || "";
			const date = transaction.Date || "";
			const amount = transaction.Amount;

			return (
				(value === 0 ||
					(value === 1 && amount > 0) ||
					(value === 2 && amount < 0)) &&
				(description.toLowerCase().includes(searchQuery.toLowerCase()) ||
					date.toLowerCase().includes(searchQuery.toLowerCase()))
			);
		});
		setFilteredTransactions(filtered);
	}, [transactions, value, searchQuery]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const tabs = [
		{ label: "All", value: 0 },
		{ label: "Income", value: 1 },
		{ label: "Expenses", value: 2 },
	];

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "97%",
					maxHeight: "80vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px 8px",
					border: 0,
					margin: "auto",
					overflowY: "auto",
					top: "10%",
					position: "relative",
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
						lineHeight: "32px",
						fontFamily: "Roboto",
						color: theme.palette.text.primary,
					}}
				>
					Transactions for {businessName}
				</Typography>

				<TabsWithSearch
					tabs={tabs}
					selectedTab={value}
					onTabChange={handleChange}
					searchTerm={searchQuery}
					onSearchChange={handleSearch}
				/>

				<CustomTable
					isLoading={isLoading}
					columns={[
						{ title: "Description", key: "Description", sortable: true },
						{ title: "Date", key: "Date", sortable: true },
						{
							title: "Amount",
							key: "Amount",
							sortable: true,
							withSignFormating: true,
						},
					]}
					data={filteredTransactions}
				/>

				<Box
					sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}
				>
					<CustomButton
						text="Close"
						onClick={onClose}
						variant="outlined"
						color="secondary"
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default TransactionOverviewModal;
