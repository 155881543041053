import React from 'react';
import { Tabs, Tab, TabsProps, useTheme } from '@mui/material';

interface CustomTabsProps extends Omit<TabsProps, 'onChange'> {
    tabs: { label: string }[];
    selectedTab: number;
    onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, selectedTab, onTabChange, ...rest }) => {
    const theme = useTheme();

    return (
        <Tabs
            value={selectedTab}
            onChange={onTabChange}
            aria-label="custom client tabs"
            sx={{
                padding: '10px 0px',
                minHeight: '48px',
                flexGrow: 1,
                borderBottom: `2px solid ${theme.palette.divider}`,
                '& .MuiTab-root': {
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    textAlign: 'center',
                    color: theme.palette.text.primary,
                },
                '& .Mui-selected': {
                    backgroundColor: theme.palette.action.selected,
                    borderBottom: `2px solid ${theme.palette.success.main}`,
                },
                '& .MuiTab-root.Mui-selected': {
                    color: theme.palette.text.primary,
                },
                '& .MuiTab-root:hover': {
                    color: theme.palette.text.primary,
                },
                '& .MuiTabs-indicator': {
                    backgroundColor: theme.palette.success.main,
                    height: '3px',
                },
            }}
            {...rest}
        >
            {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
            ))}
        </Tabs>
    );
};

export default CustomTabs;
