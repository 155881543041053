import React, { useState, useEffect } from "react";
import {
	Box,
	CircularProgress,
	Modal,
	Typography,
	useTheme,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import toast from "react-hot-toast";
import { createOrUpdateLoanOffer } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";
import { useDropzone } from "react-dropzone";

interface LoanOfferModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
	client?: any;
	type?: string;
}

const LoanOfferModal: React.FC<LoanOfferModalProps> = ({
	open,
	onClose,
	selectedApplication,
	client,
	type = "offer",
}) => {
	const queryClient = useQueryClient();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);

	const [formData, setFormData] = useState({
		approvedFundingAmount: 0,
		approvedDuration: 0,
		interestRate: 0,
		originationFee: 0,
		repaymentTerms: "",
		loanConditions: "",
		loanStatus: "Approved",
		lendingApplicationId:
			type == "offer"
				? selectedApplication?.lendingApplicationId
				: selectedApplication?.id,
		id:
			type == "offer"
				? selectedApplication?.id
				: selectedApplication?.loanOfferId,
		serviceFee: 0,
		total: 0,
		uploadedFileName: "",
	});
	const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

	const calculateServiceFee = (
		fundingAmount: number,
		interestRate: number,
		duration: number
	) => {
		const calculatedFee = (duration * interestRate * fundingAmount) / 100;
		return calculatedFee < 500 ? 500 : calculatedFee;
	};

	const calculateTotal = (
		fundingAmount: number,
		originationFee: number,
		serviceFee: number
	) => Number(fundingAmount) + Number(originationFee) + Number(serviceFee);

	useEffect(() => {
		if (selectedApplication) {
			const serviceFee = calculateServiceFee(
				selectedApplication.approvedFundingAmount || 0,
				selectedApplication.interestRate || 0,
				selectedApplication.approvedDuration || 0
			);
			setFormData({
				approvedFundingAmount: selectedApplication.approvedFundingAmount || 0,
				approvedDuration: selectedApplication.approvedDuration || 0,
				interestRate: selectedApplication.interestRate || 0,
				originationFee: selectedApplication.originationFee || 0,
				repaymentTerms: selectedApplication.repaymentTerms || "",
				loanConditions: selectedApplication.loanConditions || "",
				loanStatus: "Approved",
				lendingApplicationId:
					type == "offer"
						? selectedApplication?.lendingApplicationId
						: selectedApplication?.id,
				id:
					type == "offer"
						? selectedApplication?.id
						: selectedApplication?.loanOfferId,
				serviceFee,
				total: calculateTotal(
					selectedApplication.approvedFundingAmount || 0,
					selectedApplication.originationFee || 0,
					serviceFee
				),
				uploadedFileName: selectedApplication.uploadedFileName || "",
			});
		}
	}, [selectedApplication]);

	type FormDataKey = keyof typeof formData;

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => {
			const updatedForm = {
				...prevFormData,
				[name as FormDataKey]: value,
			};
			return {
				...updatedForm,
				serviceFee: calculateServiceFee(
					Number(updatedForm.approvedFundingAmount),
					Number(updatedForm.interestRate),
					Number(updatedForm.approvedDuration)
				),
				total: calculateTotal(
					updatedForm.approvedFundingAmount,
					updatedForm.originationFee,
					updatedForm.serviceFee
				),
			};
		});
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/pdf": [".pdf"],
			"text/csv": [".csv"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
		multiple: true,
		onDrop: (acceptedFiles) => {
			setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		},
	});

	const handleFileRemove = (fileToRemove: File) => {
		setSelectedFiles((prevFiles) =>
			prevFiles.filter((file) => file !== fileToRemove)
		);
	};
	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving loan offer...");

		try {
			const filesAsBase64 = await Promise.all(
				selectedFiles.map((file) => {
					return new Promise<string>((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () => {
							if (typeof reader.result === "string") {
								resolve(reader.result);
							} else {
								reject(new Error("Failed to convert file to base64"));
							}
						};
						reader.onerror = () => {
							reject(new Error("Failed to convert file to base64"));
						};
						reader.readAsDataURL(file);
					});
				})
			);

			const payload = {
				...formData,
				documentRef: "loan_offer",
				uploadedFileName: selectedApplication?.uploadedFileName
					? selectedApplication?.uploadedFileName
					: "",
				docs: selectedFiles.map((file, index) => ({
					base64Data: filesAsBase64[index].split(",")[1],
					name: file.name,
					sizeBytes: file.size,
					type: file.type,
				})),
			};

			const response = await createOrUpdateLoanOffer(payload);
			toast.success(response.data.message, { id: toastId });
			onClose();
			queryClient.invalidateQueries("loanOffers");
			queryClient.invalidateQueries("loanOffersSummary");
			setSelectedFiles([]);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					position: "relative",
					overflow: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
				}}
			>
				<ResponsiveGrid columnCount={2}>
					<BaseCard sx={{ padding: "20px", height: "auto" }}>
						<Box component="form">
							<CustomCardHeader title="Loan Offer Details" />

							<CustomTextInput
								name="approvedFundingAmount"
								label="Approved Funding Amount (Rands)"
								type="number"
								value={formData.approvedFundingAmount}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="approvedDuration"
								label="Approved Duration (Days)"
								type="number"
								value={formData.approvedDuration}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="interestRate"
								label="Interest Rate (%)"
								type="number"
								value={formData.interestRate}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="originationFee"
								label="Origination Fee (Rands)"
								type="number"
								value={formData.originationFee}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="repaymentTerms"
								label="Repayment Terms"
								type="text"
								value={formData.repaymentTerms}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="loanConditions"
								label="Loan Conditions"
								type="text"
								value={formData.loanConditions}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>
						</Box>
					</BaseCard>

					<BaseCard sx={{ padding: "20px", height: "auto" }}>
						<Box component="form" mt={2}>
							<CustomCardHeader title="Loan Offer Calculator" />

							<CustomTextInput
								name="approvedFundingAmount"
								label="Approved Funding Amount (Rands)"
								type="number"
								value={formData.approvedFundingAmount}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="originationFee"
								label="Origination Fee (Rands)"
								type="number"
								value={formData.originationFee}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="serviceFee"
								label="Service Fee (Rands)"
								type="number"
								value={formData.serviceFee}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="interestRate"
								label="Interest Rate (%)"
								type="number"
								value={formData.interestRate}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="approvedDuration"
								label="Duration (Days)"
								type="number"
								value={formData.approvedDuration}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<Box display="flex" justifyContent="space-between" mt={4}>
								<Box>
									<CustomButton text="Calculate" variant="contained" />
								</Box>

								<Box>Total: R {formData.total.toLocaleString()}</Box>
							</Box>
						</Box>
					</BaseCard>
				</ResponsiveGrid>

				<Box
					my={2}
					{...getRootProps()}
					sx={{
						border: "2px dashed #ccc",
						borderRadius: "8px",
						padding: "16px",
						textAlign: "center",
						cursor: "pointer",
					}}
				>
					<input {...getInputProps()} />
					<Typography variant="body1">
						Drag & drop loan quote, or click to select files
					</Typography>
				</Box>
				<Box mt={2}>
					{selectedFiles.map((file, index) => (
						<Box key={index} display="flex" alignItems="center">
							<Typography variant="body2">{file.name}</Typography>
							<IconButton onClick={() => handleFileRemove(file)} color="error">
								<DeleteIcon />
							</IconButton>
						</Box>
					))}

					{formData.uploadedFileName ? (
						<Typography variant="body1" color="success" my={2}>
							Loan Quote already uploaded. You can re upload.
						</Typography>
					) : null}
				</Box>
				<Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
					<CustomButton text="Cancel" variant="outlined" onClick={onClose} />
					<CustomButton
						text="Save Loan Offer"
						variant="contained"
						onClick={handleSubmit}
						disabled={isLoading}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default LoanOfferModal;
