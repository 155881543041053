import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface BarChartWrapperProps {
    chartData: any;
    options: any;
}

const BarChartWrapper: React.FC<BarChartWrapperProps> = ({ chartData, options }) => {
    return <Bar data={chartData} options={options} />;
};

export default BarChartWrapper;
