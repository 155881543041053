import React from 'react';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface BaseCardProps {
    children: React.ReactNode;
    sx?: object;
}

const BaseCard: React.FC<BaseCardProps> = ({ children, sx }) => {
    const theme = useTheme();

    return (
        <Card 
            sx={{ 
                padding: '0 16px 16px 0', 
                width: '100%', 
                height: '220px',  
                borderRadius: '12px', 
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                ...sx, 
            }}
        >
            {children}
        </Card>
    );
};

export default BaseCard;
