import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { colors } from "../../../configs/theme";

export const TableContainer = styled("div")({
	overflowX: "auto",
	marginTop: "0px",
	width: "100%",
});

export const Table = styled("table")({
	width: "100%",
	borderCollapse: "collapse",
});

export const TableCell = styled("td")(({ theme }) => ({
	padding: "15px",
	fontWeight: "500",
	fontSize: "14px",
	textAlign: "left",
	cursor: "pointer",
	color: theme.palette.text.primary,
	margin: "10px",
	whiteSpace: "no-wrap",
}));

export const TableRowStyled = styled("tr")(({ theme }) => ({
	backgroundColor:
		theme.palette.mode == "dark"
			? colors.dark.background.table
			: colors.light.background.table,
	borderBottom: `3px solid white`,

	"&:hover": {
		backgroundColor: theme.palette.action.hover,
	},
}));
