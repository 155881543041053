import React from 'react';
import { CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CustomCardHeaderProps {
    title: string;
}

const CustomCardHeader: React.FC<CustomCardHeaderProps> = ({ title }) => {
    const theme = useTheme();

    return (
        <CardHeader
            title={
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        letterSpacing: '-0.0026em',
                        textAlign: 'left',
                        color: theme.palette.text.primary,
                        height: "56px"
                    }}
                >
                    {title}
                </Typography>
            }
            sx={{ paddingBottom: '0' }}
        />
    );
};

export default CustomCardHeader;
