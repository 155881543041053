import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	useTheme,
	CircularProgress,
} from "@mui/material";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import { pimRequestBankAccountLink } from "../../../common/services/post-investment-service";
import toast from "react-hot-toast";

interface LinkBankAccountModalProps {
	open: boolean;
	onClose: () => void;
	clientEmail?: string;
}

const LinkBankAccountModal: React.FC<LinkBankAccountModalProps> = ({
	open,
	onClose,
	clientEmail,
}) => {
	const theme = useTheme();
	const [email, setEmail] = useState(clientEmail ? clientEmail : "");
	const [description, setDescription] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		setIsLoading(true);

		const payload = {
			lendingApplicationId: 0,
			email,
		};

		try {
			const response = await pimRequestBankAccountLink(payload);
			if (response.data.success) {
				toast.success("Request sent successfully!");

				onClose();
			} else {
				toast.error("Failed to send request.");
			}
		} catch (error) {
			console.error("Error linking bank account:", error);
			toast.error("An error occurred. Please try again.");
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		setEmail("");
		setDescription("");
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					height: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					border: 0,
					margin: "auto",
					top: "30%",
					position: "relative",
					color: theme.palette.text.primary,
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
					}}
				>
					Link Your Bank Account
				</Typography>

				<Typography variant="body1" mb={3}>
					A link to connect a bank account will be sent to the provided email
					address. Are you sure you want to proceed?
				</Typography>

				<CustomTextInput
					label="Email Address"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					sx={{ marginTop: "20px" }}
				/>

				<CustomTextInput
					label="Description" // Added description input
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					sx={{ marginTop: "20px" }}
					multiline
					rows={4}
				/>

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "10px",
						marginTop: "20px",
					}}
				>
					<CustomButton
						text="Cancel"
						variant="outlined"
						color="secondary"
						onClick={handleCancel}
					/>

					<CustomButton
						text="Confirm"
						variant="contained"
						onClick={handleSubmit}
						disabled={isLoading}
						startIcon={
							isLoading ? <CircularProgress size={20} color="inherit" /> : null
						}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default LinkBankAccountModal;
