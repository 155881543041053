import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface CircleProps {
    isChecked: boolean | null; 
}

interface CircleWithTextProps {
    innerText: string;
    besideText: string;
    besideTextDirection?: "row" | "column";
    circles?: CircleProps[]; 
}

const HorizontalCard: React.FC<CircleWithTextProps> = ({ innerText, besideText, besideTextDirection = 'row', circles = [] }) => {
    const theme = useTheme();

    return (
        <Box display="flex" gap={3} justifyContent="flex-start" alignItems="center" width="100%" height="43px">
            <Box display="flex" gap={besideTextDirection === 'row' ? 1 : 0} justifyContent="flex-start" flexDirection={besideTextDirection} alignItems={besideTextDirection === 'row' ? 'center' : 'flex-start'}>
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: '22px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        letterSpacing: '0.15px',
                        textAlign: 'left'
                    }}
                >
                    {innerText}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        letterSpacing: '0.15px',
                        textAlign: 'left',
                        color: theme.palette.text.secondary,
                    }}
                >
                    {besideText}
                </Typography>
            </Box>
            <Box display="flex" gap="5px">
                {circles.map((circle, index) => (
                    <Box 
                        key={index}
                        sx={{
                            width: '15px', 
                            height: '15px', 
                            borderRadius: '50%', 
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: circle.isChecked !== null ? 'rgba(82, 84, 89, 1)' : 'transparent',
                            border: circle.isChecked === null ? `2px solid ${theme.palette.text.secondary}` : 'none',
                        }}
                    >
                        {circle.isChecked === true && <CheckIcon sx={{ color: 'white', fontSize: '12px' }} />}
                        {circle.isChecked === false && <CloseIcon sx={{ color: 'white', fontSize: '12px' }} />}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default HorizontalCard;
