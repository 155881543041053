import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import Add from "@mui/icons-material/Add";

const DealsTable = ({
	searchTerm,
	handleSearchChange,
	isDealsFetching,
	filteredDealsData,
	setSelectedRow,
	setOpenCreateOfferModal,
	selectedDeals,
}) => {
	const theme = useTheme();
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<CustomSearchBar
				searchTerm={searchTerm}
				onSearchChange={handleSearchChange}
			/>
			<CustomTable
				isLoading={isDealsFetching}
				icons={(deal) => [
					{
						icon: (
							<Tooltip title="Create Offer" arrow placement="top">
								<Add
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(deal);
							setOpenCreateOfferModal(true);
						},
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{ title: "Product", key: "loanType", sortable: true, isPill: true },
					{ title: "Amount", key: "fundingAmount", sortable: true },
					{ title: "Duration (days)", key: "duration", sortable: true },
					{ title: "Status", key: "status", sortable: true, isPill: true },
					{ title: "Use of funds", key: "useOfFunds", sortable: true },
				]}
				actionColumn={{ title: "Actions" }}
				data={filteredDealsData}
			/>
		</Box>
	);
};

export default DealsTable;
