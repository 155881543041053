import React from "react";
import { Stepper, Step, StepLabel, useTheme } from "@mui/material";
import { colors } from "../../../configs/theme";

const CustomStepper = ({ steps, activeStep, setActiveStep }) => {
	return (
		<Stepper
			activeStep={activeStep}
			alternativeLabel
			sx={{
				"& .MuiStepLabel-root.MuiStepLabel-vertical .MuiStepLabel-iconContainer.Mui-completed svg":
					{
						backgroundColor: "#6DAE56 !important",
						borderRadius: "100%",
						fill: "white",
						width: "40px",
						height: "40px",
					},
				"& .MuiStepLabel-iconContainer svg": {
					backgroundColor: "white !important",
					width: "24px",
					height: "24px",
					color: colors.pumpkn,
					borderRadius: "100%",
					border: "1px solid white",
				},
				"& .MuiStepLabel-iconContainer.Mui-disabled svg text": {
					fill: "white",
				},
			}}
		>
			{steps.map((label, index) => (
				<Step
					sx={{ cursor: "pointer" }}
					key={label}
					onClick={() => setActiveStep(index)}
				>
					<StepLabel>{label}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

export default CustomStepper;
