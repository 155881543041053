import React, { useState } from "react";
import { Modal, Box, Typography, useTheme, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomButton from "../Elements/CustomButton";
import CustomSelect from "../Elements/CustomSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { uploadClientDocs } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";

const UploadDocumentsModal = ({ open, handleClose, data, doc }) => {
	const theme = useTheme();
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [expiryMonth, setExpiryMonth] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/pdf": [".pdf"],
			"text/csv": [".csv"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
		multiple: true,
		onDrop: (acceptedFiles) => {
			setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		},
	});

	const handleFileRemove = (fileToRemove) => {
		setSelectedFiles((prevFiles) =>
			prevFiles.filter((file) => file !== fileToRemove)
		);
	};

	const handleUploadSubmit = async () => {
		setIsLoading(true);

		const filesAsBase64 = await Promise.all(
			selectedFiles.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => {
						resolve(reader.result);
					};
					reader.onerror = () => {
						reject(new Error("Failed to convert file to base64"));
					};
					reader.readAsDataURL(file);
				});
			})
		);

		const payload = {
			userId: data?.userId,
			documentRef: doc?.documentRef,
			expiryInMonth: expiryMonth,
			docs: selectedFiles.map((file, index) => ({
				base64Data: filesAsBase64[index].split(",")[1],
				name: file.name,
				sizeBytes: file.size,
				type: file.type,
			})),
		};

		try {
			const response = await uploadClientDocs(payload);

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("clientDealChecklists");
				queryClient.invalidateQueries("dealChecklists");
			} else {
				toast.error(response.data.message);
			}

			handleClose();
		} catch (error) {
			toast.error("Failed to upload document");
		}

		setIsLoading(false);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					padding: 4,
					backgroundColor: "white",
					borderRadius: 2,
					outline: "none",
					width: "600px",
					position: "relative",
					top: "30%",
					margin: "auto",
				}}
			>
				<Typography variant="h6" mb={2}>
					Upload Documents
				</Typography>

				<Box
					{...getRootProps()}
					sx={{
						border: "2px dashed #cccccc",
						padding: "20px",
						textAlign: "center",
						marginBottom: "20px",
					}}
				>
					<input {...getInputProps()} />
					<Typography variant="body1">
						Drag 'n' drop some files here, or click to select files
					</Typography>
				</Box>

				{selectedFiles.length > 0 && (
					<Box sx={{ marginTop: "10px" }}>
						<strong>Selected Files:</strong>
						<ul>
							{selectedFiles.map((file, index) => (
								<li
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{file.name}
									<IconButton
										onClick={() => handleFileRemove(file)}
										size="small"
										sx={{ color: theme.palette.error.main }}
									>
										<DeleteIcon />
									</IconButton>
								</li>
							))}
						</ul>
					</Box>
				)}

				<CustomSelect
					name="expiryMonth"
					label="Expiry In Month"
					options={[
						{ value: 3, label: "3 Months" },
						{ value: 6, label: "6 Months" },
						{ value: 12, label: "12 Months" },
						{ value: 0, label: "None" },
					]}
					value={expiryMonth}
					onChange={(e) => setExpiryMonth(e.target.value)}
				/>

				<Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
					<CustomButton
						text="Cancel"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
					/>
					<CustomButton
						text="Upload"
						onClick={handleUploadSubmit}
						variant="contained"
						disabled={isLoading || selectedFiles.length === 0}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default UploadDocumentsModal;
