import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';

interface Segment {
    name: string;
    value: number;
    color: string;
}

interface ColoredBoxSummaryCardProps {
    title: string;
    titleIcon?: React.ReactNode;
    segments: Segment[];
    others: string[];
}

const ColoredBoxSummaryCard: React.FC<ColoredBoxSummaryCardProps> = ({
    title,
    titleIcon,
    segments,
    others
}) => {
    const total = segments.reduce((acc, segment) => acc + segment.value, 0);

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" flexDirection="column" p={2} height="100%" mt={-4}>
                <Box display="flex" width="100%" height="40px" borderRadius="4px" overflow="hidden">
                    {segments.map((segment, index) => (
                        <Box
                            key={index}
                            width={`${(segment.value / total) * 100}%`}
                            bgcolor={segment.color}
                            height="100%"
                            display="flex" 
                            alignItems="center" 
                            justifyContent="center"
                        >
                            <Typography variant="body2" color="white">
                                {segment.value}%
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box mt={1} display="flex" flexDirection="row">
                    {segments.map((segment, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            mr={2} 
                        >
                            <Box
                                width="10px"
                                height="10px"
                                bgcolor={segment.color}
                                borderRadius="50%"
                                mr={1}
                            />
                            <Typography variant="body2" mr={1}>
                                {segment.name}
                            </Typography>
                            <Typography variant="body2">
                                {segment.value}%
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box mt={6}>
                    <Typography textAlign="left" variant="body2">
                        Others: {others.join(', ')}
                    </Typography>
                </Box>
            </Box>
        </BaseCard>
    );
};

export default ColoredBoxSummaryCard;
