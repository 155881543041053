import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Tooltip, Tabs, Tab, useTheme } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import CustomTable from '../Tables/CustomTable';
import { useDropzone } from "react-dropzone";
import { CsvData, OnboardPayload, Transaction, UploadedTransactionEntry } from '../../types';
import CustomButton from '../Elements/CustomButton';
import CardWithIcon from '../Elements/CardWithIcon';
import { AddCircle } from '@mui/icons-material';
import toast from 'react-hot-toast';
import TransactionOverviewModal from './TransactionOverviewModal';
import UploadTransactionModal from './UploadTransactionModal';
import TabsWithSearch from '../Widgets/TabsWithSearch';
import ModelSelectionModal from './ModelSelectinModal';
import OnboardingModal from './OnboardingModal';
import CustomTabs from '../Elements/CustomTabs';
import CustomSearchBar from '../Elements/CustomSearchBar';

interface ImportSmeModalProps {
    open: boolean;
    onClose: () => void;
}

const ImportSmeModal: React.FC<ImportSmeModalProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const [csvData, setCsvData] = useState<any[]>([]);
    const [selectedSMEs, setSelectedSMEs] = useState<any[]>([]);

    const [uploadedTransactions, setUploadedTransactions] = useState<UploadedTransactionEntry[]>([]);
    const [transactionUploadDialogOpen, setTransactionUploadDialogOpen] = useState(false);
    const [currentIdNumber, setCurrentIdNumber] = useState<string | null>(null);
    const [currentBusinessName, setCurrentBusinessName] = useState<string | null>(null);
    const [selectedTransactions, setSelectedTransactions] = useState<Transaction[]>([]);
    const [viewUploadedTransactionsOverviewDialog, setViewUploadedTransactionsOverviewDialog] = useState(false);

    
    const [modelSelectionOpen, setModelSelectionOpen] = useState(false);
    const [onboardingProgress, setOnboardingProgress] = useState(false);
    const [selectedModel, setSelectedModel] = useState('');

    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'text/csv': ['.csv'] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const text = event.target?.result;
                if (typeof text === 'string') {
                    const data: CsvData[] = text.split("\n").map((row: string, index: number): CsvData | null => {
                        const columns = row.split(",").map(field => field.trim().replace(/\r/g, ""));
                        if (columns.length >= 12) {
                            return {
                                Email: columns[0],
                                IdNumber: columns[1],
                                CipcRegNo: columns[2],
                                EntrepreneurName: columns[3],
                                BusinessName: columns[4],
                                LoanAmount: parseFloat(columns[5]),
                                Duration: parseInt(columns[6], 10),
                                UseOfFunds: columns[7],
                                Sector: columns[8],
                                Location: columns[9],
                                RequestBankAccountLinkage: columns[10],
                                InDefault: columns[11],
                                UploadedTransactions: []
                            };
                        }
                        console.warn(`Skipping malformed row ${index + 1}: ${row}`);
                        return null;
                    }).filter((item): item is CsvData => item !== null);
                    setCsvData(data);
                    setTabValue(1);
                }
            };
            reader.readAsText(file);
        },
    });

    function viewTransactions(idNumber: string, businessName: string): void {
        const businessTransactions = uploadedTransactions.find(entry => entry.idNumber === idNumber)?.transactions || [];
        setSelectedTransactions(businessTransactions);
        setCurrentBusinessName(businessName);
        setViewUploadedTransactionsOverviewDialog(true);
      }
    
      const uploadTransactions = (idNumber: string, businessName: string) => {
        setCurrentIdNumber(idNumber);
        setCurrentBusinessName(businessName);
        setTransactionUploadDialogOpen(true);
      };
    
      const handleTransactionsCSVUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target?.result as string;
          const transactions = parseTransactionsCSV(text);
          setUploadedTransactions((prev) => {
            const existingEntry = prev.find((entry) => entry.idNumber === currentIdNumber);
            if (existingEntry) {
              existingEntry.transactions = [...existingEntry.transactions, ...transactions];
              return [...prev];
            } else {
              return [...prev, { idNumber: currentIdNumber!, transactions }];
            }
          });
          toast.success("Transactions uploaded successfully");
        };
        reader.readAsText(file);
      };
    
      const parseTransactionsCSV = (csvText: string): Transaction[] => {
        const lines = csvText.split("\n").slice(1); // Assuming the first line contains headers
        return lines.map((line: string, index: number): Transaction | null => {
          const fields = line.split(",").map(field => field.trim().replace(/\r/g, ""));
          if (fields.length === 5) {
            return {
              Sequence: parseInt(fields[0], 10),
              Date: fields[1],
              Description: fields[2],
              Amount: parseFloat(fields[3]),
              Balance: parseFloat(fields[4]),
            };
          }
          console.warn(`Skipping malformed line ${index + 2}: ${line}`);
          return null;
        }).filter((transaction): transaction is Transaction => transaction !== null);
      };
    
      const findUploadedTransactions = (idNumber: string) => {
        const record = uploadedTransactions.find(entry => entry.idNumber === idNumber);
        return record && record.transactions.length > 0;
      };

      const [searchQuery, setSearchQuery] = useState('');
      const [filteredData, setFilteredData] = useState<any[]>([]);

      const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
       
        setSearchQuery(event.target.value);
    };

    const createOnboardPayload = (
        selectedSMEs: CsvData[],
        selectedModel: string
      ): OnboardPayload => {
    
        return {
          model: selectedModel,
          SMEs: selectedSMEs.map(sme => {
    
            const smeTransactions = uploadedTransactions.find(entry => entry.idNumber === sme.IdNumber)?.transactions || [];
    
            return {
              idNumber: sme.IdNumber,
              email: sme.Email,
              cipcRegNo: sme.CipcRegNo,
              entrepreneurName: sme.EntrepreneurName,
              businessName: sme.BusinessName,
              loanAmount: sme.LoanAmount,
              duration: sme.Duration,
              useOfFunds: sme.UseOfFunds,
              sector: sme.Sector,
              location: sme.Location,
              inDefault: sme.InDefault,
              requestBankAccountLinkage: sme.RequestBankAccountLinkage,
              transactions: smeTransactions.map(transaction => ({
                sequence: transaction.Sequence,
                date: transaction.Date,
                description: transaction.Description,
                amount: transaction.Amount,
                balance: transaction.Balance,
              })),
            };
          }),
        };
      };

    const handleOnboardSelected = async (model: string) => {
        
        setSelectedModel(model);
        const payload = createOnboardPayload(selectedSMEs, model);
        setOnboardingProgress(true);
        setProgress(0);
        setCompleted(false);
    
        try {
          
          setCompleted(true);
        } catch (error) {
          setCompleted(false);
        } finally {
          setProgress(100);
          setCompleted(true);
        }
      };

  
      useEffect(() => {
        const filtered = csvData.slice(1)?.filter((sme) => {

            const entrepreneurName = sme.EntrepreneurName || '';
            const businessName = sme.BusinessName || '';
            const sector = sme.Sector || '';
            const location = sme.Location || '';
            

    
            return (
                (tabValue === 1) &&
                (entrepreneurName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    businessName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    sector.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    location.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        });

       
        setFilteredData(filtered);

       
    }, [csvData, tabValue, searchQuery]);
   
    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        width: '97%',
                        maxHeight: '80vh', 
                        background: theme.palette.mode == 'dark' ? theme.palette.background.default : theme.palette.background.paper,
                        padding: '24px',
                        borderRadius: '8px 8px',
                        border: 0,
                        margin: 'auto',
                        overflowY: 'auto',
                        top: '10%',
                        position: 'relative',
                    }}
                >
                    <Typography
                        mb={3}
                        sx={{
                            fontSize: '24px',
                            fontWeight: '500',
                            lineHeight: '32px',
                            fontFamily: 'Roboto',
                            color: theme.palette.text.primary,
                        }}
                    >
                        Onboard SMEs
                    </Typography>

                    {csvData.length > 0 && (
                        <CustomTabs
                            selectedTab={tabValue}
                            onTabChange={handleTabChange}
                           
                          
                            tabs={[
                                { label: "Import" },
                                { label: "Preview" }
                            ]}
                        />
                    )}

                    {tabValue === 1 && csvData.length > 0 && (
                        <Box mt={2}>
                            <Box
                                width="100%"
                                my={2}
                                gap={2}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between', 
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <CustomSearchBar
                                        searchTerm={searchQuery}
                                        onSearchChange={handleSearch}
                                    />
                                </Box>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    <CardWithIcon
                                        icon={
                                            <AddCircle
                                                style={{
                                                    color: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white',
                                                    marginRight: '5px',
                                                    fontSize: '34px',
                                                }}
                                            />
                                        }
                                        text="Onboard Selected SMEs"
                                        onClick={() => {
                                            setModelSelectionOpen(true);
                                        }}
                                        disabled={selectedSMEs.length < 1}
                                    />
                                </Box>
                            </Box>
                            <CustomTable
                                icons={(client: any) => [
                                    {
                                        icon: (
                                            <Tooltip placement='top' title="Upload Transactions" arrow>
                                                <UploadIcon
                                                    style={{
                                                        color: findUploadedTransactions(client.IdNumber) ? 'green' : 'red',
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Tooltip>
                                        ),
                                        onClick: () => uploadTransactions(client.IdNumber, client.BusinessName),
                                    },
                                    {
                                        icon: (
                                            <Tooltip placement='top' title="View Transactions" arrow>
                                                <VisibilityIcon sx={{ fill: theme.palette.mode == 'light' ? 'rgba(73, 69, 79, 1)' : 'white', fontSize: '16px' }} />
                                            </Tooltip>
                                        ),
                                        onClick: () => viewTransactions(client.IdNumber, client.BusinessName),
                                    },
                                ]}
                                columns={[
                                    
                                    { title: 'Name', key: 'EntrepreneurName', sortable: true },
                                    { title: 'Business', key: 'BusinessName', sortable: true },
                                    { title: 'Amount', key: 'LoanAmount', sortable: true, withSignFormating: false },
                                    { title: 'Duration (days)', key: 'Duration', sortable: true },
                                    { title: 'Sector', key: 'Sector', sortable: true },
                                    { title: 'Location', key: 'Location', sortable: true },
                                    {
                                        title: 'In default?',
                                        key: 'InDefault',
                                        sortable: true,
                                        isSwitch: true
                                    },
                                    {
                                        title: 'Request for bank account linkage?',
                                        key: 'RequestBankAccountLinkage',
                                        sortable: true,
                                        isSwitch: true
                                    },
                                ]}
                                data={filteredData}
                                actionColumn={{
                                    title: 'Actions',
                                }}
                                onRowSelect={(selectedRows) => {
                                    setSelectedSMEs(selectedRows)
                                    
                                }}
                                selectable={true}
                            />
                        </Box>
                    )}

                    {tabValue === 0 && (
                        <Box sx={{ padding: '20px', textAlign: 'center' }}>
                            <Box
                                {...getRootProps()}
                                sx={{
                                    border: '2px dashed #cccccc',
                                    padding: '15px',
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    maxWidth: '80%',
                                    marginBottom: '30px'
                                }}
                            >
                                <input {...getInputProps()} />
                                <Typography variant="body1">Drag 'n' drop a CSV file here, or click to select one</Typography>
                            </Box>

                            <CustomButton
                                variant="outlined"
                                color="secondary"
                                href="/sme_bulk_upload_template.csv"
                                startIcon={<DownloadIcon />}
                                text="Download Template"
                                download={true}
                            />
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                        <CustomButton
                            text="Close"
                            onClick={onClose}
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>
            </Modal>

            <TransactionOverviewModal 
               open={viewUploadedTransactionsOverviewDialog}
               onClose={() => setViewUploadedTransactionsOverviewDialog(false)}
               businessName={currentBusinessName}
               transactions={selectedTransactions}
            />
             <UploadTransactionModal
                open={transactionUploadDialogOpen}
                onClose={() => setTransactionUploadDialogOpen(false)}
                currentBusinessName={currentBusinessName}
                handleTransactionsCSVUpload={handleTransactionsCSVUpload}
            />

           
            <ModelSelectionModal
                open={modelSelectionOpen}
                onClose={() => setModelSelectionOpen(false)}
                onOnboard={handleOnboardSelected}
            />
      
            <OnboardingModal
                open={onboardingProgress}
                onClose={() => {
                    setOnboardingProgress(false);
                    setProgress(0);
                    setCompleted(false);
                }}
                completed={completed}
                progress={progress}
            />

        </>
        
    );
};

export default ImportSmeModal;
