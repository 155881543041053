const COMPONENT_ID_KEY = 'componentIds';

const getComponentIds = () => {
    const storedIds = localStorage.getItem(COMPONENT_ID_KEY);
    return storedIds ? JSON.parse(storedIds) : [];
};

const saveComponentId = (componentId: string) => {
    const existingIds = getComponentIds();

    if (!existingIds.includes(componentId)) {
        existingIds.push(componentId);
        localStorage.setItem(COMPONENT_ID_KEY, JSON.stringify(existingIds));
    }
};

const removeComponentId = (componentId: string) => {
    let existingIds = getComponentIds();
    existingIds = existingIds.filter((id: string) => id !== componentId);
    localStorage.setItem(COMPONENT_ID_KEY, JSON.stringify(existingIds));
};

const clearDarkMode = () => {
    // Clear any existing dark mode settings
    localStorage.removeItem("toolpad-mode");
    localStorage.removeItem("toolpad-color-scheme-dark");
    localStorage.removeItem("toolpad-color-scheme");

    // Set values explicitly to light mode
    localStorage.setItem("toolpad-mode", "light");
    localStorage.setItem("toolpad-color-scheme-dark", "light");
    localStorage.setItem("toolpad-color-scheme", "light");
};


const getUserId = () => localStorage.getItem("userId");
const getCurrentUser = () => localStorage.getItem("user");
const getCurrentUserEmail = () => {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user)?.email : "";
  };
  
export { getCurrentUser, getCurrentUserEmail,getUserId, getComponentIds, saveComponentId, removeComponentId, clearDarkMode };