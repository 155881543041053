import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import CustomCardHeaderWithIcon from './CustomCardHeaderWithIcon';

interface ClientListProps {
    data: {
        title: string;
        subtitle: string;
        clients: string[];
    }[];
    title: string;
    titleIcon?: React.ReactNode;
}

const ClientList: React.FC<ClientListProps> = ({ data, title, titleIcon }) => {
    const colors = ['#3D7F8E', '#397081', '#345D6A'];

    return (
        <BaseCard>
            <CustomCardHeaderWithIcon title={title} icon={titleIcon} />
            <Box display="flex" width="100%" height="100%" p={2} mt={-4}>
                {data.map((header, index) => (
                    <Box
                        key={index}
                        flex={1}
                        display="flex"
                        flexDirection="column"
                    >
                        <Box
                            bgcolor={colors[index]}
                            padding={1}
                            height="56px"
                        >
                            <Typography textAlign="left" fontSize="16px" fontWeight="400" lineHeight="21px" letterSpacing="-0.31%" color="white">
                                {header.title}
                            </Typography>
                            <Typography textAlign="left" fontSize="9px" fontWeight="400" lineHeight="19px" letterSpacing="-0.15%" color="white">
                                {header.subtitle}
                            </Typography>
                        </Box>

                        <Box padding={1}>
                            {header.clients.map((client, clientIndex) => (
                                <Typography
                                    key={clientIndex}
                                    textAlign="left"
                                    fontSize="14px" fontWeight="400" lineHeight="20px" letterSpacing="0.25%"
                                >
                                    {client}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </BaseCard>
    );
};

export default ClientList;
