import React from 'react';
import { Box } from '@mui/material';
import LineChartWrapper from '../Charts/LineChartWrapper';

interface LineChartDetailsProps {
    labels: string[];
    data: { data1: number[]; data2: number[] };
    maxValue: number;
}

const LineChartDetails: React.FC<LineChartDetailsProps> = ({ labels, data, maxValue }) => {
    return (
        <Box width="100%" height="100%" sx={{ height: '150px' }}>
            <LineChartWrapper
                chartData={{
                    labels: labels,
                    datasets: [
                        {
                            label: '',
                            data: data.data1,
                            borderColor: '#14AE5C',
                            backgroundColor: '#14AE5C',
                            fill: false,
                            tension: 0.1,
                        },
                        {
                            label: '',
                            data: data.data2,
                            borderColor: '#EC221F',
                            backgroundColor: '#EC221F',
                            fill: false,
                            tension: 0.1,
                        },
                    ],
                }}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Months',
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0,
                            },
                        },
                        y: {
                            min: 0,
                            max: maxValue,
                            ticks: {
                                stepSize: 500000,
                                callback: function (value: number) {
                                    if (value === 0 || value === 500000 || value === maxValue) {
                                        return 'R ' + value.toLocaleString();
                                    }
                                    return '';
                                },
                            },
                            title: {
                                display: false,
                                text: 'Amount',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default LineChartDetails;
