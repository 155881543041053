import React, { useState } from "react";
import { Box, Tooltip, useTheme, Fab } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import Upload from "@mui/icons-material/CloudUpload";
import { toast } from "react-hot-toast";
import CustomSearchBar from "../Elements/CustomSearchBar";
import CustomTable from "./CustomTable";
import LoanContractUploadContract from "../Modals/LoanContractUploadModal";
import ContractsFloatingActionMenu from "../Menus/ContractsFloatingActionMenu";

const ContractsTable = ({
	contractsSearchTerm,
	handleContractsSearchChange,
	isContractsLoading,
	filteredContractsData,
	setSelectedRow,
	setOpenUpdateContractModal,
	setPdfUrl,
	setOpenPdfDialog,
	refetch,
	selectedRow,
	selectedContracts,
}) => {
	const theme = useTheme();

	const [openUploadDialog, setOpenUploadDialog] = useState(false);

	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={2}
			position="relative"
			minHeight="100vh"
		>
			<CustomSearchBar
				searchTerm={contractsSearchTerm}
				onSearchChange={handleContractsSearchChange}
			/>
			<CustomTable
				isLoading={isContractsLoading}
				icons={(offer) => [
					{
						icon: (
							<Tooltip title="Update Contract" arrow>
								<Edit
									sx={{
										fill: theme.palette.mode === "light" ? "green" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setSelectedRow(offer);
							setOpenUpdateContractModal(true);
						},
					},
					{
						icon: (
							<Tooltip title="View Signed Contract" arrow>
								<Visibility
									sx={{
										fill: theme.palette.mode === "light" ? "orange" : "white",
										fontSize: "20px",
									}}
								/>
							</Tooltip>
						),
						onClick: () => {
							setPdfUrl(offer?.downloadUrl);
							if (offer?.uploadedFileName) setOpenPdfDialog(true);
							else toast.error("Contract has not been uploaded.");
						},
					},
				]}
				columns={[
					{ title: "Email", key: "email", sortable: true },
					{
						title: "Product",
						key: "productType",
						sortable: true,
						isPill: true,
					},
					{ title: "Description", key: "description", sortable: true },
					{
						title: "Contract Sent",
						key: "contractSent",
						sortable: true,
						isPill: true,
					},
					{
						title: "Contract Signed",
						key: "contractSigned",
						sortable: true,
						isPill: true,
					},
					{ title: "Created", key: "createdTimestamp", sortable: true },
				]}
				data={filteredContractsData}
				actionColumn={{ title: "Actions" }}
				selectable
				onRowSelect={(rows) => setSelectedRow(rows[rows ? rows.length - 1 : 0])}
				selected={selectedContracts}
			/>

			{selectedRow && (
				<ContractsFloatingActionMenu
					selectedRows={selectedContracts}
					data={selectedRow}
					refetch={refetch}
				/>
			)}
		</Box>
	);
};

export default ContractsTable;
