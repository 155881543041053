import { useQuery, useMutation } from 'react-query';
import {
    
    getAllLendingReminders,
} from '../../services/retool-service';

export const useFetchLendingReminders = () => {
    return useQuery('lendingReminders', getAllLendingReminders, { staleTime: Infinity });
};

